
const VERSION = 'api/v1';

let root = "";

if(window.location.hostname === "localhost"){
    root = "http://localhost:4000";
}
else if(window.location.host === "xlrate.10x.systems"){
    root = "https://tenxsurveycasestudy.herokuapp.com";
}
else {
    root = "https://xlratebeta-fd1a6dd1e692.herokuapp.com";
}


export const DOMAIN_NAME = `${root}/${VERSION}`;


export const XL_FACTORS = [
    "Fun",
    "Purpose",
    "Growth",
    "Emotives",
    "Utility",
    "Apathy",
    "Challenge",
    "SelfEfficacy",
    "Autonomy",
    "ClearGoal"
];

export const ACTIVITIES = ["work","relationship","hobby","study","sport","business"];

export const LOGIN_FIRST_TIME = "LOGIN_FIRST_TIME";
export const LOGIN_AFTER_FIRST_TIME = "LOGIN_AFTER_FIRST_TIME";
export const CHOOSE_THINGS_TO_IMPROVE = "CHOOSE_THINGS_TO_IMPROVE";
export const CHOOSE_GOAL_AND_ACHIVEMENTS = "CHOOSE_GOAL_AND_ACHIVEMENTS";
export const COMPLETE_SURVEY = "COMPLETE_SURVEY";
export const ADD_EVENT = "ADD_EVENT";

export const NUMBER_WITH_DAYS = {
    0: "S",
    1: "M",
    2: "T",
    3: "W",
    4: "T",
    5: "F",
    6: "S"
}

export const NUMBER_AND_DAYS_MAPING = {
    0: {
        sort: "S",
        mid: "Sun",
        full: "Sunday",
    },
    1: {
        sort: "M",
        mid: "Mon",
        full: "Monday",
    },
    2: {
        sort: "T",
        mid: "Tue",
        full: "Tuesday",
    },
    3: {
        sort: "W",
        mid: "Wed",
        full: "Wednesday",
    },
    4: {
        sort: "T",
        mid: "Thu",
        full: "Thursday",
    },
    5: {
        sort: "F",
        mid: "Fri",
        full: "Friday",
    },
    6: {
        sort: "S",
        mid: "Sat",
        full: "Saturday",
    }
}

// FACTORS
export const FACTOR_COLORS = {
    XLSCORE: "#66FF00",
    FUN: "#F5A571",
    PURPOSE: "#73B5EB",
    GROWTH: "#86D1AC",
    EMOTIVES: "#F19B96",
    UTILITY: "#8A9AED",
    APATHY: "#8F7472",
    CHALLENGE: "#ff6666"
} 

export const SQUIRE_LOGO_URL = "https://res.cloudinary.com/dsnjr85ys/image/upload/v1704051668/XLRate_Logo_w_Tag_New_122023_1080sq_wr8svt.png";
export const RECT_LOGO_URL = "https://res.cloudinary.com/dsnjr85ys/image/upload/v1704211715/Copy_XLRate_new_logo_landscape_gewvxp.png";


export const GENDER_IDENTITIES = ["","Male","Female","Non-Binary","Genderqueer","Genderfluid","Agender","Bigender","Two-Spirit","Demiboy","Demigirl","Androgynous","Neutrois","Pangender","Third Gender","Multigender","Transgender","Cisgender","Questioning","Other"];