import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { 
  createPriPostSurvey,
  getSurveyTitleByUserIdAndSurveyId,
  updateLoggedInUser
 } from '../../Store/actions';
import { toast } from 'react-toastify';
import {
  Loading, 
  PrimaryButton, 
  UI
} from '../../components';
// import mixpanel from 'mixpanel-browser';
import {useMixpanelBrowser} from "../../hooks";
import { useDispatch, useSelector } from 'react-redux';
import {TempMain,CenterContent} from "../../layout";


function Index({setIsShow}) {
  const { userId, surveyId } = useParams();
  const [survey,setSurvey] = useState(null);
  const [user,setUser] = useState(null);
  const [questions,setQuestions] = useState(null);
  const [answers, setAnswers] = React.useState(null);
  const [isPreDone, setIsPreDone] = React.useState(false);
  const [isSurveyDone, setIsSurveyDone] = React.useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const {addEvent} = useMixpanelBrowser();
  const loggedInUser = useSelector(state => state.loggedInUser);
  const location = useLocation();
  const dispatch = useDispatch();
  const [organizationName, setOrganizationName] = useState("");


  const handleSubmit = async (lastAns,lastFactor,starttime) => {
    setIsSubmiting(true);
    const myAnswers = [...answers,{rating: lastAns,XLFactor: lastFactor,starttime,endtime: new Date().getTime()}];
    const data = {
      answers: myAnswers,
      surveytitle: survey._id,
      user: user._id 
    };
    
    const res = await createPriPostSurvey(data);
    console.log(res);
    if(res.success){
      if(location.state?.isPostSurvey){
        dispatch(updateLoggedInUser({isPostSurveyDone: true}));
      }
      if(setIsShow){
        dispatch(updateLoggedInUser({isPreSurveyDone: true}));
      }
      addEvent(user._id,"Pre Survey Submit Button");
      setIsSurveyDone(true);
    }
    setIsSubmiting(false);
  }

  useEffect(() => {
    (async()=>{
      const tUserId = userId || loggedInUser._id;
      const tSurveyId = surveyId || loggedInUser.preSurveyId;
      const res = await getSurveyTitleByUserIdAndSurveyId(tUserId, tSurveyId);
      if(res.success){
        setSurvey(res.surveyTitle);
        setUser(res.user);
        if(res.user.organizationName){
          const questions = replaceQuestionsPlaceholders(res.surveyTitle.questions,res.user.organizationName)
          setQuestions(questions);
        }
      }
      else {
        toast.error("Something Went Wrong!");
      }
    })()
  }, [userId,surveyId,loggedInUser]);

  if(!survey || !user){
    return <div className="w-full h-[93vh]"><Loading/></div>
  }

  if(!questions){
    return <EnterOrganizationName survey={survey} setQuestions={setQuestions} organizationName={organizationName} setOrganizationName={setOrganizationName}/>
  }

  if(!isPreDone){
    return <PreSurvey user={user} setIsPreDone={setIsPreDone} survey={survey}/>
  }

  if(isSurveyDone){
    return <AfterSurveyPage survey={survey} user={user} setIsShow={setIsShow}/>
  }

  return (
    <TempMain>
      <div className='h-[20px]'/>
      <p className='text-center text-secondry-color mb-2 font-bold'>Setting your baseline</p>
      <UI.SurveyUI isSubmiting={isSubmiting} handleSubmit={handleSubmit} questions={questions} answers={answers} setAnswers={setAnswers}/>
    </TempMain>
  )
}

export default Index;



function PreSurvey({user,setIsPreDone,survey}){
  // let briefingForm = survey.briefingform.replaceAll("<p>","")
  // briefingForm = briefingForm.replaceAll("</p>","")
  // briefingForm = briefingForm.replaceAll("<h1>","")
  // briefingForm = briefingForm.replaceAll("</h1>","")
  // briefingForm = briefingForm.replaceAll("__organization__",user.organizationName)
  // briefingForm = briefingForm.split("\n").filter(item => item.trim());
  const {addEvent} = useMixpanelBrowser();

  const handleNext = () => {
    setIsPreDone(true);
    addEvent(user._id,"Pre Survey Intro Next Button");
  }

  
  return (
    <> 
      <TempMain>
            {/* <div className='w-[400px] max-w-full mx-auto'> */}
              {/* {
                briefingForm.map(item => {
                  return  <p key={item} className="text-sm  tracking-widest mb-3">
                            {item}
                          </p>
                })
              } */}
              
            {/* </div> */}
            <CenterContent>
                <p className='text-secondry-color mb-6 font-bold text-center'>Setting Your Baseline</p>
                <p className='text-secondry-color mb-6'>Let's Personalise Your Journey</p>
                <p className='text-secondry-color mb-6'>We need some insights from you to tailor your experience and help you track your growth with XLRate</p>
                <p className='text-secondry-color mb-3 font-bold'>Why Provide Baseline Data?</p>
                <ul className='list-disc mb-6 pl-6 text-secondry-color'>
                  <li className=''><span className='font-bold'>Personalised Feedback:</span> Get tailored insights and actionable recommendations</li>
                  <li className=''><span className='font-bold'>Track Progress:</span> Measure your improvement and celebrate milestones</li>
                  <li className=''><span className='font-bold'>Optimise Performance:</span> Identify and enhance areas of growth</li>
                </ul>
                <p className='text-secondry-color mb-6'>Ready to start? Let’s set your baseline!</p>
              
                <PrimaryButton onClick={handleNext}>
                  Start Now
                </PrimaryButton>
            </CenterContent>
      </TempMain>
    </>
  )
}


function AfterSurveyPage({user,survey,setIsShow}){
  const navigator = useNavigate();
  // const activity = user.activities[0];
  // let debriefingForm = survey.debriefingform.replaceAll(`[forename]`,user.forename);
  // debriefingForm = debriefingForm.replaceAll(`[title+activity]`,`${activity.name} ${activity.category}`);
  // debriefingForm = debriefingForm.split("\n").filter(item => item.trim());
  
  
  const handleNext = async () => {
    if(setIsShow){
      setIsShow(false);
      return;
    }
    navigator(`/score/user/${user._id}`);
    return;
  }


  return (<>
    <TempMain>
        {/* <div className='w-[400px] max-w-full mx-auto'> */}
        {/* {
          debriefingForm.map(item => {
            return  <p key={item} className="text-sm  tracking-widest mb-3">
                      {item}
                    </p>
          })
        } */}
        
        {/* </div> */}
        <CenterContent>
            <p className='text-secondry-color mb-6'>Thank You!</p>

            <p className='text-secondry-color mb-6'>You've completed your baseline setting</p>
          
            <p className='text-secondry-color mb-3 font-bold'>Your personalised journey with XLRate begins now. Here’s what’s next</p>
            <ul className='list-disc mb-6 pl-6 text-secondry-color'>
              <li className=''>How XLRate works</li>
              <li className=''>Set Goals: Tailored goals based on your input</li>
              <li className=''>1st CogniPulse</li>
              <li className=''>Your Personal Growth Dashboard</li>
            </ul>

          
            <PrimaryButton onClick={handleNext}>
              Next
            </PrimaryButton>
        </CenterContent>
    </TempMain>
  </>)
}


function EnterOrganizationName({setQuestions,survey,organizationName,setOrganizationName}){
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(updateLoggedInUser({organizationName}));
    const questions = replaceQuestionsPlaceholders(survey.questions,organizationName)
    setQuestions(questions);
  }

  return (
    <>
    <TempMain>
           <div className='flex items-center justify-center w-full h-[calc(100vh-64px)]'>
              <div className='w-[500px] max-w-full w-full p-6 rounded-lg shadow-lg  md:mt-0 sm:max-w-md sm:p-8'>
                  <div className='mb-4'>
                    <img className='w-[400px] max-w-full' src='https://res.cloudinary.com/dsnjr85ys/image/upload/v1704211715/Copy_XLRate_new_logo_landscape_gewvxp.png' alt='logo'/>
                  </div>
                  
                  <p className='mb-6 text-secondry-color'>We'd Like to Personalise Your Experience</p>
                  {/* <p className='mb-6 text-secondry-color'>This helps us tailor your experience and provide relevant organisational insights</p> */}

                  <div className='flex flex-col text-secondry-color mb-8'>
                    <label htmlFor='organizationName' className='mb-2 font-bold' >Please enter the name of your organisation</label>
                    <input id='organizationName' placeholder='e.g. Acme Corporation' className='bg-secondry-color/20 border border-secondry-color rounded px-3 py-1' type='text' value={organizationName} onChange={(e)=> setOrganizationName(e.target.value)}/>
                    <small className='text-secondry-color/50 mt-1'>Your organisation name helps us personalise your experience. Your data is secure</small>
                  </div>
                
                  <PrimaryButton onClick={handleClick} disabled={!organizationName? true:false}>
                      Submit Organisation Name
                  </PrimaryButton>
              </div>
           </div>
      </TempMain>
    </>
  )
}


function replaceQuestionsPlaceholders(questions,organizationName){
  return questions.map((ques) => {
    let question = ques.question.replace("__organization__", organizationName);
    // question = question?.replace("[insert survey subject]", surveyName);
    // question = question?.replace("[insert user input name/title]", title);
    // question = question?.replace("study", "Studies");
    return {...ques,question};
  })
}

