import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {orderOptions} from "../utils";
import { Options, ProgressBarAndBackButton, Wrapper } from "./index";
import { PrimaryButton } from "../../../components";
import { updateLoggedInUser } from "../../../Store/actions";
import {TempMain} from "../../../layout";


function PersonalImprovementGoals({setScreen}){
    const loggedInUser = useSelector(state => state.loggedInUser);
    const [options, setOptions] = useState(()=> {
        return [
            {
                order: null,
                option: "Maximise My Career Growth",
                isSelected: false,
                tag: "Career Growth",
            },
            {
                order: null,
                option: "Navigate Career Transitions with Confidence",
                isSelected: false,
                tag: "Career Growth",
            },
            {
                order: null,
                option: "Discover My True Professional Calling",
                isSelected: false,
                tag: "Career Growth",
            },
            {
                order: null,
                option: "Build a Rewarding Career Path",
                isSelected: false,
                tag: "Career Growth",
            },
            {
                order: null,
                option: "Enhance My Work-Life Balance",
                isSelected: false,
                tag: "Personal Well-being",
            },
            {
                order: null,
                option: "Manage Work Stress More Effectively",
                isSelected: false,
                tag: "Personal Well-being",
            },
            {
                order: null,
                option: "Increase My Job Satisfaction",
                isSelected: false,
                tag: "Personal Well-being",
            },
            {
                order: null,
                option: "Cultivate a Positive Mindset",
                isSelected: false,
                tag: "Personal Well-being",
            },
            {
                order: null,
                option: "Develop Stronger Leadership Skills",
                isSelected: false,
                tag: "Leadership Development",
            },
            {
                order: null,
                option: "Foster Better Workplace Relationships",
                isSelected: false,
                tag: "Leadership Development",
            },
            {
                order: null,
                option: "Improve Emotional Intelligence in the Workplace",
                isSelected: false,
                tag: "Leadership Development",
            },
            {
                order: null,
                option: "Enhance My Decision-Making Skills",
                isSelected: false,
                tag: "Leadership Development",
            },
            {
                order: null,
                option: "Boost My Productivity and Efficiency",
                isSelected: false,
                tag: "Skills Enhancement",
            },
            {
                order: null,
                option: "Strengthen My Professional Skill Set",
                isSelected: false,
                tag: "Skills Enhancement",
            },
            {
                order: null,
                option: "Unlock My Creative Potential",
                isSelected: false,
                tag: "Skills Enhancement",
            },
            {
                order: null,
                option: "Commit to Lifelong Learning and Growth",
                isSelected: false,
                tag: "Skills Enhancement",
            },
            {
                order: null,
                option: "Build a Personal Brand That Resonates",
                isSelected: false,
                tag: "Skills Enhancement",
            },
            {
                order: null,
                option: "Expand My Professional Network and Influence",
                isSelected: false,
                tag: "Skills Enhancement",
            },
        ];
    });
    const dispatch = useDispatch();


    const handleBack = () => {
        setScreen(prev => {
            return {
                ...prev,
                welcome: true,
                personalImprovementGoals: false
            }
        })
    }

    const handleClick = (item) => {
        setOptions(prev => orderOptions(prev,item));
    }

    const handleNext = async () => {
        setScreen(prev => {
            return {
                ...prev,
                personalImprovementGoals: false,
                personalImprovementObstacles: true
            }
        });
        dispatch(updateLoggedInUser({isPersonalImprovementGoalsSeen: true,personalImprovementGoals: options.filter(it => it.isSelected).sort((a,b) => a.order-b.order).map(it => it.option)}));
    }

    useEffect(() => {
        if(loggedInUser.personalImprovementGoals){
            setOptions((prev) => {
                return prev.map(op => {
                    return loggedInUser.personalImprovementGoals.indexOf(op.option) === -1? op:{...op,isSelected: true,order: loggedInUser.personalImprovementGoals.indexOf(op.option)+1};
                })
            })
        }
    }, [loggedInUser.personalImprovementGoals]);

    return (<>
        <TempMain>
            <Wrapper>
                <ProgressBarAndBackButton handleBack={handleBack} completedSteps={2}/>
                <h1 className='text-2xl font-semibold text-secondry-color mb-2'>What are Your Key Professional and Personal Growth Goals with XLRate?</h1>
                <p className="text-secondry-color">Help us tailor your experience to unlock your peak performance and leadership potential.</p>

                <Options options={options} handleClick={handleClick}/>

                <div className="mt-10">
                    <PrimaryButton onClick={handleNext}>
                        Continue to Personalise My XLRate Experience
                    </PrimaryButton>
                </div>
            </Wrapper>
        </TempMain>  
    </>)
}


export {PersonalImprovementGoals};