import React, { useEffect, useState } from "react";
import "./Surveys.css";

import { MdAddBox } from "react-icons/md";
import {
  AddSurveyForm,
  Survey
} from "./components";
import { useDispatch } from "react-redux";
import {
  getSurveyTitleById,
  createSurveyTitle,
  updateSurveyTitle,
  getAllSurveyTitles,
} from "../../Store/actions/index.js";
import {Modal} from "../../components/index.js";
import { toast } from "react-toastify";


function Index() {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ questions: [] });
  const [toggleUpdateForm, setToggleUpdateForm] = useState(false);
  const [toggleInsertForm, setToggleInsertForm] = useState(false);
  const [allSurveyTitles, setAllSurveyTitles] = useState([]);
  // _id to update resources
  const [_id, set_id] = useState("");

  const handleUpdate = async (id) => {
    set_id(id);
    const res = await getSurveyTitleById(id);

    setFormData((prev) => {
      return {
        ...res.surveyTitle,
      };
    });
    setToggleUpdateForm(true);
  };

  const handdleUpdateSubmit = async () => {
    const res = await updateSurveyTitle(_id, formData);
    if (res.success) {
      toast.success(res.message);

      const updatedSurveyTitles = allSurveyTitles.map((st) =>
        st._id === _id ? { ...st, ...res.surveytitle } : st
      );
      setAllSurveyTitles(updatedSurveyTitles);
    } else {
      toast.error(res.message);
    }
  };

  const handdleAddSurvey = async (e) => {
    setFormData(() => {
      return {
        questions: [],
      };
    });
    setToggleInsertForm(true);
  };

  const handdleAddSurveySubmit = async (e) => {
    const res = await createSurveyTitle(formData);

    if (res.success) {
      toast.success(res.message);

      const updatedSurveyTitles = [...allSurveyTitles, { ...res.surveytitle }];
      setAllSurveyTitles(updatedSurveyTitles);
    } else {
      toast.error(res.message);
    }
  };

  useEffect(() => {
    (async () => {
      const res = await getAllSurveyTitles();
      if (res.success) {
        setAllSurveyTitles(res.surveyTitles);
      } else {
        toast.error(res.message);
      }
    })();
  }, [dispatch]);

  return (
    <>
      <div className="container mx-auto">
        <h2 className="text-center my-5">Survey Title</h2>
        <div>
          <div className="surveytitle-container">
            {allSurveyTitles.map((item, index) => {
                  return (
                    <Survey
                      surveyTitle={item}
                      key={index}
                      handleUpdate={handleUpdate}
                      setAllSurveyTitles={setAllSurveyTitles}
                    />
                  );
                })}
          </div>
        </div>
        

        {/* Modals  */}
        {/* update Modal  */}
        <Modal
          title="Update"
          saveBtnText="Update"
          cancelBtnText="Cancel"
          isOpen={toggleUpdateForm}
          setIsOpen={() => setToggleUpdateForm(!toggleUpdateForm)}
          onSubmit={handdleUpdateSubmit}
        >
        <AddSurveyForm formData={formData} setFormData={setFormData} />
        </Modal>
        {/* insert Modal  */}
        <Modal
          title="Add"
          saveBtnText="Insert"
          cancelBtnText="Cancel"
          isOpen={toggleInsertForm}
          setIsOpen={() => setToggleInsertForm(!setToggleInsertForm)}
          onSubmit={handdleAddSurveySubmit}
        >
          <AddSurveyForm formData={formData} setFormData={setFormData} />
        </Modal>

        <div className="add-new-surveytitle my-Modal-container hide">
          <div className="my-Modal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Add Survey Title
                  </h5>
                </div>
                <div className="modal-body">
                  {/* <%- include('./partials/surveytitleUpdateInsertFrom.ejs') %> */}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary close-btn"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="button" className="btn btn-primary save-btn">
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* add question btn   */}
        <div className="add_questions" title="Add Survey Title">
          <MdAddBox onClick={handdleAddSurvey}/>
          {/* <FaPlus onClick={handdleAddSurvey} /> */}
        </div>
      </div>
    </>
  );
}

export default Index;
