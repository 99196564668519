import React from 'react';
import {BsInfoCircle} from "react-icons/bs";
import {Tooltip}  from 'react-tooltip';


function InfoIconWithToolTip(props) {
  const remProps = {...props};
  const content = remProps.content;
  const tooltipStyle = remProps.tooltipStyle || {};
  delete remProps.content;
  delete remProps.tooltipStyle;
  const rest = content.replace(/[^a-zA-Z]/gi, '');
  const id = `${rest}-tooltip`;


  return (
    <>
      <ToolTipInfoIcon  {...remProps} id={id} style={{cursor:"pointer",display:"inline-block"}}/>
      <RectTooltip style={tooltipStyle} html={content} anchorSelect={`#${id}`}  place={props.place? props.place:"top"}/>   
    </>
  )
}


function ToolTipInfoIcon(props) {
  return <BsInfoCircle {...props}/>;
}

function RectTooltip(props){
  const remProps = {...props};
  const children = remProps.children;
  delete remProps.children;
  
  return <Tooltip {...remProps} variant="info" style={{fontSize: "14px",maxWidth: "200px",fontWeight: "normal"}}>
            {children}
         </Tooltip> 
}



export {
  InfoIconWithToolTip,
  ToolTipInfoIcon,
  RectTooltip
};


