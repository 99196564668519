import React, { useMemo } from 'react';
import Color from "color";

const text = `Please feel that you can speak to me… or use the wellbeing resources (link/phone number) if you would like more support…. Don’t forget that you can report unacceptable behaviours using our hotline….. You anonymity and privacy has been protected.`;

function HeatMap({histogramData}) {
    const rows = useMemo(()=>Math.ceil(Math.sqrt(histogramData.length)),[histogramData]);

    const getColor = (val) => {
      let color = '';
      let opc = 1;
  
      if(val > 0){
        color = Color("#009c1a");
        opc = val/100;
      }
      else {
        color = Color("#da0000");
        opc = Math.abs(val)/100;
      }
    
      return color.alpha(opc);
    }  

  return (
        <>
          <div className={`grid ${`grid-cols-${rows}`} grid-rows-${rows} w-[100%] h-[100%] relative`}>
            {
              histogramData && histogramData.map((item,i) => {
                return <div  key={i} className={`border hover:cursor-pointer relative`} title={item.XLScore<0? text:item.XLScore} style={{backgroundColor: getColor(item.XLScore)}} >
                  {
                    item.streak && <div title={`How many days this individual has been below 50`} className="absolute top-1 right-1 w-5 h-5 rounded-full bg-blue-800 flex justify-center items-center text-[12px] text-white font-bold cursor-pointer">{item.streak}</div>
                  }
                </div>
              })
            }
          </div>
        </>
  )
}

export {HeatMap};