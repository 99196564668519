import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    resetPassword,
    getUserByResetPasswordToken
} from "../Store/actions";
import {PrimaryButton, UI} from "../components";
import { useSelector } from 'react-redux';
import {TempMain} from "../layout";


function ResetPassword() {
    const queryParameters = new URLSearchParams(window.location.search);
    const token = queryParameters.get("token")
    const [data, setData] = useState({email: "",password: "",confirmPassword: "",token});
    const navigator = useNavigate();
    const location = useLocation();
    const [isConditionChecked, setIsConditionChecked] = useState(location.state?.iConsent? true:false);
    const [isAlreadyAcceptedConsent, setIsAlreadyAcceptedConsent] = useState(true);
    const loggedInUser = useSelector(state => state.loggedInUser);
    const { label, color } = calculatePasswordStrength(data.password);


    const handleChange = (e)=> {
        setData(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(data);
        const res = await resetPassword(data);
        if(res.success){
            toast.success("New Password Added Successfully!");
            navigator("/login");
        }
        else{
            toast.error(res.message);
        }
    }

    useEffect(() => {
        (async ()=> {
            if(loggedInUser){
                return navigator(`/score/user/${loggedInUser._id}`);
            }
            const res = await getUserByResetPasswordToken(token);
            // console.log(res);
            if(res.success){
                const {user} = res;
                if(user.termAndConditions?.isAccepted){
                    setIsAlreadyAcceptedConsent(true);
                }
                else {
                    setIsAlreadyAcceptedConsent(false);
                }
            }
            else {
                // toast.error(res.message);
            }
        })()
    }, [token,loggedInUser,navigator]);


    return (
        <>
        <TempMain>
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto min-h-[calc(100vh-64px)] lg:py-0">
                <div className="w-full p-6 rounded-lg shadow-lg  md:mt-0 sm:max-w-md sm:p-8">
                    <div className="flex justify-center pb-3 border-b">
                        <img src="https://res.cloudinary.com/dsnjr85ys/image/upload/v1704211715/Copy_XLRate_new_logo_landscape_gewvxp.png" alt="logo" className='w-[100%]'/>
                    </div>
                    <h2 className="pt-3 mb-1 text-xl font-bold leading-tight tracking-tight text-secondry-color md:text-2xl ">
                        Create New Password
                    </h2>
                    <form className="mt-4 space-y-4 lg:mt-5 md:space-y-5" action="#">
                        <div>
                            <label htmlFor="password" className="block mb-2 text-sm font-medium text-secondry-color flex items-center gap-1">New Password <LockIcon/></label>
                            <UI.PasswordInputWithEye placeholder={"Enter your new password"} value={data.password} onChange={handleChange} name={"password"} id={"password"}/>
                            <small className={`font-bold ${color}`}>{data.password? label:""}</small>
                            {
                                data.password && <br/>
                            }
                        </div>
                        
                        <div>
                            <label htmlFor="confirm-password" className="block mb-2 text-sm font-medium text-secondry-color flex items-center gap-1">Confirm password <LockIcon/></label>
                            <UI.PasswordInputWithEye placeholder={"Re-enter your new password"} value={data.confirmPassword} onChange={handleChange} name={"confirmPassword"} id={"confirmPassword"}/>
                            <small className='text-red-600 font-bold'>{data.password !== data.confirmPassword? "Pasword did't match":""}</small>
                        </div>

                        {
                            (isAlreadyAcceptedConsent === false) && <TermAndConditionsBox isConditionChecked={isConditionChecked} setIsConditionChecked={setIsConditionChecked}/>
                        }

                        <PrimaryButton disabled={(isConditionChecked || isAlreadyAcceptedConsent)? false:true}  onClick={handleSubmit}>
                            Create Password
                        </PrimaryButton>
                    </form>
                </div>
            </div>
        </TempMain>
        </>
    )
}

export default ResetPassword;


function TermAndConditionsBox({isConditionChecked,setIsConditionChecked}){
    const navigator = useNavigate();

    const handleTermsAndConditionsClick = (e) => {
        e.preventDefault();
        const prevURL = window.location.pathname+window.location.search;
        navigator("/terms-and-conditions",{state: {prevURL}});

    }

    return (
        <div className="flex items-start">
            <div className="flex items-center h-5">
                <input checked={isConditionChecked} onChange={(e)=>setIsConditionChecked(e.target.checked)} id="newsletter" aria-describedby="newsletter" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 accent-accent-color focus:ring-3 focus:ring-primary-300" required=""/>
            </div>
            <div className="ml-3 text-sm">
                <label htmlFor="newsletter" className="font-light text-secondry-color">I accept the <a onClick={handleTermsAndConditionsClick} href='/terms-and-conditions' className="font-medium text-accent-color underline">Terms & Conditions</a></label>
            </div>
        </div>
    )
}



function calculatePasswordStrength(password) {
    let strength = 0;

    // Criteria for password strength
    const lengthCriteria = password.length >= 8;
    const extraLengthCriteria = password.length >= 12;
    const upperCaseCriteria = /[A-Z]/.test(password);
    const lowerCaseCriteria = /[a-z]/.test(password);
    const digitCriteria = /[0-9]/.test(password);
    const specialCharCriteria = /[^A-Za-z0-9]/.test(password);

    // Increment strength based on criteria
    if (lengthCriteria) strength += 1;
    if (extraLengthCriteria) strength += 1;
    if (upperCaseCriteria) strength += 1;
    if (lowerCaseCriteria) strength += 1;
    if (digitCriteria) strength += 1;
    if (specialCharCriteria) strength += 1;

    // Ensure the strength is capped at 5
    strength = Math.min(strength, 5);

    // Map the strength score to a descriptive label and color
    let strengthLabel;
    let color;
    switch (strength) {
        case 0:
            strengthLabel = "Very Weak: Your password is too short and lacks complexity. Consider adding more characters and using a mix of uppercase, lowercase, digits, and special characters.";
            color = "text-red-600";
            strength = 0;
            break;
        case 1:
            strengthLabel = "Weak: Your password is still too weak. Try making it longer and include uppercase letters, digits, and special characters.";
            color = "text-orange-600";
            strength = 1;
            break;
        case 2:
            strengthLabel = "Moderate: Your password is somewhat strong, but it could be more secure. Consider adding more characters and mixing in uppercase letters, digits, and special characters.";
            color = "text-yellow-600";
            strength = 2;
            break;
        case 3:
            strengthLabel = "Good: Your password is good, but there's room for improvement. Make sure it has a good mix of character types and is longer.";
            color = "text-green-400";
            strength = 3;
            break;
        case 4:
            strengthLabel = "Strong: Your password is strong. To make it even stronger, ensure it is sufficiently long and combines various character types.";
            color = "text-green-500";
            strength = 4;
            break;
        case 5:
            strengthLabel = "Very Strong: Excellent! Your password is very strong and should be safe from most attacks. Keep using strong passwords like this.";
            color = "text-green-600";
            strength = 5;
            break;
        default:
            strengthLabel = "Unknown";
            color = "grey";
    }

    return {
        label: strengthLabel,
        color: color
    };
}


function LockIcon(){

    return (<>
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="13" height="13" viewBox="0 0 26 26">
         <path d="M 16 0 C 13.789063 0 11.878906 0.917969 10.6875 2.40625 C 9.496094 3.894531 9 5.824219 9 7.90625 L 9 9 L 12 9 L 12 7.90625 C 12 6.328125 12.390625 5.085938 13.03125 4.28125 C 13.671875 3.476563 14.542969 3 16 3 C 17.460938 3 18.328125 3.449219 18.96875 4.25 C 19.609375 5.050781 20 6.308594 20 7.90625 L 20 9 L 23 9 L 23 7.90625 C 23 5.8125 22.472656 3.863281 21.28125 2.375 C 20.089844 0.886719 18.207031 0 16 0 Z M 9 10 C 7.34375 10 6 11.34375 6 13 L 6 23 C 6 24.65625 7.34375 26 9 26 L 23 26 C 24.65625 26 26 24.65625 26 23 L 26 13 C 26 11.34375 24.65625 10 23 10 Z M 16 15 C 17.105469 15 18 15.894531 18 17 C 18 17.738281 17.597656 18.371094 17 18.71875 L 17 21 C 17 21.550781 16.550781 22 16 22 C 15.449219 22 15 21.550781 15 21 L 15 18.71875 C 14.402344 18.371094 14 17.738281 14 17 C 14 15.894531 14.894531 15 16 15 Z"></path>
    </svg>
    </>)
}


















