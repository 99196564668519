// import React from 'react';
import {confirmAlert} from "react-confirm-alert"


 const useConfermAlert = (title='Confirm to submit',message='Are you sure to do this.') => {


    const use = (data,onYes)=> {
        confirmAlert({
            title: title,
            message: message,
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    onYes(data);
                }
              },
              {
                label: 'No',
                onClick: () => {
                  //
                }
              }
            ]
          });
    }

    return use;
}



export default useConfermAlert