import React from 'react'

function PopUpWraper({children,absolute}) {
    const myStyle = absolute? {backgroundColor: "rgba(0, 0, 0, 0.5)",position: "fixed",top: "64px",left: "0",width: "100vw",zIndex: 1000}:{}; 

    return (<>
        <section style={myStyle} className="bg-gray-200 flex justify-center overflow-auto h-[calc(100vh-64px)]">
            <div className="flex flex-col items-center justify-center  px-2 mx-auto max-w-[100%]">
                <div className="w-full border-blue-100 p-6 bg-primary-color rounded-3xl shadow  md:mt-0 sm:max-w-lg sm:p-8 max-h-[80vh] overflow-auto">
                    {children}
                </div>
            </div>
        </section>
        </>)
}

export default PopUpWraper;