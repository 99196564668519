import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { createEvent, createTag, getAllTagsOfUser } from '../../../Store/actions';
import { toast } from 'react-toastify';
import { InfoIcon } from '../../../components';
import mixpanel from 'mixpanel-browser';


const defaultTags = [
    {tag: "Relationships",selected: false},
    {tag: "Personal",selected: false},
    {tag: "Professional",selected: false},
    {tag: "Sad",selected: false},
    {tag: "Happy",selected: false},
    {tag: "Excited",selected: false},
    {tag: "Anxious",selected: false},
    {tag: "Angry",selected: false},
    {tag: "Home",selected: false},
    {tag: "Family",selected: false},
    {tag: "Work",selected: false}
]

const tooltipTexts = [
    `What's happening or recently happened that could be affecting your state? Capturing these types of events really help your get better insights into what affects your state. You will be able to view these events in your timeline.`,
    `Quickly tag feelings or events or add your own tags for events and feelings that occur regularly. You can view these on the timeline in your dashboard and they are a fast way of increase your insight into what is affecting your state and wellbeing.`,
    `Here you can add more details about what's happening or happened. (e.g. perhaps you had a great meeting, or a argument with someone). Capturing these details will give you better insights into what affects your state. You will be able to view, edit or add new events the timeline on your dashboard.`,
    `When did the event happen? It may be today or it could be something that occured in the past that you are thinking about today.`,
  ]

function AddEvent({setShowAddEvent,user,userId,isManagementEvent,currentActivity}) {
    const [tags, setTags] = useState(defaultTags);
    const [eventDetail, setEventDetail] = useState("");
    const [eventDate, setEventDate] = useState(moment().format("YYYY-MM-DDTHH:mm"));

    const handleAddEvent = async () => {
        const selectedTags = tags.filter(tag => tag.selected).map(t => t.tag);

        const data = {
            date: eventDate,
            tags: selectedTags,
            description: eventDetail,
            user: user._id,
            surveyName: currentActivity,
            isManagementEvent
        }

        const res = await createEvent(data); 
        
        if(res.success){
            toast.success("Event Created Successfully");    
            setShowAddEvent(false);
        }
        else {
            toast.success(res.message);    
        }
    }

    useEffect(() => {
        (async ()=> {
            const res = await getAllTagsOfUser(user._id);
            const tags = res.tags.map(tag => {return {...tag,selected: false}});
            setTags(prev => {
                const uniqueTags = [];
                const allTags = [...prev,...tags].filter(item => {
                    if(uniqueTags.indexOf(item.tag) === -1){
                        uniqueTags.push(item.tag);
                        return item;
                    }

                    return undefined;
                });
                return allTags;
            });
        })()
    }, [user]);


  return (
    <div className="py-6 flex flex-col justify-center sm:py-12">
        <div className="relative py-3 sm:max-w-xl sm:mx-auto">
            <div className="relative px-4 py-10 bg-white mx-2 md:mx-8 md:mx-0 shadow rounded-3xl sm:p-10">
                <div className="max-w-[100%] w-[500px] mx-auto">
                    <div className="flex items-center space-x-5">
                    <div className="h-14 w-14 bg-yellow-200 rounded-full flex flex-shrink-0 justify-center items-center text-yellow-500 text-2xl font-mono">i</div>
                        <div className="block pl-2 font-semibold text-xl self-start text-gray-700">
                            <h2 className="leading-relaxed">Create an Event</h2>
                            <p className="text-sm text-gray-500 font-normal leading-relaxed">What's on your mind at the moment? <span className='cursor-pointer' id='event-title' data-tip={tooltipTexts[0]}><InfoIcon/></span></p>
                        </div>
                    </div>
                    <div className="divide-y divide-gray-200">
                    <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                        <ShowAndSelectTags tags={tags} setTags={setTags}/>
                        <AddNewTag setTags={setTags} tags={tags} user={user}/>
                        <EventDetail setEventDetail={setEventDetail} eventDetail={eventDetail}/>
                        <EventDate user={user} eventDate={eventDate} setEventDate={setEventDate}/>
                    </div>
                    <div className="pt-4 flex items-center space-x-4">
                        <button onClick={()=> setShowAddEvent(false)} className="flex justify-center items-center w-full text-gray-900 px-4 py-3 rounded-md focus:outline-none">Skip</button>
                        <button onClick={handleAddEvent} className="bg-blue-500 flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none">Create</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AddEvent;




function ShowAndSelectTags({tags,setTags}){
    
    const handleSelectTag = (item) => {

        setTags(prev => {
            return prev.map(t => {
                if(t.tag !== item.tag){
                    return t;
                }
                return {...t,selected: !t.selected}
            })
        })
    }   
    

    return (<>
        <div className="flex flex-col">
            <label className="leading-loose">Select Tags <span className='cursor-pointer' id='event-select-tags' data-tip={tooltipTexts[1]}><InfoIcon/></span></label>
            <div className="flex flex-wrap gap-2">
                {
                    tags.map((item,i) => {
                        return <span onClick={()=>handleSelectTag(item)} key={i} style={{backgroundColor: item.selected? "green":"",color: item.selected?"white":""}} className='cursor-pointer border px-2 py-1 rounded-md'>{item.tag}</span>
                    })
                }
            </div>
        </div>
    </>)
}

function AddNewTag({setTags,tags,user}){
    const [tag, setTag] = useState("");

    const handleAddTag = async () => {
        if(tag){
            const isExists = tags.find(item => item.tag.toLowerCase() === tag.toLowerCase());
            
            if(isExists){
              toast.error("Duplicate tags are not allowed");
              return;  
            }

            const res = await createTag({user: user._id,tag});

            if(res.success){
                mixpanel.identify(user._id);
                mixpanel.track("Add Own Tag!");
                setTags(prev => {
                    return [...prev,{tag,selected: true}]
                });
                setTag("");
            }
            else {
                toast.error("Someting Went Wrong!");
            }
        }
        else {
            toast.error("Empity tags are not allowed");
        }
    }

    return (<>
        <div className="flex flex-col">
            <label className="leading-loose">Add New Tag</label>
            <div className="flex gap-3">
                <input value={tag} onChange={(e)=> setTag(e.target.value)} type="text" className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" placeholder="Work.."/>
                <button onClick={handleAddTag} title='Click here to add tag' className="btn btn-primary add-tag-btn">+</button>
            </div>
        </div>
    </>)
}

function EventDetail({setEventDetail,eventDetail}){
    
    return (<>
        <div className="flex flex-col">
            <label className="leading-loose">Add details <span className='cursor-pointer' id='event-description' data-tip={tooltipTexts[2]}><InfoIcon/></span></label>
            <input onChange={(e)=>setEventDetail(e.target.value)} value={eventDetail} type="text" className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" placeholder="Optional"/>
        </div>
    </>)
}

function EventDate({eventDate,setEventDate,user}){

    const handleChange = (e) => {
        setEventDate(e.target.value);
        mixpanel.identify(user._id);
        mixpanel.track("Event Time/Date Changed!");
    }

    return (<>
            <div className="flex flex-col">
                <label className="leading-loose">Date of event <span className='cursor-pointer' id='event-date' data-tip={tooltipTexts[3]}><InfoIcon/></span></label>
                <div className="relative focus-within:text-gray-600 text-gray-400">
                <input onChange={handleChange} value={eventDate} type="datetime-local" className="pr-4 pl-10 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" placeholder="25/02/2020"/>
                    <div className="absolute left-3 top-2">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
                    </div>
                </div>
            </div>
    </>) 
}


function FormSkeleton(){

    return (<>
    <div className="py-6 flex flex-col justify-center sm:py-12">
        <div className="relative py-3 sm:max-w-xl sm:mx-auto">
            <div className="relative px-4 py-10 bg-white mx-2 md:mx-8 md:mx-0 shadow rounded-3xl sm:p-10">
                <div className="max-w-[100%] min-h-[600px] w-[500px] mx-auto">
                <div role="status" className="space-y-2.5 animate-pulse max-w-lg">
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2 max-w-[480px]">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2 max-w-[400px]">
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2 max-w-[480px]">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2 max-w-[440px]">
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-32"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2 max-w-[360px]">
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2 max-w-[480px]">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2 max-w-[400px]">
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2 max-w-[480px]">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2 max-w-[440px]">
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-32"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2 max-w-[360px]">
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2 max-w-[480px]">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2 max-w-[400px]">
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2 max-w-[480px]">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2 max-w-[440px]">
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-32"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2 max-w-[360px]">
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2 max-w-[480px]">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2 max-w-[400px]">
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2 max-w-[480px]">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2 max-w-[440px]">
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-32"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2 max-w-[360px]">
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                    </div>
                    <span className="sr-only">Loading...</span>
                </div>
                </div>
            </div>
        </div>
    </div>
    </>)
}

export {FormSkeleton};