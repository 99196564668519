import React, { useEffect, useState } from "react";
import "./Surveys.css";

import { MdAddBox } from "react-icons/md";
import {
  getSurveyTitleById,
  createSurveyTitle,
  updateSurveyTitle,
  getUniversalSurveyTitle,
  addQuestions,
  removeQuestion,
  addActionChallenges,
  removeActionChallenge,
  removeOneActionChallenge
} from "../../Store/actions/index.js";
import {Modal} from "../../components/index.js";
import { toast } from "react-toastify";
import {XLDataToJSON} from "../../utils/index.js";
import { XL_FACTORS,ACTIVITIES } from "../../constants/index.js";
import {
  Survey,
  AddSurveyForm
} from "./components";



function Index() {
  const [formData, setFormData] = useState({ questions: [] });
  const [toggleUpdateForm, setToggleUpdateForm] = useState(false);
  const [toggleInsertForm, setToggleInsertForm] = useState(false);
  const [universalSurveytitle, setUniversalSurveytitle] = useState(null);
  const [rawQuestions, setRawQuestions] = useState("");
  const [rawChallenges, setRawChallenges] = useState("");
  const [XLFactor, setXLFactor] = useState("Fun");
  const [survey, setSurvey] = useState("work");
  const [questions, setQuestions] = useState([]);
  const [actionChallenges, setActionChallenges] = useState([]);
  const actionChallengesForTeam = actionChallenges.filter(ac => ac.for === "team");
  const actionChallengesForIndividual = actionChallenges.filter(ac => !ac.for || ac.for === "individual");
  const [actionChallengeFor, setActionChallengeFor] = useState("individual");
  
  
  // _id to update resources
  const [_id, set_id] = useState("");

  const handleUpdate = async (id) => {
    set_id(id);
    const res = await getSurveyTitleById(id);

    setFormData((prev) => {
      return {
        ...res.surveyTitle,
      };
    });
    setToggleUpdateForm(true);
  };

  const handdleUpdateSubmit = async () => {
    const res = await updateSurveyTitle(_id, formData);
    if (res.success) {
      toast.success(res.message);
      setUniversalSurveytitle(res.surveytitle);
    } else {
      toast.error(res.message);
    }
  };

  const handdleAddSurvey = async (e) => {
    setFormData(() => {
      return {
        questions: [],
      };
    });
    setToggleInsertForm(true);
  };

  const handdleAddSurveySubmit = async (e) => {
    const res = await createSurveyTitle({...formData,isUniversal: true});

    if (res.success) {
      toast.success(res.message);
      setUniversalSurveytitle(res.surveytitle);
    } else {
      toast.error(res.message);
    }
  };


  const handleRawQuestionAdd = async ()=> {
    const questions = [];
    const json = XLDataToJSON(rawQuestions);
    json.forEach(ques => {
      questions.push({question: ques[0],XLFactor});
    });

    const res = await addQuestions(universalSurveytitle._id,questions);

    if(res.success){
      toast.success(res.message);
      setQuestions(res.questions);
    }
    else{
      toast.error(res.message)
    }
  };

  const handlrRawActionsChallengesAdd = async ()=> {
    const challenges = [];
    const json = XLDataToJSON(rawChallenges);
    json.forEach(ques => {
      challenges.push(ques[0]);
    });
    const data = {
      XLFactor,
      survey,
      for: actionChallengeFor,
      challenges
    }

    const res = await addActionChallenges(universalSurveytitle._id,data);
    
    if(res.success){
      toast.success(res.message);
      setActionChallenges(res.actionChallenges);
      setRawChallenges("");
    }
    else {
      toast.error(res.message);
    }
  }

  const handleDeleteQuestion = async (questionId)=> {
    // universalSurveytitle._id
    const res = await removeQuestion(universalSurveytitle._id,questionId);
    if(res.success){
      toast.success(res.message);
      // console.log(res);
      setQuestions(res.questions);
    }
    else{
      toast.error(res.message);
    }
  }

  useEffect(() => {
    (async () => {
      const res = await getUniversalSurveyTitle();
      console.log(res);
      if (res.success) {
        setUniversalSurveytitle(res.surveytitle);
        setQuestions(res.surveytitle.questions);
        setActionChallenges(res.surveytitle.actionChallenges);
      } else {
        toast.error(res.message);
      }
    })();
  }, []);

  return (
    <>
      <div className="container">
        <h2 className="text-center my-5">Survey Title</h2>

        {/* text area to add questions  */}
        <div className="my-10  w-full py-5 bg-white shadow-lg rounded ">
              <div className="p-3">
                <h5>Select XL Factor</h5>
                <select
                className="border w-full py-1" 
                value={XLFactor} 
                name="XLFactor" 
                onChange={(e)=> setXLFactor(e.target.value)}>
                  {
                    XL_FACTORS.map(factor => <option key={factor} value={factor}>{factor}</option>)
                  }
                </select>
              </div>
              <div className="w-full p-3">
                <textarea 
                value={rawQuestions} 
                onChange={(e)=>setRawQuestions(e.target.value)} 
                placeholder="Paste XML here..." 
                className="w-[100%] p-2 focus:outline-1 focus:outline-blue-500 font-bold border-[0.1px] resize-none h-[120px] border-[#9EA5B1] rounded-md"></textarea>
                <div className="flex justify-end">
                  <button onClick={handleRawQuestionAdd} className="text-sm font-semibold absolute bg-[#4F46E5] w-fit text-white py-2 rounded px-3">Add Questions</button>
                </div>
              </div>
        </div>
     
        {/* questions list  */}
        <div className="bg-white shadow-lg rounded mb-10 p-3">
          <h5>Questions</h5>
          <div className="flex flex-col gap-3">
            {
              XL_FACTORS.map(XLFactor => {

                const q = questions.filter(ques =>ques.XLFactor === XLFactor);
                
                return <QuestionWraper 
                key={XLFactor} 
                XLFactor={XLFactor}
                questions={q} 
                handleDeleteQuestion={handleDeleteQuestion}
                />
              })
            }

          </div>
        </div>
        
        {/* textarea to add action challenges  */}
        <div className="my-10  w-full py-5 bg-white shadow-lg rounded ">
              <div className="p-3">
                <h5>Select Survey</h5>
                <select
                className="border w-full py-1" 
                value={survey} 
                name="survey" 
                onChange={(e)=> setSurvey(e.target.value)}>
                  <option value="work">Work</option>
                  <option value="relationship">Relationship</option>
                  <option value="hobby">Hobby</option>
                  <option value="study">Study</option>
                  <option value="sport">Sport</option>
                  <option value="business">Business</option>
                </select>
              </div>
              <div className="p-3">
                <h5>Select XL Factor</h5>
                <select
                className="border w-full py-1" 
                value={XLFactor} 
                name="XLFactor" 
                onChange={(e)=> setXLFactor(e.target.value)}>
                  {
                    XL_FACTORS.map(factor => <option key={factor} value={factor}>{factor}</option>)
                  }
                </select>
              </div>
              <div className="p-3">
                <h5>Select Action Challenges for</h5>
                <select
                className="border w-full py-1" 
                value={actionChallengeFor} 
                name="for" 
                onChange={(e)=> setActionChallengeFor(e.target.value)}>
                  <option value="team">Group/Team</option>
                  <option value="individual">Individual</option>
                </select>
              </div>
              <div className="w-full p-3">
                <textarea 
                value={rawChallenges} 
                onChange={(e)=>setRawChallenges(e.target.value)} 
                placeholder="Paste XML here..." 
                className="w-[100%] p-2 focus:outline-1 focus:outline-blue-500 font-bold border-[0.1px] resize-none h-[120px] border-[#9EA5B1] rounded-md"></textarea>
                <div className="flex justify-end">
                  <button onClick={handlrRawActionsChallengesAdd} className="text-sm font-semibold absolute bg-[#4F46E5] w-fit text-white py-2 rounded px-3">Add Action Challenges</button>
                </div>
              </div>
        </div>
     
        {/* action challenges individual  */}
        <div className="bg-white shadow-lg rounded mb-10 p-3">
          <h5>Action Challenges (Individual)</h5>
          <div className="flex flex-col gap-3">
            {
              ACTIVITIES.map((survey,i) => {
                return <ActionChallengeWraper 
                key={i} 
                survey={survey} 
                actionChallenges={actionChallengesForIndividual}
                universalSurveytitle={universalSurveytitle}
                setActionChallenges={setActionChallenges}
                />
              })
            }

          </div>
        </div>

         {/* action challenges team  */}
         <div className="bg-white shadow-lg rounded mb-10 p-3">
          <h5>Action Challenges (Leader/Manager)</h5>
          <div className="flex flex-col gap-3">
            {
              ACTIVITIES.map((survey,i) => {
                return <ActionChallengeWraper 
                key={i} 
                survey={survey} 
                actionChallenges={actionChallengesForTeam}
                universalSurveytitle={universalSurveytitle}
                setActionChallenges={setActionChallenges}
                />
              })
            }

          </div>
        </div>
           
        <div>
          <div className="surveytitle-container mb-5">
            {
              universalSurveytitle && <Survey
                                        surveyTitle={universalSurveytitle}
                                        handleUpdate={handleUpdate}
                                        setAllSurveyTitles={setUniversalSurveytitle}
                                      />                        
            }
            {
              !universalSurveytitle && <div className="my-10 text-center">You Don't have Universal Survey Yet</div>
            }
          </div>
        </div>
        

        {/* Modals  */}
        {/* update Modal  */}
        <Modal
          title="Update"
          saveBtnText="Update"
          cancelBtnText="Cancel"
          isOpen={toggleUpdateForm}
          setIsOpen={() => setToggleUpdateForm(!toggleUpdateForm)}
          onSubmit={handdleUpdateSubmit}
        >
        <AddSurveyForm formData={formData} setFormData={setFormData} />
        </Modal>
        {/* insert Modal  */}
        <Modal
          title="Add"
          saveBtnText="Insert"
          cancelBtnText="Cancel"
          isOpen={toggleInsertForm}
          setIsOpen={() => setToggleInsertForm(!setToggleInsertForm)}
          onSubmit={handdleAddSurveySubmit}
        >
          <AddSurveyForm formData={formData} setFormData={setFormData} />
        </Modal>

        <div className="add-new-surveytitle my-Modal-container hide">
          <div className="my-Modal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Add Survey Title
                  </h5>
                </div>
                <div className="modal-body">
                  {/* <%- include('./partials/surveytitleUpdateInsertFrom.ejs') %> */}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary close-btn"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="button" className="btn btn-primary save-btn">
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* add question btn   */}
        <div className="add_questions" title="Add Survey Title">
          {
            !universalSurveytitle && <MdAddBox onClick={handdleAddSurvey}/>
          }
        </div>
      </div>
    </>
  );
}

export default Index;


function ActionChallengeWraper({survey,actionChallenges,universalSurveytitle,setActionChallenges}){

  const [visivility,setVisivility] = useState(false);

  const handleShowHide= ()=> {
    setVisivility(prev => !prev)
  }

  return (
  <>
  <div className="border-b pb-2">
              <div className="flex justify-between">
                <div>
                  {survey}
                </div>
                <div>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input onChange={handleShowHide} type="checkbox" value="" className="sr-only peer" checked={visivility}/>
                    <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{visivility?"Hide":"Show"}</span>
                  </label>
                </div>
              </div>
              <div className={`flex bg-gray-100 p-4 rounded flex-col gap-3 ${visivility? "":"hidden"}`}>
              {
                  XL_FACTORS.map(XLFactor => {

                      const ac = actionChallenges.find(ac => ac.XLFactor === XLFactor && ac.survey === survey);

                      return <FactorWrapper 
                        key={XLFactor} 
                        XLFactor={XLFactor}
                        actionChallenges={ac} 
                        universalSurveytitle={universalSurveytitle}
                        setActionChallenges={setActionChallenges}
                      />
                    })
              }
              </div>
            </div>
  </>
  )
};


function FactorWrapper({XLFactor,actionChallenges,universalSurveytitle,setActionChallenges}){

  const [visivility,setVisivility] = useState(false);

  const handleShowHide= ()=> {
    setVisivility(prev => !prev);
  }

  const handleDeleteActionChallenge = async (actionChallengeId)=> {
    const res = await removeActionChallenge(universalSurveytitle._id,actionChallengeId);
    if(res.success){
      toast.success(res.message);
      setActionChallenges(res.actionChallenges);
    }
    else {
      toast.error(res.message);
    }
  }

  return (
  <>
            <div className="border-b pb-2">
              <div className="flex justify-between">
                <div>
                  {XLFactor} ({actionChallenges?.challenges?.length})
                </div>
                <div className="flex gap-3">
                  <div>
                    <label className="relative inline-flex items-center cursor-pointer">
                      <input onChange={handleShowHide} type="checkbox" value="" className="sr-only peer" checked={visivility} />
                      <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                      <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{visivility? "Hide":"Show"}</span>
                    </label>
                  </div>
                  <div className={`${actionChallenges?.challenges?.length > 0? "":"hidden"}`}>
                    <button 
                    className="bg-red-500 text-white font-bold text-sm px-3 py-1 rounded"
                    onClick={()=> handleDeleteActionChallenge(actionChallenges._id)}
                    >Delete All</button>
                  </div>
                </div>
              </div>
                <div className={`flex bg-gray-100 p-4 rounded flex-col gap-3 ${visivility? "":"hidden"}`}>
                {
                    actionChallenges?.challenges.map((ac,i) => {
                      return <ActionChallenge 
                      key={i} 
                      actionChallenge={ac} 
                      i={i} 
                      actionChallengeId={actionChallenges._id}
                      setActionChallenges={setActionChallenges}
                      universalSurveytitle={universalSurveytitle} />
                    })
                }
              </div>
            </div>
  </>)
}


function ActionChallenge({actionChallenge,i,universalSurveytitle,actionChallengeId,setActionChallenges}){

  const handleDeleteChallenge = async (challenge)=> {

    const res = await removeOneActionChallenge(universalSurveytitle._id,actionChallengeId,challenge);

    if(res.success){
      toast.success(res.message);
      setActionChallenges(res.actionChallenges);
    }
    else {
      toast.error(res.message);
    }
  }

  return (
    <>
    <div className="flex justify-between p-2 shadow-sm bg-white rounded">
      <div className="grow-1">
        {i+1}: {actionChallenge}
      </div>
      <div className="">
        <button 
        className="bg-red-500 text-white font-bold text-sm px-3 py-1 rounded"
        onClick={()=> handleDeleteChallenge(actionChallenge)}
        >Delete</button>
      </div>
    </div>
    </>
  )
}


function QuestionWraper({XLFactor,questions,handleDeleteQuestion}){


  const [visivility,setVisivility] = useState(false);

  const handleShowHide= ()=> {
    setVisivility(prev => !prev)
  }

  return (<>
            <div className="border-b pb-2">
              <div className="flex justify-between">
                <div>
                  {XLFactor} ({questions.length})
                </div>
                <div>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input onChange={handleShowHide} type="checkbox" value="" className="sr-only peer" checked={visivility} />
                    <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{visivility?"Hide":"Show"}</span>
                  </label>
                </div>
              </div>
                <div className={`flex bg-gray-100 p-4 rounded flex-col gap-3 ${visivility? "":"hidden"}`}>
                {
                    questions.map((ques,i) => {
                      return <Question key={i} ques={ques} i={i} handleDeleteQuestion={handleDeleteQuestion}/>
                    })
                }
              </div>
            </div>
  </>)
}


function Question({ques,i,handleDeleteQuestion}){

  return (
    <>
    <div className="flex justify-between p-2 shadow-sm bg-white rounded">
      <div className="grow-1">
        {i+1}: {ques.question}
      </div>
      <div className="">
        <button 
        className="bg-red-500 text-white font-bold text-sm px-3 py-1 rounded"
        onClick={()=> handleDeleteQuestion(ques._id)}
        >Delete</button>
      </div>
    </div>
    </>
  )
}



