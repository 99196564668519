import React from 'react';
import { PrimaryButton} from '../../../components';
import {TempMain,CenterContent} from "../../../layout";

function FirstSurveyScreen({setScreen}) {

    const handleNext = () => {
      setScreen(true);
    }

    return (
      <TempMain>
           <CenterContent>
            <h1 className="text-md mb-2 font-bold text-secondry-color tracking-widest text-center">
                  Ok. Lets take your very 1st ‘CogniPulse’.
            </h1>

            <p className="text-sm  tracking-widest mb-5 text-center text-secondry-color">
                (It takes about 50 seconds)
            </p>

            <PrimaryButton onClick={handleNext}>
                  Next
            </PrimaryButton>
           </CenterContent>
        </TempMain>
    )
}

export {FirstSurveyScreen};