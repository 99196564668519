import {UI} from "../../components";
import { useEffect, useState } from "react";
import { 
    completeActionChallenge, 
    completeStep, 
    createActionChallenge, 
    getActionChallengeByName,
    setNumberOfActionChallenges
 } from "../../Store/actions";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

function ActionChallengePopup({show,setShow,currentActionChallenge,factor,userId,forTeam}) {
    const [actionChallenge, setActionChallenge] = useState(null);
    const [isAccepted, setIsAccepted] = useState(false);
    const [completedSteps, setCompletedSteps] = useState(0);
    const [isAllStepsCompleted, setIsAllStepsCompleted] = useState(false);
    const numberOfActionChallenges = useSelector(state => state.numberOfActionChallenges);
    const dispatch = useDispatch();

    const handleClick = (e) => {
        setShow(false);
    }

    const handleAccept = async () => {
        const data = {
            factor,
            impact: "immediate" ,
            subFactor: currentActionChallenge.subFactor,
            actionChallenge: currentActionChallenge.actionChallenge,
            steps: currentActionChallenge.steps.map(step => {return {step}}),
            for: forTeam? 'team':'individual',
            user: userId 
        };

        const res = await createActionChallenge(data);
     
        if(res.success){
            setActionChallenge(res.actionChallenge);
            setIsAccepted(true); 
            dispatch(setNumberOfActionChallenges(numberOfActionChallenges+1));
        }
        else {
            toast.error(res.message);
        }
    }

    const handleStepComplete = async (stepId) => {
        const res = await completeStep({actionChallengeId: actionChallenge._id,stepId});

        if(res.success){
            setActionChallenge(res.actionChallenge);
        }
        else {
            toast.error("Someting Went Wrong!");
        }
    }

    const handleChallengeComplete = async () => {
        const res = await completeActionChallenge({actionChallengeId: actionChallenge._id});
        if(res.success){
            setActionChallenge(res.actionChallenge);
        }
        else {
            toast.error("Something Went Wrong!");
        }
    }

    useEffect(() => {
        if(currentActionChallenge){
            (async ()=>{
                const res = await getActionChallengeByName({actionChallenge: currentActionChallenge?.actionChallenge,user: userId});
    
                if(res.success && res.actionChallenge){
                    setActionChallenge(res.actionChallenge);
                    setIsAccepted(true);
                }
                else {
                    setIsAccepted(false);
                    setActionChallenge({
                        ...currentActionChallenge,
                        steps: currentActionChallenge?.steps?.map(step => {return {step}})
                    });
                }
            })()
        }
    }, [currentActionChallenge,userId]);

    useEffect(() => {
        if(actionChallenge){
            const cSteps = actionChallenge.steps !== undefined? actionChallenge?.steps.filter(step => step.isCompleted):[];

            setCompletedSteps(cSteps.length);
            if(actionChallenge.steps && cSteps.length === actionChallenge.steps.length){
                setIsAllStepsCompleted(true); 
            }
            else {
                setIsAllStepsCompleted(false); 
            }
        }
    }, [actionChallenge]);


    if(!show || !actionChallenge){
        return null;
    }

   
    return (
        <UI.PopUpWraper absolute={true}>
            <div className="w-[100%] max-w-[500px] rounded-md">
                <div className="w-full flex justify-end border-b mb-3 pb-3"><UI.CrossSVG onClick={handleClick}/></div>
                <div className="flex flex-col items-center">
                    <h1 className='text-[20px]'>Action Challenge</h1>
                    <p className='text-[12px]'>{actionChallenge.actionChallenge}</p>
                    <p className='text-[12px] flex justify-center items-center gap-3'>{isAccepted? <UI.Button className="text-white px-2 bg-green-800">Accepted</UI.Button>:<UI.Button onClick={handleAccept} className="px-2 text-white">I Accept</UI.Button>}</p>
                    <div className="w-[50%]"><UI.ProgressBar totalSteps={actionChallenge.steps?.length || 0} completedSteps={completedSteps}/></div>
                    <div className="my-10 flex flex-col gap-5 w-[80%]">
                        {
                            actionChallenge.steps.map((step,index) => (
                                <div key={index} className="flex justify-between items-center gap-4">
                                    <span className="text-xs md:text-md lg:text-base">{step.step}</span>
                                    {
                                        step.isCompleted? <>
                                        <UI.Button className="text-white text-[12px] mt-0  bg-green-800 text-white px-2 rounded-md w-auto">
                                            Completed
                                        </UI.Button>
                                        </>:<>
                                        <UI.Button onClick={()=>handleStepComplete(step._id)} disabled={!isAccepted} className="text-white text-[12px] mt-0  bg-indigo-800 text-white px-2 rounded-md w-auto">
                                            Done
                                        </UI.Button>
                                        </> 
                                    }
                                </div>
                            ))
                        }
                    </div>
                    
                    {
                        actionChallenge.isCompleted? <>
                        <UI.Button className="w-full py-3 px-4 text-white bg-green-800">
                            Challenge Completed
                        </UI.Button>
                        </>:<>
                        <UI.Button onClick={handleChallengeComplete} className="text-white w-full py-3 px-4" disabled={!isAllStepsCompleted}>
                            Complete
                        </UI.Button>
                        </>
                    }
                </div>
            </div>
        </UI.PopUpWraper>
    )
}

export default ActionChallengePopup;