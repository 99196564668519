import {visitToTreeData} from "../../../utils";
import { v4 as uuid } from 'uuid';

export function getAllUsers(root){
    let userids = new Set();
    let users = new Set();
    let userSurveys = new Set();

    visitToTreeData(
        root,
        async function (d) {

          if(d.data.email){
            userids.add(d.data._id);
            users.add(d.data);
            d.data.surveyPreference?.surveys.forEach(s => {
              userSurveys.add(s);
            })
          }  
  
        },
        function (d) {
          return d.children;
        }
      );

      return {userids,users,userSurveys};
  }


export function getSurveyTitleUsers(node){

    const surveyTitles = new Set();

    visitToTreeData(
      node,
        async function (d) {
          if(d.data.surveys){
            d.data.surveys.forEach(s => {
              surveyTitles.add(s);
            })
          }
        },
        function (d) {
          return d.children;
        }
    );

      return surveyTitles;
  }


export function MakeTreeFromArray(allNodes,loggedInUser) {

    // console.log(allNodes);
    const managementGroups = loggedInUser.managementGroups || [];
    let rootNodes = allNodes.filter(node => !node.parent);

     // console.log(d);
    if(loggedInUser.role === "manager"){
      rootNodes = allNodes.filter(node => managementGroups.indexOf(node._id) !== -1);
    }

    
    rootNodes.forEach(rootNode => {
      visitToTreeData(
        rootNode,
        async function (d) {
          
          addIdAndName(d);

          if (d.children && d.children.length > 0) {
              let children = allNodes.filter((node) => node.parent === d._id);
              d.children = children;
          }
          
  
          let users = [];
          if(d.users && d.users.length > 0){
              d.users.forEach(u => {
                  if(u._id){
                    users.push({...u,parent:d._id});
                  }
              })
          }
  
          if(d.children){
                d.children = [...d.children,...users];
          }
          else{
              d.children = [...users];
          }
  
          if(d.children && d.children.length === 0){
            // delete d.children
            d.children = [];
          }
           
        },
        function (d) {
          return d.children;
        }
      );
    
    })

    return rootNodes;
  }


export function getUsersFromGroupWithoutTiming(groupId,root,scheduledTimes){

    const users = new Set();

    visitToTreeData(root);
    function visitToTreeData(node){

      const isGroup = scheduledTimes.find(t => t.group === node._id);

      if((groupId === node._id || !isGroup) && !node.email){
        node?.users.forEach(user => {
          users.add(user._id)
        })
      }

      if(node.children){
        node.children.forEach(child => visitToTreeData(child))
      }
    }

    return [...users]; 
  }


export function addIdAndName(node) {
  const id = uuid();
  node.id = `${node._id}---${id}`;
  node.name = node.email? `${node.surname} ${node.forename}`:`${node.groupname}`;
}  