import React from 'react';
import {BsInfoCircle} from "react-icons/bs";

function InfoIcon({className,size,onClick}) {


  return (
    <BsInfoCircle onClick={onClick}  className={className} size={size} style={{cursor:"pointer",display:"inline-block"}}/>
  )
}

export default InfoIcon;