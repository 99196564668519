import React from 'react';

function CookiePolicy() {
  return (
    <section className="bg-gray-200 w-full">
            <div className='w-[700px] max-w-full px-4 mx-auto py-5'>
                <h1 class="text-xl font-bold mb-2">Cookie Policy</h1>

                <p className='mb-6'>Effective Date: 18/5/2024</p>

                <h2 class="text-xl font-bold mb-2">Introduction</h2>

                <p class="mb-4">This Cookie Policy explains how XLRate ("we", "us", or "our") uses cookies and similar tracking technologies when you visit our website and use our platform. By continuing to use our website and services, you agree to the use of cookies as described in this policy</p>

                {/* <h2 class="text-xl font-bold mb-2">Information We Collect</h2>

                <ul class="list-disc list-inside mb-4 ml-6">
                    <li className='mb-3'><span className='font-bold'>Personal Data</span> We collect information that you voluntarily provide to us, including your name, email address, and organization name. Additionally, we collect psychometric data and text input about your psychological state to analyze and provide intuitive visual metrics</li>
                    <li className='mb-3'><span className='font-bold'>Usage Data</span> We collect information about your interactions with our platform, such as the pages you visit, the time and date of your visits, and the features you use. This data helps us understand how you use the platform and improve our services</li>
                    <li className='mb-3'><span className='font-bold'>Device Data</span> We collect information about the device you use to access our platform, including your IP address, browser type, operating system, and device identifiers</li>
                    <li><span className='font-bold'>Cookies and Tracking Technologies</span> We use cookies and similar tracking technologies to track activity on our platform and hold certain information. For more details, please refer to our [Cookie Policy]</li>
                </ul> */}

                <h2 class="text-xl font-bold mb-2">What Are Cookies?</h2>

                <p class="mb-4">
                    Cookies are small text files that are stored on your device (computer, tablet, or mobile) when you visit a website. They are widely used to make websites work, or work more efficiently, as well as to provide information to the website owners
                </p>

                {/* <p class="mb-4">
                    We use the information we collect for various purposes, including:
                </p>

                <ul class="list-disc list-inside mb-4 ml-6">
                    <li className='mb-3'><span className='font-bold'>Providing and Improving Services</span> To provide, operate, and maintain our platform, and to improve and personalize your experience</li>
                    <li className='mb-3'><span className='font-bold'>Analytics </span> To analyze usage and performance of our platform, and to develop new features and services</li>
                    <li className='mb-3'><span className='font-bold'>Communications </span> To communicate with you about your account, updates, and other information related to our services</li>
                    <li className='mb-3'><span className='font-bold'>Security </span> To detect, prevent, and address technical issues and to safeguard your information</li>
                    <li className='mb-3'><span className='font-bold'>Automated Decision-Making </span> Automated Decision-Making: To provide personalized recommendations and insights based on automated data analysis. You have the right to object to automated decision-making and request human intervention</li>
                </ul> */}

                <h2 class="text-xl font-bold mb-2">Types of Cookies We Use</h2>

                <ul class="list-disc list-inside mb-4 ml-6">
                    <li className='mb-3'><span className='font-bold'> Essential Cookies</span> These cookies are necessary for the website to function properly. They enable basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies <br/>Example: session_id (expires when the session ends)</li>
                    <li className='mb-3'><span className='font-bold'> Performance Cookies</span> These cookies collect information about how visitors use our website, such as which pages are visited most often and if they receive error messages. This data helps us improve the performance and user experience of our website<br/>Example: _ga (Google Analytics cookie, expires after 2 years)</li>
                    <li className='mb-3'><span className='font-bold'> Functional Cookies</span> These cookies allow our website to remember choices you make (such as your username, language, or the region you are in) and provide enhanced, more personalised features<br/>Example: language_preference (expires after 1 year)</li>
                    <li className='mb-3'><span className='font-bold'> Targeting/Advertising Cookies</span> These cookies are used to deliver advertisements that are more relevant to you and your interests. They are also used to limit the number of times you see an advertisement and to help measure the effectiveness of advertising campaigns<br/>Example: ads_user_id (expires after 1 year)</li>
                    <li className='mb-3'><span className='font-bold'> Analytics Cookies</span> These cookies help us understand how our website is being used, measure the effectiveness of our marketing campaigns, and help us customise and improve our website for you<br/>Example: _gid (Google Analytics cookie, expires after 24 hours)</li>
                </ul>

                <h2 class="text-xl font-bold mb-2">How We Use Cookies</h2>
                <p className='mb-6'>We use the information collected by cookies to</p>

                <ul class="list-disc list-inside mb-4 ml-6">
                    <li className='mb-3'>Ensure the proper functioning of our website and platform</li>
                    <li className='mb-3'>Enhance the performance and user experience of our website</li>
                    <li className='mb-3'>Personalise your experience by remembering your preferences and settings</li>
                    <li className='mb-3'>Analyse website traffic and user behaviour to improve our services</li>
                    <li className='mb-3'>Deliver relevant advertisements and measure their effectiveness</li>
                </ul>




                <h2 class="text-xl font-bold mb-2">Legal Basis for Using Cookies</h2>
                <p className='mb-6'>For essential cookies, our legal basis is our legitimate interest in ensuring the proper functioning of our website and platform. For non-essential cookies (performance, functional, targeting/advertising, and analytics cookies), we rely on your consent</p>
                


                <h2 class="text-xl font-bold mb-2">Cookie Consent Banner</h2>
                <p className='mb-6'>When you first visit our website, you will see a cookie consent banner that provides information about the types of cookies we use and allows you to manage your consent preferences. You can choose to accept all cookies or customise your cookie settings</p>
           
           
                <h2 class="text-xl font-bold mb-2">Managing Cookies</h2>
                <p className='mb-6'>You can manage your cookie preferences through our cookie consent banner or by adjusting your web browser settings. Most browsers allow you to</p>

                <ul class="list-disc list-inside mb-4 ml-6">
                    <li className='mb-3'>See what cookies you have and delete them on an individual basis</li>
                    <li className='mb-3'>Block third-party cookies</li>
                    <li className='mb-3'>Block cookies from particular sites</li>
                    <li className='mb-3'>Block all cookies from being set</li>
                    <li className='mb-3'>Delete all cookies when you close your browser</li>
                </ul>
                <p className='mb-6'>Please note that if you choose to block or delete cookies, certain features of our website may not function properly, and your user experience may be affected</p>

                <p className='mb-6'>For more information on how to manage cookies, please refer to the help section of your browser or visit <a href='https://www.allaboutcookies.org/' target='_blank' rel='noreferrer' className='text-blue-400'>www.allaboutcookies.org</a></p>


                <h2 class="text-xl font-bold mb-2">Third-Party Cookies</h2>
                <p className='mb-6'>Some cookies on our website may be set by third parties, such as analytics and advertising partners. These third parties may use cookies to collect information about your online activities over time and across different websites. We do not control these third-party cookies and recommend that you review the privacy policies of these third parties for more information</p>
               
                <h2 class="text-xl font-bold mb-2">Changes to This Cookie Policy</h2>
                <p className='mb-6'>We may update this Cookie Policy from time to time to reflect changes in our practices and services. We will notify you of any significant changes by posting the new Cookie Policy on this page and updating the "Effective Date" at the top. We encourage you to review this Cookie Policy periodically to stay informed about our use of cookies</p>
               
               
                <h2 class="text-xl font-bold mb-2">Contact Us</h2>
                <p className=''>If you have any questions about this Cookie Policy or our data practices, please contact us at</p>
                <p className=''>Data Protection Officer (DPO)</p>
                <p className='mb-6'>Email: DPO@xlrate.10x.systems</p>

                <p>By using our website and platform, you acknowledge that you have read and understood this Cookie Policy and agree to our use of cookies as described.</p>
            </div>
    </section>
  )
}

export {CookiePolicy};