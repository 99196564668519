function PopupContainer({children}){

    return (<>
    <div className='fixed top-0 left-0 w-full h-full overflow-y-auto z-[2000] bg-black/80'>
        <div className='bg-primary-color p-8 w-[500px] max-w-full mx-auto rounded-lg my-10 transform'>
            {children}
        </div>
    </div>
    </>)
};


export {PopupContainer};