import React, { useEffect, useRef, useState } from 'react';
// import {UI} from "../../components";
// import Confetti from 'react-confetti';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { toast } from 'react-toastify';
import CircularSlider from "react-circular-slider-svg";
import { useAnimate } from "framer-motion";

const MIN_ZOOM = .7;
const MAX_ZOOM = 1.3;
const ANIMATION_DURATION = 1;
const MIN_DURATION = 0;
const AJJESTMENT_Y = 10;
const EASE_FUNC = "linear";

function SurveyUI({questions,answers,setAnswers,handleSubmit,isSubmiting,surveyQuestionInstruction}) {
  const [sliderValues, setSliderValues] = React.useState([4]);
  const [tempSliderValues, setTempSliderValues] = React.useState([4]);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [steps,setSteps] = useState({stpesCount: questions.map((q,i) => i+1),currentStep: 1});
  const nextButtonRef = useRef(null);
  const [starttime, setStarttime] = useState(null);
  const [isBarClicked, setIsBarClicked] = useState(false);
  const [isNextButtonClicked, setIsNextButtonClicked] = useState(true);
  const [showQues, setShowQues] = useState(true);
  const [scope,prevAnimation,nextAnimation] = useSliderAnimation();

  const next = () => {
      if(isSubmiting){
        console.log("is submitting is true");
        return;
      }
      if(!showQues){
        console.log("showQues is true");
        return;
      }
      if(!isBarClicked){
        toast.error("Please Touch/Click Slider");
        return;
      }

      if(currentIndex === questions.length-1){
        handleSubmit(sliderValues[0],questions[currentIndex].XLFactor,starttime);
        return;
      }

      setTempSliderValues(sliderValues);

      setCurrentIndex(p => p+1);

      setSteps((prev) => {
        return {
          ...prev,
          currentStep: prev.currentStep+1
        }
      });

      
      setAnswers((prev)=>{
        if(!prev){
          return [{
            starttime,
            endtime: new Date().getTime(),
            rating: sliderValues[0],
            XLFactor: questions[currentIndex].XLFactor
          }]
        }
        const obj = {
          starttime,
          endtime: new Date().getTime(),
          rating: sliderValues[0],
          XLFactor: questions[currentIndex].XLFactor
        }
        const isExists = prev.find(ans => ans.XLFactor === questions[currentIndex].XLFactor);
        if(isExists){
          return prev.map(ans => ans.XLFactor === questions[currentIndex].XLFactor? obj:ans)
        }
        return [...prev,obj];
      })

      const prevAns = answers?.find(ans => ans.XLFactor === questions[currentIndex+1].XLFactor);

      if(prevAns){
        setSliderValues([prevAns.rating]);
      }
      else {
        setSliderValues([4]);
        setIsBarClicked(false);
      }
      setIsNextButtonClicked(true);
      setStarttime(new Date().getTime());
      
      // animation 
      setShowQues(false);
      nextAnimation();
      setTimeout(() => {
        setShowQues(true);
      }, (ANIMATION_DURATION*1000)+10);
  }

  const back = () => {
    if(!showQues){
      return;
    }
    if(currentIndex === 0){
      return;
    }

    setTempSliderValues(sliderValues);

    setIsBarClicked(true);
    setCurrentIndex(p => p-1);
    setSteps((prev) => {
        return {
          ...prev,
          currentStep: prev.currentStep-1
        }
    });
    const prevAns = answers.find(ans => ans.XLFactor === questions[currentIndex-1].XLFactor);
    setSliderValues([prevAns.rating]); 
    setIsNextButtonClicked(false);

    // animation 
    setShowQues(false);
    prevAnimation();
    setTimeout(() => {
      setShowQues(true);
    }, (ANIMATION_DURATION*1000)+10);
  }


  useEffect(() => {
    setStarttime(new Date().getTime());
  }, []);


  return (
      <section className='mx-auto max-w-screen-xl pb-4 px-4 sm:px-8'>
        <div className="mx-auto max-w-[200px] mb-[30px]">
          <ProgressBar steps={steps}/>
        </div>
        <div className="mx-auto max-w-6xl mb-[50px]">
            <div className="flex gap-10 items-center justify-center">
                <div ref={scope} className="relative w-[300px] h-[300px] flex justify-center items-center mobile-slider">
                    <ArcSlider 
                    surveyQuestionInstruction={surveyQuestionInstruction}
                    isNextButtonClicked={isNextButtonClicked} 
                    setIsBarClicked={setIsBarClicked} 
                    values={sliderValues} 
                    setValues={setSliderValues} 
                    currentQuestion={questions[currentIndex]?.question}
                    showQues={showQues}
                    tempValues={tempSliderValues}
                    setTempValues={setTempSliderValues}
                    />
                </div>
            </div>
            <div className="flex justify-between max-w-[300px] mx-auto">
                  <h1 className='text-red-600 text-[16px]'>Strongly disagree</h1>
                  <h1 className='text-green-600 text-[16px]'>Strongly agree</h1>
            </div>
        </div>
        <div className=" flex justify-center items-center gap-4">
            <button disabled={currentIndex !== 0? false:true} className='focus:ring w-[50px] h-[50px] shadow-md rounded-full flex justify-center items-center disabled:cursor-not-allowed' onClick={back}>
                <FaChevronLeft className='text-[20px] font-bold text-secondry-color'/>
            </button>
            <button  className='focus:ring w-[50px] h-[50px] shadow-md rounded-full flex justify-center items-center disabled:cursor-not-allowed' onClick={next} ref={nextButtonRef}>
                {
                  currentIndex !== questions.length-1 && <FaChevronRight className='text-[20px] font-bold text-accent-color'/>
                }
                {
                  currentIndex === questions.length-1 && <>
                      {
                        isSubmiting? <span className='text-[14px] font-bold text-secondry-color'>Saving..</span>:<span className='text-[14px] font-bold'>Finish</span>
                      }
                  </>
                }
            </button>
        </div>
      </section>
  )
}

export default SurveyUI;



function ArcSlider({surveyQuestionInstruction,currentQuestion,values,setValues,setIsBarClicked,tempValues,setTempValues}){
  

  const handleChange = (v)=> {
    setIsBarClicked(true);
    setValues([v]);
    setTempValues([v]);
  }



  return (
    <>
      <div id='arc-slider-1' className='absolute w-[300px] h-[225px] flex justify-center items-center'>
        <MyCircularSlider values={values} handleChange={handleChange}/>
      </div>

      <div id='arc-slider-2' className='absolute w-[300px] h-[225px] flex justify-center items-center'>
        <MyCircularSlider values={tempValues} handleChange={handleChange}/>
      </div>

      <div id='question-container' className="select-none w-[230px] h-[230px] absolute top-[75px] rounded-full flex flex-col text-center text-[14px] p-3 justify-center items-center  text-secondry-color">
            {
              surveyQuestionInstruction && <p className="text-center">{surveyQuestionInstruction}</p>
            }
           {currentQuestion}
      </div>
    </>
  )
}


function MyCircularSlider({values,handleChange,bg}){

  return (
    <CircularSlider
          className="this-is-my-class-name"
          size={300}
          minValue={1}
          maxValue={7}
          startAngle={60}
          endAngle={300}
          angleType={{
            direction: "cw",
            axis: "-y"
          }}
          handle1={{
            value: values[0],
            onChange: handleChange
          }}
          arcColor="rgb(79 70 229)"
          arcBackgroundColor={"#CECECE"}
        />
  )
}


function ProgressBar({steps}) {
  const completedSteps = steps.currentStep-1;
  const totalSteps = steps.stpesCount.length;
  const percentage = (completedSteps/totalSteps)*100;
 

  return (
    <>
      <div>
        <span id="ProgressLabel" className="sr-only">Loading</span>

        <span
          role="progressbar"
          aria-labelledby="ProgressLabel"
          aria-valuenow="75"
          className="block rounded-full bg-[#CECECE]"
        >
          <span
          className="block h-[4px] rounded-full bg-indigo-600"
          style={{width: `${percentage}%`}}
          />
        </span>
      </div>
    </>
  )
}


function useSliderAnimation(){
  const [scope, animate] = useAnimate();
  
  const nextAnim = () => {
    animate([
      ["#arc-slider-1",{scale: MIN_ZOOM,y: AJJESTMENT_Y,opacity: 0},{ease: EASE_FUNC,duration: MIN_DURATION}],
      ["#arc-slider-2",{scale: 1, y: 0,opacity: 1},{ease: EASE_FUNC,duration: MIN_DURATION,at: "<"}],
      ["#question-container",{scale: MIN_ZOOM,opacity: 0},{ease: EASE_FUNC,duration: MIN_DURATION,at: "<"}],
      ["#arc-slider-1",{scale: 1,y: 0, opacity: 1},{ease: EASE_FUNC,duration: ANIMATION_DURATION, at: MIN_DURATION}],
      ["#arc-slider-2",{scale: MAX_ZOOM,y: -AJJESTMENT_Y, opacity: 0},{ease: EASE_FUNC,duration: ANIMATION_DURATION,at: MIN_DURATION}],
      ["#question-container",{scale: 1,opacity: 1},{ease: EASE_FUNC,duration: ANIMATION_DURATION,at: MIN_DURATION}]
    ]);
  }

  const prevAnim = () => {
    animate([
      ["#arc-slider-1",{scale: MAX_ZOOM,y: -AJJESTMENT_Y, opacity: 0},{ease: EASE_FUNC,duration: MIN_DURATION}],
      ["#arc-slider-2",{scale: 1, y: 0, opacity: 1},{ease: EASE_FUNC,duration: MIN_DURATION,at: "<"}],
      ["#question-container",{opacity: 0,scale: MAX_ZOOM},{ease: EASE_FUNC,duration: MIN_DURATION,at: "<"}],
      ["#arc-slider-1",{scale: 1,y: 0, opacity: 1},{ease: EASE_FUNC,duration: ANIMATION_DURATION, at: MIN_DURATION}],
      ["#arc-slider-2",{scale: MIN_ZOOM,y: AJJESTMENT_Y, opacity: 0},{ease: EASE_FUNC,duration: ANIMATION_DURATION,at: MIN_DURATION}],
      ["#question-container",{opacity: 1,scale: 1},{ease: EASE_FUNC,duration: ANIMATION_DURATION,at: MIN_DURATION}]
    ]);
  }

  return [scope,prevAnim,nextAnim];
}