import { useState,useEffect} from 'react';
import { Disclosure} from '@headlessui/react';
import {HiPlus} from 'react-icons/hi';
import { 
  NavLink, 
  useLocation, 
  // useLocation, 
  useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActionChallengeOfTeam,
  getTodaysUnCompletedSurveyLinkOfUser,
  logoutUser,
  // setBadge, 
  setIsMenuOpen, 
  // setPreviousRoute
} from "../../Store/actions";
import {MdSelfImprovement} from "react-icons/md";
import {IoMdRocket} from "react-icons/io";
import {BsLightningFill} from "react-icons/bs";
import {useAnimate} from "framer-motion";
// import {useMixPanelEvents} from "../useMixPanelEvents";
import { GiStairsGoal } from "react-icons/gi";
import {getActionChallengeOfUser,setNumberOfActionChallenges} from "../../Store/actions";
import { IoWarningOutline } from "react-icons/io5";
import moment from 'moment';


const navigation = [
  { name: 'Manage Client Users', href: '/admin/manage/users', current: true,admin: true },
  { name: 'Manage Survey', href: '/admin/manage/surveytitle', current: false,admin: true },
  { name: 'Manage Universal Survey', href: '/admin/manage/universal/surveytitle', current: false,admin: true },
  { name: 'Manage Groups', href: '/admin/manage/groups', current: false ,admin: true,manager: true},
  { name: 'Completed Surveys', href: '/admin/surveys/completed-surveys', current: false,admin: true },
  { name: 'Un Regular Com Surveys', href: '/admin/un-regular-completed-surveys', current: false,admin: true },
  { name: 'Add Client Users', href: '/admin/add/user', current: false,admin: true },
  { name: 'Your Dashboard', href: '/score/user/', current: false, user: true,admin: true,manager: true},
  { name: 'Dashboard Guide', href: '/score/user/', current: false, user: true,admin: true,manager: true,payload: {isDashboardTutorialFinished: false}},
  { name: 'Edit Activity Title', href: '/user-survey-selection', current: false ,user: true,admin: true,manager: true,payload: "updateOnlyActivity"},
  { name: 'Purpose & Goals', href: '/purpose-and-goal', current: false ,user: true,admin: true,manager: true},
  { name: 'Manage Notifications', href: '/manage/notifications', current: false ,user: true,admin: true,manager: true},
  // { name: 'temp onboarding', href: '/on-boarding', current: false ,user: false,admin: true,manager: false}
];


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar() {
  const loggedInUser = useSelector(state=> state.loggedInUser);
  
  return (
    <>
    <Disclosure as="nav" className="bg-gray-800 text-white fixed w-full top-0 z-50 z-[1000]">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center justify-between">
          <Left loggedInUser={loggedInUser}/>
          <Middle/>
          <Right loggedInUser={loggedInUser}/>
        </div>
      </div>
    </Disclosure>
    </>
  )
}



function Left({loggedInUser}){
  const dispatch = useDispatch();
  const isMenuOpen = useSelector(state => state.globalStates.isMenuOpen);

  // const {
  //   hemBurger,
  //   hemBurgerPlus,
  //   menuItems
  // } = useMixPanelEvents(loggedInUser?._id);

  const handleLogOut = (e) => {
    // menuItems(e);
    dispatch(logoutUser());
    dispatch(setIsMenuOpen(false));
  }
  
  const handleHemburgerClick = (e) => {
    dispatch(setIsMenuOpen(!isMenuOpen));

    if(!isMenuOpen){
      // hemBurger(e);
      return;
    }

    // hemBurgerPlus(e);
  }
  
  const menuItemsHoveredClick = (e) => {
    // menuItems(e);
    dispatch(setIsMenuOpen(false));
  }



  return(<>
        <div className="flex items-center relative">
          {
            isMenuOpen? <HiPlus 
            onClick={handleHemburgerClick} 
            // onMouseEnter={hemBurger} 
            id='plus' 
            color='white' 
            className='w-10 h-10 rotate-45 rounded-full cursor-pointer p-[10px] hover:bg-gray-700'/>:<svg id="hem" 
            onClick={handleHemburgerClick} 
            // onMouseEnter={hemBurger} 
            xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 rounded-full cursor-pointer p-[10px] hover:bg-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" /></svg>        
          }
          {
            isMenuOpen && <>
              <nav className={`transition-all absolute top-[40px]  bg-gray-800 rounded menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52`}>
                <div className='flex flex-col gap-1'>
                  {(loggedInUser && loggedInUser.role) && navigation.map((item) => {
                  
                      return ((item.admin && loggedInUser.role === "admin") || (item.manager && loggedInUser.role === "manager") || (item.user && loggedInUser.role === "user")) && <NavLink 
                                                        key={item.name}
                                                        to={item.name === "Your Dashboard"  || item.name === "Dashboard Guide"? `${item.href}${loggedInUser._id}`:item.href}
                                                        state={{payload: item.payload}}
                                                        onClick={menuItemsHoveredClick}
                                                        className={classNames(
                                                          item.name === isMenuOpen ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                          `px-3 py-2 rounded-md text-sm font-medium no-underline`
                                                        )}
                                                        // onMouseEnter={menuItems}
                                                        > 
                                                          {item.name}
                                                        </NavLink>
                    })}

                    {
                      (loggedInUser && loggedInUser.role) && <button
                      onClick={handleLogOut}
                      // onMouseEnter={menuItems}
                      className="bg-brand-peach text-gray-800 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium no-underline"
                      >
                        Logout
                      </button>
                    }
                </div>
              </nav>
            </>
          }
        </div>
  </>)
}

function Middle(){
  return(<>
    <div className='order-[10] md:order-[0] px-2'>
      <NavLink to="/" className="no-underline text-white text-lg">
          {/* XLRate */}
      </NavLink>
    </div>
  </>)
}

function Right({loggedInUser}){
  return(<>
  <div>
    {
      !loggedInUser? <>
      {
        window.location.pathname !== "/terms-and-conditions" && <LoginAndSignupBtns/>
      }
      </>:<>
      <ScoreAndUserInfo loggedInUser={loggedInUser}/>
      </> 
    }
  </div>
  </>)
}

function LoginAndSignupBtns(){


  return(<>
                   <NavLink 
                  to="/login"
                  className="bg-brand-sky-blue text-gray-800 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium no-underline"
                  > 
                    Login
                  </NavLink>
                   {/* <NavLink 
                  to="/signup"
                  className="bg-brand-aqua-green ml-3 text-gray-800 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium no-underline"
                  > 
                    Signup
                  </NavLink> */}

  </>)
}


function ScoreAndUserInfo({loggedInUser}){
  const PIPoints = useSelector(state => state.globalStates.PIPoints);
  const IStreak = useSelector(state => state.globalStates.IStreak);
  const [scope] = useAnimate();
  const [currentIStreak] = useState(IStreak);
  const [currentPIPoints] = useState(PIPoints);
  const [currentPILevel] = useState(parseFloat((PIPoints || 0)/500+1).toFixed(2));
  // const {userStats} = useMixPanelEvents(loggedInUser?._id);



  return(<>
      <div className='flex items-center md:gap-4'>
        <div className='text-white'>
                {
                  loggedInUser && <h6 
                  // onMouseEnter={(e)=>userStats(e,"User Name")} 
                  className='mb-0 text-center hidden md:block'>{loggedInUser.forename} {loggedInUser.surname}</h6>
                }
        </div>
        <div ref={scope} className='text-white flex items-center gap-2'>
            <OutstandingWarning/>
            <ActionChallenge loggedInUser={loggedInUser}/>
            <div 
            // onMouseEnter={(e)=>userStats(e,"Personal Improvement Points")} 
            className="relative item flex items-center" 
            title="Personal Improvement Points">
                    <span id='personal-improvement-points' className="inline-block item flex items-center rounded-full">
                          <span><MdSelfImprovement/></span>
                          <span>{currentPIPoints}</span>
                     </span>
            </div>
            <div 
            // onClick={(e)=>userStats(e,"Personal Improvement Level")} 
            // onMouseEnter={(e)=>userStats(e,"Personal Improvement Level")} 
            className="relative item flex items-center" 
            title="Personal Improvement Level">
                    <span 
                      id='personal-improvement-level'
                      className="inline-block item flex items-center rounded-full">
                          <span><IoMdRocket/></span>
                          <span>{currentPILevel}</span>
                      </span>
            </div>
            <div 
            // onClick={(e)=>userStats(e,"Improvement Streak")} 
            // onMouseEnter={(e)=>userStats(e,"Improvement Streak")} 
            className="relative item flex items-center" 
            title="Improvement Streak">
                      <span 
                      id='improvement-streak'
                      className="item flex items-center rounded-full">
                          <span><BsLightningFill/></span>
                          <span>{currentIStreak}</span>
                      </span>
            </div>
        </div>
      </div>
  </>)
}


function ActionChallenge({loggedInUser}) {
  const numberOfActionChallenges = useSelector(state => state.numberOfActionChallenges);
  const navigator = useNavigate();
  const dispatch = useDispatch();
  

  const handleClick = () => {
    navigator("/action-challenges");
  }


  useEffect(() => {
    (async ()=> {
          let len = 0;
          const res = await getActionChallengeOfUser({userId: loggedInUser._id});
          const res1 = await getActionChallengeOfTeam({userId: loggedInUser._id});

          if(res.success){
            len += res.actionChallenges.length;
          }
          if(res1.success){
            len += res1.actionChallenges.length
          }
          dispatch(setNumberOfActionChallenges(len));
    })()
  }, [loggedInUser,dispatch]);

  return (
    <>
    <div id="introSelector12" className="relative item flex items-center" title="Action Challenges">
        <span onClick={handleClick} id='Action Challenges' className="cursor-pointer inline-block item flex items-center gap-1 rounded-full">
              <span><GiStairsGoal /></span>
              <span>{numberOfActionChallenges}</span>
         </span>
    </div>
    </>
  )
}


function OutstandingWarning(){
      const loggedInUser = useSelector(state => state.loggedInUser);
      const [outstandingSurveyLinks, setoutstandingSurveyLinks] = useState(null);
      const location = useLocation();
      const navigator = useNavigate();

      const handleClick = () => {
        if(!outstandingSurveyLinks){
          return;
        }
        navigator(outstandingSurveyLinks[0],{state: {isPostSurvey: true}});
      }

      useEffect(() => {
        (async ()=> {
          const res = await getTodaysUnCompletedSurveyLinkOfUser(loggedInUser._id);

          if(res.success){
            const urls = res.surveyLinks.map(sl => sl.url.replace("https://xlrate.10x.systems",""));
            // if post survey date is today then redirect user to do post survey 
            if(!res.isPostSurveyDone && loggedInUser.postSurveyDate && moment(loggedInUser.postSurveyDate).startOf("day").isSameOrBefore(moment().startOf("day"))){
              const url = `/pre-post-survey/${loggedInUser._id}/${loggedInUser.postSurveyId}`;
              urls.push(url);
            }
            if(urls.length){
              setoutstandingSurveyLinks(urls);
            }
            else {
              setoutstandingSurveyLinks(null);
            }
          }
        })()
      }, [loggedInUser,location.pathname]);      

      if(!outstandingSurveyLinks){
        return null;
      }

      return (
        <>
          <div className="relative item flex items-center" title="Outstanding">
              <span onClick={handleClick} id='Action Challenges' className="cursor-pointer inline-block item flex items-center gap-1 rounded-full">
                    <span><IoWarningOutline className='text-white font-bold'/></span>
                    <span>{outstandingSurveyLinks?.length}</span>
              </span>
          </div>
        </>
      )      

}






