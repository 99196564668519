import { StreakModal } from "./StreakModal";
import { EventModal } from "./EventModal";


const components = {
    StreakModal,
    EventModal
}


export default components;