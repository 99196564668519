import React,{useState} from 'react';
import {Link} from "react-router-dom";
import { toast } from 'react-toastify';
import {
	forgetPassword
} from "../Store/actions";


function ForgetPassword() {
	const [email, setEmail] = useState("");
	const [requesing, setRequesting] = useState(false);
	
	
	  

	const handleSubmit = async () => {
		if(!email){
			return;
		}
		setRequesting(true);
		const res = await forgetPassword(email);
		if(res.success){
			toast.success("Reset Link Send Successfully!")
		}
		else {
			toast.error(res.message);
		}
		setRequesting(false);
	}

  return (
    <>
		<div className="container mx-auto ">
			<div className="flex justify-center px-6 my-12">
				<div className="w-full xl:w-3/4 lg:w-11/12 flex justify-center items-center min-h-[calc(100vh-128px)]">
					<div className="w-full max-w-md bg-white md:p-5 rounded-lg">
						<div className="test1 px-8 mb-4 text-center">
							<h3 className="pt-4 mb-2 text-2xl">Forgot Your Password?</h3>
							<p className="mb-4 text-sm text-gray-700">
								We get it, stuff happens. Just enter your email address below and we'll send you a
								link to reset your password!
							</p>
						</div>
						<form className="test2 px-8 pt-6 pb-8 mb-4 bg-white rounded">
							<div className="mb-4">
								<label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="email">
									Email
								</label>
								<input
									className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
									id="email"
									type="email"
									value={email}
									onChange={(e)=>setEmail(e.target.value)}
									placeholder="Enter Email Address..."
								/>
							</div>
							<div className="mb-6 text-center">
								<button
								onClick={handleSubmit}
									className="w-full px-4 py-2 font-bold text-white bg-blue-500 rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-outline"
									type="button"
								>
								{requesing? "...":"Reset Password"}	
									
								</button>
							</div>
							<hr className="mb-6 border-t" />
							<div className="text-center">
								<Link
									className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
									to="/signup"
								>
									Create an Account!
								</Link>
							</div>
							<div className="text-center">
								<Link
									className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
									to="/login"
								>
									Already have an account? Login!
								</Link>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
    </>
  )
}

export default ForgetPassword;