import React,{useEffect, useState} from 'react';
import { getAllSurveyTitles } from '../../../Store/actions';

function AddSurveyTitleTiming({setTimingData,timingData}) {

    const [surveyTitles, setSurveyTitles] = useState(null);
    const [days] = useState([0,1,2,3,4,5,6]);
    const [unRegularSurvey,setUnRegularSurvey] = useState(timingData.unRegularSurvey || "");
    const [isUnRegularSurvey,setIsUnRegularSurvey] = useState(timingData.unRegularSurvey? true:false);
   

    const handleTimingDataChange = (e) =>{
        setTimingData(prev =>{

            if(e.target.name === "startDate"){
                return {
                    ...prev,
                    duration: {...prev.duration,start: e.target.value}
                } 
            }
            if(e.target.name === "endDate"){
                return {
                    ...prev,
                    duration: {...prev.duration,end: e.target.value}
                } 
            }
            if(e.target.name === "time"){
                const [hours,minutes] = e.target.value.split(":");
                // console.log(hours,minutes);
                return {
                    ...prev,
                    time: {hours,minutes}
                } 
            }

            if(e.target.name === "frequency"){
                if(e.target.value === "daily"){
                    return {
                        ...prev,
                        days: [0,1,2,3,4,5,6],
                        [e.target.name]: e.target.value
                    }
                }
                if(e.target.value === "weekly"){
                    return {
                        ...prev,
                        [e.target.name]: e.target.value
                    }
                }
            }
  
        })
     
    }

   
    useEffect(() => {
        setTimingData((prev)=> {
            if(!isUnRegularSurvey){
                return {
                    ...prev,
                    unRegularSurvey: ""
                }
            }
            return {
                ...prev,
                unRegularSurvey: unRegularSurvey || surveyTitles.filter(s => !s.isUniversal)[0]
            }
        })
    }, [surveyTitles,setTimingData,isUnRegularSurvey,unRegularSurvey]);
    

    useEffect(() => {
        setTimingData(prev => {
            return {
                ...prev,
                surveytitle: surveyTitles? surveyTitles[0]._id:""
            }
        })
    }, [surveyTitles,setTimingData]);
  
    useEffect(() => {
        (async () => {
            const stRes = await getAllSurveyTitles();
            if(stRes.success){
                setSurveyTitles(stRes.surveyTitles);
            }
        })()
    }, []);
    

  return (
    <form className="p-0">
    <div className="mb-3">
      <h1 className='font-bold'>Duration</h1>
      <div className="flex">
        <label htmlFor="start" className="form-label">Start: </label>
        <input 
        type="date" 
        name='startDate' 
        value={timingData?.duration?.start} 
        className="border ml-2 px-2 flex-1" id="start" 
        required 
        onChange={handleTimingDataChange}
        />
      </div>
      <div className="flex mt-1">
        <label htmlFor="end" className="form-label">End: </label>
        <input 
        type="date" 
        name='endDate' 
        value={timingData?.duration?.end} 
        className="border ml-2 px-2 flex-1" id="end" 
        required
        onChange={handleTimingDataChange}
        />
      </div>
    </div>
    <div className="mb-3 flex">
      <label htmlFor="time" className="form-label">Time: </label>
      <input 
      type="time" 
      name='time' 
      value={timingData?.time?.hours +":"+ timingData?.time?.minutes} 
      className="border ml-2 px-2 flex-1" id="time" 
      onChange={handleTimingDataChange}
      required
      />
    </div>
    <div className="mb-3">
        <label htmlFor="frequency" className="font-bold mb-1">Frequency</label>
        <div className="recuring_time_span" id="frequency">
            <select 
            id="frequency" 
            name='frequency' 
            value={timingData?.frequency} 
            className="border w-full mb-2 p-2 rounded-md" 
            required
            onChange={handleTimingDataChange}
            >
                <option value="">Please Select Frequency</option>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
            </select> 
        </div>
        <div className="recuring_details">
            {
                timingData?.frequency && (
                        <>
                           {days.map((d,i)=> {
                            return <DayComp timingData={timingData} setTimingData={setTimingData} key={i} day={d} />
                           })}
                        </>
                )
            }
        </div>
    </div>
    <div className="my-3 flex flex-col">
      <label htmlFor="frequency" className="font-bold">Survey Title</label>
      <select value={timingData.surveytitle} onChange={(e)=>{setTimingData(prev => {return {...prev,surveytitle: e.target.value}})}} className="border p-2 rounded-md">
        {
          surveyTitles && surveyTitles.map((st,index) => {
            return <option value={st._id} key={index}>{st.title}</option>
          })
        }
      </select>
    </div>
    <div className="my-3">
      <div className='flex items-center gap-2 font-bold'><label htmlFor="unRegularSurvey" className="form-label">Un-Regular Survey</label> <input className='' type="checkbox" checked={isUnRegularSurvey} onChange={()=> setIsUnRegularSurvey(p => !p)}/></div>
      <select value={unRegularSurvey} onChange={(e)=>setUnRegularSurvey(e.target.value)} className="border p-2 rounded-md w-full">
        {
          surveyTitles && surveyTitles.filter(s => !s.isUniversal).map((st,index) => {
            return <option value={st._id} key={index}>{st.title}</option>
          })
        }
      </select>
    </div>
  </form>
  )
}

export default AddSurveyTitleTiming;



function DayComp({day,setTimingData,timingData}){
    
    const weekDays = ["S","M","T","W","T","F","S"];

  
    const handdleDayChange = (d)=>{
        if(timingData.frequency === "weekly"){
            setTimingData((prev)=>{
                const index = prev.days.indexOf(d);
                console.log(prev);
                return {
                    ...prev,
                    days: index === -1? [...prev.days,d]:prev.days.filter(item => item !== d)
                }
            })
        }
    }


    return (
        <div onClick={(e)=> handdleDayChange(day)} className={timingData.days.indexOf(day) !== -1? "selected-day":""}>{weekDays[day]}</div>
    )
}