import React, { useEffect, useRef, useState } from "react";
import { 
  Modals, 
  PrimaryButton, 
  UI } from "../../../components";
import { allSurveysWithXLScoresToHistoricalSPQ } from "../../../utils";
import { Chart as ChartJS} from "chart.js/auto";
import { Line } from "react-chartjs-2";
import 'chartjs-adapter-moment';
import zoomPlugin from 'chartjs-plugin-zoom';
import { FACTOR_COLORS,XL_FACTORS } from "../../../constants";
import { useSelector } from "react-redux";


ChartJS.register(zoomPlugin);




function StreamGraphContainer({deshboardData,setIsYourInsightsFullViewOn}){

    const historiclData = deshboardData.historiclData;
    const allSurveysWithXLScores = historiclData.map(item => {return {createdAt: item.year,XLScore: item.XLScore}});
    const historicalOPQ = allSurveysWithXLScoresToHistoricalSPQ(allSurveysWithXLScores);
    const loggedInUser = useSelector(state => state.loggedInUser);
    const [isOpenAddEventsModal, setIsOpenAddEventsModal] = useState(false);
    const [isUpdateEvent, setIsUpdateEvent] = useState(false);
    const [eventData, setEventData] = useState(false);
    const [eventId, setEventId] = useState(null);
    const [events, setEvents] = useState(deshboardData.events);
    const [streamGraphChartData, setstreamGraphChartData] = useState(()=> {
      return {
                labels: historiclData.map(item => new Date(item.year)),
                datasets: [
                    {
                      type: "scatter",
                      label: "Events",
                      data: deshboardData.events?.map(ev => {return {x: new Date(ev.date),y:-85,description: ev.description,tags: ev.tags}}),
                      borderColor: "#db03fc",
                      backgroundColor: "#db03fc",
                      stack: 'combined',
                    },
                    {
                      type: "scatter",
                      label: "Action Challenges",
                      data: deshboardData.actionChallenges?.map(ac => {return {x: new Date(ac.createdAt),y:-95,steps: ac.steps,actionChallenge: ac.actionChallenge}}),
                      borderColor: "#4F46E5",
                      backgroundColor: "#4F46E5",
                      stack: 'combined',
                    }
                ]
             }
    });
    const [chart, setChart] = useState(null);
    useEventsChangeEffect({events,setstreamGraphChartData});
    useHistoricalDataChangeEffect({historiclData,setstreamGraphChartData});

    const arr = [];
    historicalOPQ.forEach((item,idx) => {
        const prevSum = historicalOPQ.filter((it,id) => id < idx).reduce((a,i) => a+i.OPQ,0);
        if(idx === 0){
          arr.push({compountedValue: item.OPQ, date: item.date})
        }
        else {
          arr.push({compountedValue: item.OPQ+prevSum,date: item.date});
        }
    });
    const myData = {
            labels: arr.map(item => new Date(item.date)),
            datasets: [
                {
                  label: "Personal Growth (Compounded) Graph",
                  data: arr.map(item => item.compountedValue),
                  backgroundColor: '#0a8001',
                  pointRadius: 0,
                  borderColor: "#0a8001",
                  clip: {left: 0, top: 0, right: 0, bottom: 0}
                }
            ]
    };

 
    

    
    return (
        <>
        <StreamGraph 
          streamGraphChartData={streamGraphChartData}
          setEventData={setEventData}
          setEventId={setEventId}
          setIsUpdateEvent={setIsUpdateEvent}
          setIsOpenAddEventsModal={setIsOpenAddEventsModal}
          events={events}
          setIsYourInsightsFullViewOn={setIsYourInsightsFullViewOn}
          setChart={setChart}
          chart={chart}
        />

        <PersonalGrowthGraph 
          myData={myData}
        />

        <Modals.EventModal
            isOpen={isOpenAddEventsModal}
            setIsOpen={setIsOpenAddEventsModal}
            userId={loggedInUser._id}
            isUpdate={isUpdateEvent}
            data={eventData}
            setEvents={setEvents}
            eventId={eventId}
            cancelBtnText="Cancel"
            isManagemaneEvent={false}
            surveyTitle={null}
         />
      </>
    )
}



export {StreamGraphContainer};



function useEventsChangeEffect({events,setstreamGraphChartData}){

  useEffect(() => {
    if(events){
      setstreamGraphChartData(prev => {
        const rem = prev.datasets.filter(dataSet => dataSet.label !== "Events");

        return {
          ...prev,
          datasets: [
            {
              type: "scatter",
              label: "Events",
              data: events.map(ev => {return {x: new Date(ev.date),y:-85,description: ev.description,tags: ev.tags}}),
              borderColor: "#db03fc",
              backgroundColor: "#db03fc",
              stack: 'combined',
            },
            ...rem
          ]
        }
      })
    }
  }, [events,setstreamGraphChartData]);

  return null;
}

function useHistoricalDataChangeEffect({historiclData,setstreamGraphChartData}){

  useEffect(() => {
    if(historiclData){
      setstreamGraphChartData(prev => {
        
        const rem = prev.datasets.filter(dataSet => [...XL_FACTORS,"XLScore"].indexOf(dataSet.label) === -1);
   
        return {
          ...prev,
          datasets: [
            ...rem,
             {
              type: 'line',
              label: "XLScore",
              data: historiclData.map(item => {return {x: new Date(item.year),y: item["XLScore"]}}),
              borderColor: FACTOR_COLORS["XLScore".toLocaleUpperCase()],
              backgroundColor: FACTOR_COLORS["XLScore".toLocaleUpperCase()],
              pointRadius: 0,
              fill: false,
              stack: 'combined',
            },
            ...XL_FACTORS.slice(0,6).map(factor => {
                return {
                  type: 'line',
                  label: factor,
                  data: historiclData.map(item => {return {x: new Date(item.year),y: item[factor]}}),
                  borderColor: FACTOR_COLORS[factor.toLocaleUpperCase()],
                  backgroundColor: FACTOR_COLORS[factor.toLocaleUpperCase()],
                  fill: true,
                  pointRadius: 0,
                }
            })
          ]
        }
      });
    }
  }, [historiclData,setstreamGraphChartData]);

  return null;
}


function StreamGraph({setChart,chart,streamGraphChartData,setEventData,setEventId,setIsUpdateEvent,setIsOpenAddEventsModal,events,setIsYourInsightsFullViewOn}){
  const toolTipText = `Discover a deeper understanding of your performance potential, factor cycles over time, and their intricate connection to your events and action challenges. Gain valuable wisdom to navigate your continuous improvement journey!`;
  const chartRef = useRef(null);

  function handeDoubleClick(){
    chart.resetZoom();
  }

  useEffect(() => {
    let chart = undefined;
    if(streamGraphChartData){
        
        const getOrCreateTooltip = (chart) => {
            let tooltipEl = chart.canvas.parentNode.querySelector('div');
          
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
              tooltipEl.style.borderRadius = '3px';
              tooltipEl.style.color = 'white';
              tooltipEl.style.opacity = 1;
              tooltipEl.style.pointerEvents = 'none';
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.transform = 'translate(-50%, 0)';
              tooltipEl.style.transition = 'all .1s ease';
          
              const table = document.createElement('table');
              table.style.margin = '0px';
          
              tooltipEl.appendChild(table);
              chart.canvas.parentNode.appendChild(tooltipEl);
            }
          
            return tooltipEl;
        };
          
        const externalTooltipHandler = (context) => {
            // Tooltip Element
            const {chart, tooltip} = context;
            const tooltipEl = getOrCreateTooltip(chart);
          
            // Hide if no tooltip
            if (tooltip.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }
          
            // Set Text
            if (tooltip.body) {
              const titleLines = tooltip.title || [];
              const bodyLines = tooltip.body.map(b => b.lines);
          
              const tableHead = document.createElement('thead');
          
              titleLines.forEach(title => {
                const tr = document.createElement('tr');
                tr.style.borderWidth = 0;
          
                const th = document.createElement('th');
                th.style.borderWidth = 0;
                const text = document.createTextNode(title);
          
                th.appendChild(text);
                tr.appendChild(th);
                tableHead.appendChild(tr);
              });
          
              const tableBody = document.createElement('tbody');
              bodyLines.forEach((body, i) => {
                const colors = tooltip.labelColors[i];
          
                const span = document.createElement('span');
                span.style.background = colors.backgroundColor;
                span.style.borderColor = colors.borderColor;
                span.style.borderWidth = '2px';
                span.style.marginRight = '10px';
                span.style.height = '10px';
                span.style.width = '10px';
                span.style.display = 'inline-block';
          
                const tr = document.createElement('tr');
                tr.style.backgroundColor = 'inherit';
                tr.style.borderWidth = 0;
          
                const td = document.createElement('td');
                td.style.borderWidth = 0;
          
                if(tooltip.dataPoints[i].dataset.label === "Events"){
                  const rawData = tooltip.dataPoints[i].raw;
                  const text = document.createTextNode(rawData.description);
                  td.appendChild(span);
                  td.appendChild(text);
                  rawData.tags.forEach(tag => {
                    const button = document.createElement("button");
                    button.innerText = tag;
                    button.style.backgroundColor = "blue";
                    button.style.marginLeft = "2px";
                    button.style.marginBottom = "2px";
                    button.style.padding = "0px 10px";
                    button.style.color = "#ffffff";
                    button.style.borderRadius = "5px"
                    td.appendChild(button)
                  });
                  tr.appendChild(td);
                  tableBody.appendChild(tr);
                }
                else if(tooltip.dataPoints[i].dataset.label === "Action Challenges"){
                  const rawData = tooltip.dataPoints[i].raw;
                  const text = document.createTextNode(rawData.actionChallenge);
                  td.appendChild(span);
                  td.appendChild(text);
                  rawData.steps.forEach(step => {
                    const button = document.createElement("button");
                    button.innerText = step.step;
                    button.style.backgroundColor = "blue";
                    button.style.marginLeft = "2px";
                    button.style.marginBottom = "2px";
                    button.style.padding = "0px 10px";
                    button.style.color = "#ffffff";
                    button.style.borderRadius = "5px"
                    td.appendChild(button)
                  });
                  tr.appendChild(td);
                  tableBody.appendChild(tr);
                }
                else {
                  const text = document.createTextNode(body);
          
                  td.appendChild(span);
                  td.appendChild(text);
                  tr.appendChild(td);
                  tableBody.appendChild(tr);
                }
              });
          
              const tableRoot = tooltipEl.querySelector('table');
          
              // Remove old children
              while (tableRoot.firstChild) {
                tableRoot.firstChild.remove();
              }
          
              // Add new children
              tableRoot.appendChild(tableHead);
              tableRoot.appendChild(tableBody);
            }
          
            const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
          
            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.background = '#ffffff';
            tooltipEl.style.color = '#000000';
            tooltipEl.style.border = '1px solid #000000';
            tooltipEl.style.left = positionX + tooltip.caretX + 'px';
            tooltipEl.style.top = positionY + tooltip.caretY + 'px';
            tooltipEl.style.font = tooltip.options.bodyFont.string;
            tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
        };


        chart = new ChartJS(chartRef.current, {
            type: 'bar',
            data: streamGraphChartData,
            options: {
                scales: {
                  x: {
                    type: 'time',
                    ticks: {
                    autoSkip: true,
                    autoSkipPadding: 50,
                    maxRotation: 0,
                    },
                  },
                  y: {
                    stacked: true,
                    ticks: {
                    callback: function(ctx,i){
                        return i*10;
                    }
                    },
                    min: -100,
                    max: 100
                  }
                },
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                    title: {
                      display: false,
                      text: (ctx) => 'Test Chart'
                    },
                    tooltip: {
                                        enabled: false,
                                        position: 'nearest',
                                        external: externalTooltipHandler
                    },
                    zoom: { 
                        limits: {
                            y: {min: -100,max: 100,minRange: 0}
                        },
                        pan: {
                          enabled: true,
                          modifierKey: 'ctrl'
                        },
                        zoom: {
                          drag: {
                              enabled: true,
                              backgroundColor: "#00000055"
                          },
                          pinch: {
                            enabled: true,
                          },
                          mode: 'x',
                        }
                    },
                    legend: {
                        labels: {
                        boxWidth: 10,
                        boxHeight: 10,
                        font: 10
                        },
                    }
                },
                onClick: function(ctx){
                    const {chart} = ctx;

                    const points = chart.getElementsAtEventForMode(ctx,'nearest',{intersect: true},true);
                    const data = points[0];
                    if(!data){
                        return;
                    }
                    // if user click on event circle 
                    if(data.datasetIndex === 0){
                        const {index} = data;
                        const event = events[index];
                        setEventData(event);
                        setEventId(event._id);
                        setIsUpdateEvent(true);
                        setIsOpenAddEventsModal(true);
                        return;
                    }
                },
                
            }
        });
        setChart(chart);
    }
    return () => {
        if(chart){
            chart.destroy();
        }
    }
  }, [streamGraphChartData,setEventData,setEventId,setIsUpdateEvent,setIsOpenAddEventsModal,events,setChart]);

  
  if(!streamGraphChartData){
    return null;
  }

  return <>
        <div id="introSelector13" className="mt-3 mb-3 text-11 md:text-[14px] flex flex-col w-full">
            <div className="pb-1 flex justify-between items-center border-b mb-2">
                <h2 className='text-[11px] md:text-[16px] rounded font-bold text-accent-color'>Your Insights <UI.InfoIconWithToolTip content={toolTipText}/></h2>
                <div className="flex gap-2 w-[200px]">
                  <PrimaryButton onClick={()=>setIsOpenAddEventsModal(true)}>Add Event</PrimaryButton>
                  <PrimaryButton id="introSelector14" onClick={()=>setIsYourInsightsFullViewOn(true)}>Expand</PrimaryButton>
                </div>
            </div>
            
            <div id='dashboard-step-10' className="self-center w-full h-[280px] md:h-[357px]">
                <canvas ref={chartRef} onDoubleClick={handeDoubleClick}></canvas>
            </div>
        </div>
  </>
}


function PersonalGrowthGraph({myData}){



  return <>
        <div className="mt-5 mb-3 text-11 md:text-[14px] flex flex-col w-full">
            <div className="pb-1 flex justify-between items-center border-b mb-2">
                <h2 className='text-[11px] md:text-[16px] rounded font-bold text-secondry-color'>Your Personal Growth <UI.InfoIconWithToolTip content={"This graph visualizes your psychological development as you adapt to events and challenges. It reflects the transformative journey of adapting, learning, and growing from each experience you encounter."}/></h2>
            </div>
            <Line 
               data={myData}
               options={{
               scales: {
                  x: {
                    type: 'timeseries',
                    ticks: {
                        callback: function(value, index, ticks){
                            // if(index === 0){
                            //     return "Today"
                            // }
                            return this.getLabelForValue(value).split(",")[0]
                        },
                        color: "black"
                    },
                    grid: {
                      display: false
                    },
                    border: {
                      color: "black"
                    },
                  },
                  y: {
                    // max: 100,
                    // min: 0,
                    ticks: {
                        callback: function(value,index,ticks){
                            return `${value} %`;
                        },
                        color: "black"
                    },
                    title: {
                                                display: false,
                                                text: "Personal Growth (Compounded)",
                                                font: {
                                                    family: 'sans-serif',
                                                    size: 18,
                                                    style: 'normal',
                                                    lineHeight: 1.2
                                                },
                    },
                    grid: {
                      display: false
                    },
                    border: {
                      color: "black"
                    }
                  }
               },
               plugins: {
                  legend: {
                      position: "top",
                      align: "center",
                      display: false,
                      labels: {
                          font: {
                              size: 11
                          },
                          boxWidth: 11
                      }
                  },
                  labels: {
                      color: "black",
                      font: {
                        family: 'sans-serif',
                        size: 10,
                        weight: "normal"
                      },
                  }
               },
              }}
            />
        </div>
  </>
}









