import React, { useEffect,useRef,useState } from "react";
import {
  getUserDashboardData,
  getUserById,
  updateLoggedInUser,
  // getUniversalSurveyTitle,
  getAllSurveyTitles,
  getUniversalSurveyTitle
 } from "../../Store/actions";
import { 
  useLocation, 
  useNavigate, 
  useParams 
} from "react-router-dom";
import {
  Factors,
  Loading,
  Modals,
  UI,
  // UI
} from "../../components";
import {
  mapper__100_100_to_0_100
} from '../../utils';
import {
  ScoreInBrainWithLabels,
  // useMixPanelEvents,
  ActionChallenges,
  StreamGraphContainer,
  FormSkeleton,
  YourInsightsFullView,
  XLFactorsTooltipText
} from "./components";
import moment from "moment";
import { TempMain } from "../../layout";
import { useDispatch, useSelector } from "react-redux";
// import { Steps } from 'intro.js-react';
import Joyride from 'react-joyride';
import { CiCirclePlus } from "react-icons/ci";
import { toast } from "react-toastify";



function Index() {
  const { userId } = useParams();
  const [user,setUser] = useState(null);
  const location = useLocation();
  const [showAddEvent, setShowAddEvent] = useState(true);
  const [currentActivity,setCurrentActivity] = useState(location.state?.surveyName || "");


  useEffect(() => {
    (async ()=> {
      const res = await getUserById(userId);
      if(res.success){
        setUser(res.user);
      }
    })()
  }, [userId,setUser]);

  if(!user && !location.state?.loading){
    return <div><Loading/></div>
  }

  if(!user && location.state?.loading){
    return <FormSkeleton/>
  }
  
  if(showAddEvent && location.state?.loading){
    return <Modals.EventModal
            isOpen={showAddEvent}
            setIsOpen={setShowAddEvent}
            userId={userId}
            cancelBtnText="Skip"
            isManagemaneEvent={false}
          />
  }

  if(user && user.canAccessDashboard === false){
    return <div className="h-[93vh] flex justify-center items-center">
      <p>Thank you for tracking you state. Your data will be available soon!</p>
    </div>
  }

  return <NewDashboard
          user={user} 
          setShowAddEvent={setShowAddEvent}
          currentActivity={currentActivity}
          setCurrentActivity={setCurrentActivity}
          />
}

export default Index;

function NewDashboard({user,setShowAddEvent,currentActivity,setCurrentActivity}){
  const location = useLocation();
  const navigator = useNavigate();
  const XLScoreContainer = useRef(null);
  const [deshboardData,setDeshboardData] = useState(null);
  const [isLoading,setIsLoading] = useState(true);
  const [isAnimation] = useState(()=> location.state?.loading? true:false);
  const [secVis,setSecVis] = useState({
    actionChallenges: false,
    streamGraph: false
  });
  const [isYourInsightsFullViewOn,setIsYourInsightsFullViewOn] = useState(false);
  const [isStreakModalOpen,setStreakModalOpen] = useState(false);
  const [currentTourStep,setCurrentTourStep] = useState(null);
  const currentActivityName = user.activities.find(ac => ac.category === (currentActivity || deshboardData?.currentActivity))?.name;
  const dispatch = useDispatch();
  const loggedInUser = useSelector(state => state.loggedInUser);
  const [isFatchingUniversalSurveyTitle] = useState(false);
  const [isSurveyLoading, setIsSurveyLoading] = useState(false);
  
  const handleFetchUnRegularSurvey = async () => {
    setIsSurveyLoading(true);
    const resUser = await getUserById(user._id);
    const res = await getAllSurveyTitles();
    const universalRes =  await getUniversalSurveyTitle();
    if(resUser.success && res.success){
      const selectedSurvey = res.surveyTitles.find(st => resUser.user?.unRegularSurveys.indexOf(st._id) !== -1);
      setIsSurveyLoading(false);
      const cat = loggedInUser.activities.find(item => item.name === currentActivityName);
      if(selectedSurvey){
        navigator(`/survey/${loggedInUser._id}/${selectedSurvey._id}/${new Date().getTime()}?new=true&isNonCogniPulse=true&surveyName=${cat.category}&title=${cat.name}`);
      }
      else {
        navigator(`/survey/${loggedInUser._id}/${universalRes.surveytitle._id}/${new Date().getTime()}?surveyName=${cat.category}&title=${cat.name}&new=true`);
      }
    }
    else {
      setIsSurveyLoading(false);
      toast.error("Something went wrong!");
    }
  }


  useEffect(() => { 
    (async () => {
      const query = `user=${user._id}&surveyName=${currentActivity}&isEventsAndActionChallenges=true`;

      const res = await getUserDashboardData(query);
      console.log(res);

      if(res.success){
        setDeshboardData({...res});
        dispatch(updateLoggedInUser({hasSeenDashboardFirstTime: true}));
      }
      setIsLoading(false);
    })();
  }, [user?._id,currentActivity,dispatch]);

  // console.log(deshboardData);

  useEffect(() => {
    let time = undefined;
    if(!isLoading && deshboardData && secVis.streamGraph){
      setTimeout(() => {
        setStreakModalOpen(true);
      }, 1000);
    }
    return () => {
      clearTimeout(time);
    }
  }, [isLoading,deshboardData,secVis]);

  if(isLoading || !deshboardData){
    return <div><Loading/></div>
  }

  const {
    XLScore,
    scoreOfAllfactors,
    percentageOfChangeOfFactors,
    historiclData,
    XLChange,
    higestDiffrance,
    factorsMinMax,
    // whatImapctDoesItMakeData,
    // currentActionChallanges,
    currentActionChallengesWithSteps,
  } = deshboardData;
  
  

  if(isYourInsightsFullViewOn){
    return <YourInsightsFullView deshboardData={deshboardData} user={user} currentActivity={currentActivity || deshboardData?.currentActivity} XLChange={XLChange} XLScore={XLScore} setIsYourInsightsOn={setIsYourInsightsFullViewOn} isYourInsightsOn={isYourInsightsFullViewOn} historiclData={historiclData} setShowAddEvent={setShowAddEvent} factorsMinMax={factorsMinMax} percentageOfChangeOfFactors={percentageOfChangeOfFactors} scoreOfAllfactors={scoreOfAllfactors}/>
  }
  
  return (
    <>
        <TempMain>
            {
              user.activities.length > 1 && <Tabs user={user} currentActivity={currentActivity} setCurrentActivity={setCurrentActivity}/>
            }
            
            <div className="w-full  pb-10" style={{paddingTop: user.activities.length > 1?"0":"20px"}}>
                <h2 className="text-[16px] font-bold md:text-[20px] text-center flex flex-col justify-center items-center gap-2 text-secondry-color mb-2"><span>Performance Potential in your {currentActivityName} {currentActivity}</span> <span>{moment(historiclData[historiclData.length-1]?.year).format("DD-MM-YYYY")}</span></h2>
                
                <div className="flex justify-center">
                  {
                    isFatchingUniversalSurveyTitle && <p className="text-gray-400">Loading up your CogniPulse...</p>
                  }
                  {
                    !isFatchingUniversalSurveyTitle && <CiCirclePlus onClick={handleFetchUnRegularSurvey} title="Take Your CogniPulse Now" size={40} className="font-bold cursor-pointer text-secondry-color"/>
                  }
                </div>

                <div ref={XLScoreContainer} className="w-full max-w-[715px] mx-auto">
                  {
                    !isYourInsightsFullViewOn && <ScoreInBrainWithLabels currentTourStep={currentTourStep} isBrainAnimate={isAnimation} setSecVis={setSecVis} containerRef={XLScoreContainer} XLScore={mapper__100_100_to_0_100(XLScore)} XLChange={XLChange}/>
                  }
                </div>

                <div className="flex flex-col justify-center items-center mb-6">
                    {
                      isSurveyLoading? <p>Loading...</p>:<>
                        {
                          !isSurveyLoading && <CiCirclePlus onClick={handleFetchUnRegularSurvey} title="Im.Pulse: Capture your Impact." size={40} className="font-bold cursor-pointer text-secondry-color"/>
                        } 
                      </>
                    }
                </div>

                <div id="introSelector9" className="w-full max-w-[315px] mx-auto mb-6 relative z-10">
                    <h4 className="title flex justify-center gap-2 items-center font-bold mb-2 text-secondry-color" ><span>XL factors</span><UI.InfoIconWithToolTip content={XLFactorsTooltipText}/></h4>
                    <Factors 
                        Fun={scoreOfAllfactors?.Fun}
                        Purpose={scoreOfAllfactors?.Purpose}
                        Growth={scoreOfAllfactors?.Growth}
                        Emotives={Math.abs(scoreOfAllfactors?.Emotives)}
                        Utility={Math.abs(scoreOfAllfactors?.Utility)}
                        Apathy={Math.abs(scoreOfAllfactors?.Apathy)}
                        factorsMinMax={factorsMinMax}
                        percentageOfChangeOfFactors={percentageOfChangeOfFactors}
                        border={true}
                        mixPanelEvents={""}
                        conrainerId="introSelector10"
                        driversId="introSelector11"
                        constrainersId="introSelector12"
                    />
                </div>

                <div className="w-full max-w-[715px] mx-auto mb-6">
                  {
                    secVis.actionChallenges && <ActionChallenges 
                      higestDiffrance={higestDiffrance} 
                      currentActionChallengesWithSteps={currentActionChallengesWithSteps} 
                      user={user}
                      setSecVis={setSecVis}
                      isACAnimation={isAnimation}
                    />
                  }
                </div>

                <div className="w-full max-w-[715px] mt-5 mx-auto relative">
                  {
                    (secVis.streamGraph && !isYourInsightsFullViewOn) && <StreamGraphContainer historiclData={historiclData} deshboardData={deshboardData} setIsYourInsightsFullViewOn={setIsYourInsightsFullViewOn}/>
                  }
                  {
                    historiclData?.length === 0  && <div className="absolute top-0 left-0 bg-black/50 w-full h-full">

                    </div>
                  }
                </div>
            </div>

            <Modals.StreakModal userId={user._id} isOpen={isStreakModalOpen} setIsOpen={setStreakModalOpen} handleOk={()=>setStreakModalOpen(false)}/>
            
            {
              (!loggedInUser.isDashboardTutorialFinished || location.state?.payload?.isDashboardTutorialFinished === false) && <IntroJSTour currentTourStep={currentTourStep} currentActivity={currentActivity} setCurrentTourStep={setCurrentTourStep}/>
            }
        </TempMain>
    </>
  )
}

function Tabs({user,currentActivity,setCurrentActivity}){
  // const {userActivitiesTabs} = useMixPanelEvents(user._id);
  const Icon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 01-.657.643 48.39 48.39 0 01-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 01-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 00-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 01-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 00.657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 01-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 005.427-.63 48.05 48.05 0 00.582-4.717.532.532 0 00-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 00.658-.663 48.422 48.422 0 00-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 01-.61-.58v0z" />
                </svg>;

  const tabItems = user.activities.map(act => {return {icon: Icon,placeHolder: `${act.name} ${act.category}`,...act}});

  const [selectedItem,setSelectedItem] = useState(()=>{
    let index = 0;
    tabItems.forEach((item,i) => {
      if(item.category === currentActivity){
        index = i;
      }
    });
    return index;
  });

  const handleSelectActivity = (item,idx) => {
    const category = item.category;
    setCurrentActivity(category);
    setSelectedItem(idx);
  }




  return (
      <div className="pb-4 md:pb-8 flex justify-center">
          <ul role="tablist" className="max-w-screen-xl mx-auto border-b flex items-center gap-x-6 overflow-x-auto text-sm">
              {
                  tabItems.map((item, idx) => (
                      <li key={idx} className={`py-2 border-b-2 ${selectedItem === idx ? "border-accent-color text-accent-color" : "border-secondry text-secondry-color"}`}>
                          <button
                              role="tab"
                              aria-selected={selectedItem === idx ? true : false}
                              aria-controls={`tabpanel-${idx + 1}`}
                              className="flex items-center gap-x-2 py-2 px-2 rounded-lg duration-150 hover:text-accent-color hover:bg-accent-color/20 active:bg-gray-100 font-medium"
                              onClick={(e) => {
                                handleSelectActivity(item,idx);
                              }}
                              // onMouseEnter={(e)=>userActivitiesTabs(e,idx)}
                          >
                              {/* {item.icon} */}
                              <span>My {item.placeHolder}</span>
                          </button>
                      </li>
                  ))
              }
          </ul>
      </div>
  )
}

function IntroJSTour({setCurrentTourStep,currentActivity}){
  const dispatch = useDispatch();
  // const loggedInUser = useSelector(state => state.loggedInUser);
  // const title = loggedInUser.activities.find(act => act.category === currentActivity)?.name;
  const title = currentActivity;


  const mySteps = [
    {
      element: '#introSelector1',
      intro: `The XL score indicates your current potential for performance & wellbeing in the activity you are tracking.`,
    },
    {
      element: '#introSelector2',
      intro: `It spans from 0 to 100`,
    },
    {
      element: '#introSelector3',
      intro: `If you are in the 0-50 range, you are likely to rely on established routines and methods to tackle challenges (i.e. tactical performance).`,
    },
    {
      element: '#introSelector4',
      intro: `If you are 50-100 means you are becoming more versatile and open to innovative problem-solving approaches (meaning you have access to adaptive performance as well as tactical).`,
    },
    {
      element: '#introSelector5',
      intro: `Remember that everyone's personal potential for performance varies, and there is no inherently 'better' point on this scale.`,
    },
    {
      element: '#introSelector5',
      intro: `XLRate is not about your 'actual output' or 'performance' or 'external measurements!' It is designed to aid your dynamic and personal development journey. `,
    },
    {
      element: '#introSelector5',
      intro: `What matters most is understanding where you currently stand and how you can leverage your strengths to adapt and excel in various situations.`,
    },
    {
      element: '#introSelector5',
      intro: `For example, Productive Flow is not a 'one-time' destination but rather an ongoing process as you continually improve and hone your skills, striving to be the best you can be.`,
    },
    {
      element: '#introSelector9',
      intro: `These are the key factors shaping your XL Score, a dynamic blend of drivers and constrainers that influence your well-being and performance.`,
    },
    {
      element: '#introSelectorFactorsContainer',
      intro: `The XL factors are divided into drivers and constrainers.`,
    },
    {
      element: '#introSelectorDrivers',
      intro: `Drivers are your intrinsic motivators. They drive the adaptive performance potential. They are fun, purpose, and personal growth potential.`,
    },
    {
      element: '#introSelectorFunDrivers',
      intro: `Fun indicates how much your ${title} activities themselves drive you. Enjoyment occurs when you engage in a task simply because the action is, in itself, fun to do.`,
    },
    {
      element: '#introSelectorPurposeDrivers',
      intro: `Purpose indicates how much the direct outcome of your ${title} drives you; even if you don't enjoy the tasks you do, you value their overarching impact.`,
    },
    {
      element: '#introSelectorGrowthDrivers',
      intro: `Growth (i.e.Self-development) indicates how much you do your ${title} activities because they will indirectly lead to something you believe is important. For example, even if you don't find the tasks enjoyable or fulfilling, you feel they are helping you improve your skills and will lead to something better.`,
    },
    {
      element: '#introSelectorConstrainers',
      intro: `Constrainers limit your ability to get into adaptive performance states. They are extrinsic motivators (e.g. emotives, extrinsic incentives) and apathy, which, if high, tend to limit both adaptive and tactical performance.`,
    },
    {
      element: '#introSelectorEmotivesConstrainers',
      intro: `Emotives indicate how much you perform your ${title} activities because of emotional pressures like guilt, or peer pressure. It suggests you're no longer engaged in your ${title} because you enjoy or value the tasks themselves.`,
    },
    {
      element: '#introSelectorUtilityConstrainers',
      intro: `Utility indicates how much external incentives like gaining a reward or avoiding a punishment drive you in your ${title} activities. The higher this is, the less likely you are to be engaged by the enjoyment or value of the activity itself.`,
    },
    {
      element: '#introSelectorApathyConstrainers',
      intro: `Apathy indicates how much you do your ${title} simply out of habit (i.e. You do the job today because you did it yesterday). It suggests a disconnect between your daily ${title} activities and the enjoyment, fulfilment and self-development you need to access your full cognitive potential.`,
    },
    {
      element: '#introSelector10',
      intro: 'XLrate calculates the factor that if you change it will have the most impact on your XL score and your state (i.e. improve your cognitive state the fastest).'
    },
    {
      element: '#introSelector11',
      intro: 'It suggests you choose 1 of 3 possible ‘Action challenges’ that you can do to improve the fastest. Click on each to view and accept the challenge that resonates most with you.'
    },
    {
      element: '#introSelector12',
      intro: 'You access the Action Challenges you have accepted or completed here too'
    },
    {
      element: "#introSelector13",
      intro: "The Insights graph will give you a deeper understanding of your journey over time."
    },
    {
      element: "#introSelector14",
      intro: "Click to expand and have more interaction and details (i.e. select specific time periods to see avg. XL or most frequent XL factor to improve and click day by day)"
    },

  ];

  const steps = mySteps.map(step => {return {target: step.element,content: step.intro}})

  const cb = (state) => {
    if(state.action === "reset"){
      dispatch(updateLoggedInUser({isDashboardTutorialFinished: true}));
    }
    setCurrentTourStep(state.index);
  }

  // const Comp = () => {
  //   return <div className="">Start</div>
  // }

  return (
    <Joyride
        callback={cb} 
        steps={steps}
        continuous={true}
        scrollOffset={100}
        spotlightPadding={5}
        // beaconComponent={Comp}
    />
  )
}




