import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { ProgressBarAndBackButton, Wrapper } from "./index";
import { PrimaryButton} from "../../../components";
import { 
    onBoardUser,
    // onBoardUser, 
    updateLoggedInUser,
} from "../../../Store/actions";
// import { toast } from "react-toastify";
import {TempMain} from "../../../layout";
// import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";


function EnterActivityTitle({setScreen}){

    const loggedInUser = useSelector(state => state.loggedInUser);
    const [activities, setActivities] = useState(loggedInUser.activities);
    const [isSaving, setIsSaving] = useState(false);
    const navigator = useNavigate();
    const dispatch = useDispatch();
    const placeHolder = "Enter activity name";

    const handleBack = () => {
        setScreen(prev => {
            return {
                ...prev,
                enterActivityTitle: false,
                finalProjection: true
            }
        })
    }
    
    const handleChange = (e) => {
        const category = e.target.name;
        setActivities(prev => {
            return prev.map(act => {
                if(act.category === category){
                    return {
                        ...act,
                        name: e.target.value
                    }
                }
                return act;
            })
        });
    }

    const giveInfo = (act) => {
            switch (act.category) {
                case "work":
                    return <Field preText={"My"} postText={"Work"} value={act.name} name={act.category} onChange={handleChange} placeHolder={placeHolder} />

                case "relationship":
                    return <Field preText={"My relationship with"} value={act.name} name={act.category} onChange={handleChange} placeHolder={placeHolder} postText={""}/>
   
                case "hobby":
                    return <Field preText={"My"} value={act.name} name={act.category} onChange={handleChange} placeHolder={placeHolder} postText={"Hobby"}/>

                case "study":
                    return <Field preText={"My"} value={act.name} name={act.category} onChange={handleChange} placeHolder={placeHolder} postText={"Study"}/>

                case "sport":
                    return <Field preText={"My"} value={act.name} name={act.category} onChange={handleChange} placeHolder={placeHolder} postText={"Sport"}/>

                case "business":
                    return <Field preText={"My"} value={act.name} name={act.category} onChange={handleChange} placeHolder={placeHolder} postText={"Business"}/>

                default:
                    break;
            }
    }

    const handleNext = async () => {
        setIsSaving(true);
        
        const onBoardRes = await onBoardUser(loggedInUser._id);
        
        if(onBoardRes.success){
            dispatch(updateLoggedInUser({isEnterActivityTitleSeen: true,activities}));
            navigator(onBoardRes.link);
        }
        else {
            // toast.error(onBoardRes.message);
            navigator(`/score/user/${loggedInUser._id}`);
        }

        setIsSaving(false);
    }

    return (<>
         <TempMain>
            <Wrapper>
                <ProgressBarAndBackButton handleBack={handleBack} completedSteps={5}/>
                <p className='pb-10 text-xl text-secondry-color font-bold'>Enter the specific titles or names of the things you want track and improve.</p>
            
                {/* activities */}
                <div className='flex flex-col gap-10  mb-10'>
                    {
                        activities.map(act => {
                            return giveInfo(act) 
                        })
                    }
                </div>

                {/* next btn  */}
                <div className="">
                    <PrimaryButton onClick={handleNext}>
                        {isSaving? "Saving..":"Next"}
                    </PrimaryButton>
                </div>
            </Wrapper>
         </TempMain>
    </>)
}


export {EnterActivityTitle};





function Field({preText,postText,value,name,onChange,placeHolder}){

    return (<>
        <div className="text-secondry-color flex justify-start items-center gap-3">
            <span className="font-bold">{preText}</span> 
            <input className="flex-1 bg-secondry-color/20 rounded-md px-4 py-2" type="text" value={value} name={name} onChange={onChange} placeholder={placeHolder}/> 
            <span className="font-bold">{postText}</span>
        </div>
    </>)
}