import {UI} from "../index";

function List({title = "Default",items = ["Default 1","Default 2","Default 3"]}){

    return (
        <>
        <p className='font-bold'>{title}</p>
                    <div className="flex flex-col">
                        {
                            items.map((item,index) => {
                                return <p className='shadow-sm border rounded-md py-2 px-4 flex' key={item}>
                                            <UI.ListIcon/>
                                            <span>{item}</span>
                                       </p>
                            })
                        }
                    </div>
        </>
    )
}


export default List;