import React from 'react'

function Main({children,isChildrenCenter,containerClasses}) {
  const center = isChildrenCenter? "flex justify-center items-start md:items-center":"";

  return (
    <section className='min-h-[calc(100vh-64px)] mt-[64px]'>
      <div className={`mx-auto py-6 container min-h-[calc(100vh-64px)] bg-white ${center} ${containerClasses || ""}`}>
          {children}
      </div>
    </section>
  )
}

export default Main