import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Modals, Factors, UI, PrimaryButton, CrossSVG } from "../../../components";
import {
    // allSurveysWithXLScoresToHistoricalSPQ, 
    mapper__100_100_to_0_100,
    // OPQCalculator
} from "../../../utils";
import {ScoreInBrain, XLFactorsTooltipText} from "./index";
// import {Main} from "../../../layout";
import { Chart as ChartJS} from "chart.js/auto";
import zoomPlugin from 'chartjs-plugin-zoom';
import { useSelector } from "react-redux";
import { FACTOR_COLORS, XL_FACTORS } from "../../../constants";
import {TempMain} from "../../../layout";
import annotationPlugin from 'chartjs-plugin-annotation';


ChartJS.register(zoomPlugin);
ChartJS.register(annotationPlugin);



function YourInsightsFullView({deshboardData,XLChange,user,currentActivity,factorsMinMax,percentageOfChangeOfFactors,scoreOfAllfactors,isYourInsightsOn,setIsYourInsightsOn,historiclData,setShowAddEvent}) {
    const streamGraphDiv = useRef(null);
    const XLScoreContainer = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(historiclData.length-1);
    const [brushSelection, setBrushSelection] = useState(null);
    const CURRENT_FACTORS = historiclData[currentIndex];
    const NEXT_DISABLED = currentIndex === null || currentIndex === historiclData.length-1;
    const PREV_DISABLED = currentIndex === 0;
    const IS_TODAY = moment(CURRENT_FACTORS.year).format("yy-mm-dd") === moment().format("yy-mm-dd");
    const SELECTED_RANGES = brushSelection? historiclData.map((item,index) => {return {...item,index}}).filter(item => moment(item.year).isBetween(moment(brushSelection.range[0]),moment(brushSelection.range[1]))):historiclData;
    const SUM_OF_XL_SCORES = SELECTED_RANGES.reduce((a,b)=> a + b.XLScore,0)/SELECTED_RANGES.length;
    const ACTIVITY = user.activities.find(act => {return act.category === currentActivity? act:null});
    const MOST_FREQUENT_FACTORS = useFrequencyOfFactorsCalculator(SELECTED_RANGES);
    // const OPQ = OPQCalculator(SELECTED_RANGES,"XLScore");
    // const todaysSurveys = historiclData.filter(item => moment(CURRENT_FACTORS.year).format("DD-MM-YYYY") === moment(item.year).format("DD-MM-YYYY"));
    // const currentOPQ = OPQCalculator(todaysSurveys,"XLScore");
    // const allSurveysWithXLScores = historiclData.map(item => {return {createdAt: item.year,XLScore: item.XLScore}});
    // const historicalOPQ = allSurveysWithXLScoresToHistoricalSPQ(allSurveysWithXLScores);
    // const toltipText = `(Caution: this is a Beta feature!) A indicator of performance states that strike the balance between innovation and deliverable solution. Higher scores suggest that performance is more dynamic, possibly reflecting varying levels of innovation, creativity, or adaptability in approaching the problem. Early data indicates a strong correlation between high scores and performance output (i.e. productive value) Note:  and higher scores may also imply inconsistency in performance, which could either be a sign of creative experimentation or lack of a steady approach). Lower scores indicate a more tactical, steady approach to performance with less variability in creativity or innovation. Initial data indicates lower scores have a strong correlation with performance outcomes witt less productive value.`;
    const loggedInUser = useSelector(state => state.loggedInUser);
    const [isOpenAddEventsModal, setIsOpenAddEventsModal] = useState(false);
    const [isUpdateEvent, setIsUpdateEvent] = useState(false);
    const [eventData, setEventData] = useState(false);
    const [eventId, setEventId] = useState(null);
    const [events, setEvents] = useState(deshboardData.events || []);
    const selectedEvents = events.map((item,index) => {return {...item,index}}).filter(item => moment(CURRENT_FACTORS.year).startOf("day").isSame(moment(item.date).startOf("day")));
    const [streamGraphChartData, setstreamGraphChartData] = useState(()=> {
        return {
                  labels: historiclData.map(item => new Date(item.year)),
                  datasets: [
                      {
                        type: "scatter",
                        label: "Events",
                        data: deshboardData.events?.map(ev => {return {x: new Date(ev.date),y:-85,description: ev.description,tags: ev.tags}}),
                        borderColor: "#db03fc",
                        backgroundColor: "#db03fc",
                        stack: 'combined',
                      },
                      {
                        type: "scatter",
                        label: "Action Challenges",
                        data: deshboardData.actionChallenges?.map(ac => {return {x: new Date(ac.createdAt),y:-95,steps: ac.steps,actionChallenge: ac.actionChallenge}}),
                        borderColor: "#4F46E5",
                        backgroundColor: "#4F46E5",
                        stack: 'combined',
                      }
                  ]
               }
    });
    const [chart, setChart] = useState(null);
    const [isEventsChecked,setIsEventsChecked] = useState(false);
    useEventsChangeEffect({events,setstreamGraphChartData});
    useHistoricalDataChangeEffect({historiclData,setstreamGraphChartData});


    const handlePrev = () => {
        setCurrentIndex(prev => {
            if(prev === 0){
                return 0;
            }
            return prev-1;
        });

        // if(historiclData[currentIndex-2].year && chart.getZoomLevel() !== 1){
            // const startDate = new Date(historiclData[currentIndex-2].year).getTime();
            // const diff = moment(historiclData[currentIndex-1].year).diff(moment(historiclData[currentIndex-2].year),"days");
            // const endDate =  new Date(chart.scales.x.max).setDate(new Date(chart.scales.x.max).getDate()-diff);
            // chart.zoomScale('x', {min: startDate, max: endDate}, 'none');
            // chart.pan({x: 10}, undefined, 'default');
        // }

        const newDate = new Date(historiclData[currentIndex-1].year);
        chart.options.plugins.annotation.annotations.line1.xMin = newDate;
        chart.options.plugins.annotation.annotations.line1.xMax = newDate;
        chart.update();

        const selection = {
            range: [new Date(chart.scales.x.min),new Date(chart.scales.x.max)]
        };
        setBrushSelection(selection);
    }
  
    const handleNext = () => {
        setCurrentIndex(prev => {
            if(prev === historiclData.length-1){
                return historiclData.length-1;
            }
            return prev+1;
        })

        // if(historiclData[currentIndex+2].year && chart.getZoomLevel() !== 1){
        //     const startDate = new Date(chart.scales.x.min).setDate(new Date(chart.scales.x.min).getDate()+1);
        //     const endDate = new Date(historiclData[currentIndex+2].year).getTime();
        //     chart.zoomScale('x', {min: startDate, max: endDate}, 'none');
        // }
        
        const newDate = new Date(historiclData[currentIndex+1].year);
        chart.options.plugins.annotation.annotations.line1.xMin = newDate;
        chart.options.plugins.annotation.annotations.line1.xMax = newDate;
        chart.update();

        const selection = {
            range: [new Date(chart.scales.x.min),new Date(chart.scales.x.max)]
        };
        setBrushSelection(selection);
    }
  
    const handleKeyDown = (e) => {
        if(e.key === "ArrowLeft" && !PREV_DISABLED){
            handlePrev();
        }
        if(e.key === "ArrowRight" && !NEXT_DISABLED){
            handleNext();
        }
    }

  
    return (
      <TempMain>
        <div tabIndex={0} onKeyDown={handleKeyDown} className="flex flex-col items-center">
                <div className="flex justify-between w-full p-2 mb-1">
                    <div className="flex items-center justify-between gap-2 w-[80%]">
                        <span className="text-secondry-color font-bold">My {ACTIVITY?.name} {ACTIVITY?.category}</span>
                        <PrimaryButton style={{width: "120px"}} onClick={()=>setIsOpenAddEventsModal(true)} >Add Event</PrimaryButton>
                    </div>
                    <div>
                        <CrossSVG onClick={()=>setIsYourInsightsOn(false)}/>
                    </div>
                </div>
                <div className="w-full flex max-[1000px]:flex-col min-[1000px]:flex-row">
                    <div className="p-2 max-[450px]:w-full min-[1000px]:w-[80%]" >
                        <div className="w-full h-[280px] md:h-[400px]" ref={streamGraphDiv}>
                            <MyChart
                            streamGraphChartData={streamGraphChartData}
                            events={events}
                            setEventData={setEventData}
                            setEventId={setEventId}
                            setIsUpdateEvent={setIsUpdateEvent}
                            setIsOpenAddEventsModal={setIsOpenAddEventsModal}
                            chart={chart}
                            setChart={setChart}
                            setBrushSelection={setBrushSelection}
                            setCurrentIndex={setCurrentIndex}
                            />
                        </div>
                        <div className="flex gap-3 mt-5">
                            <PrimaryButton disabled={PREV_DISABLED} onClick={handlePrev}>Prev</PrimaryButton>
                            <PrimaryButton disabled={NEXT_DISABLED} onClick={handleNext}>Next</PrimaryButton>
                        </div>
                        <div className="mt-4">
                            <div className="p-2 rounded-md shadow-sm bg-secondry-color/20 min-w-[100px] min-h-[100px] text-secondry-color">
                                                <div className="">
                                                    <span className='font-bold'>Selected Range:</span>
                                                    <span className='ml-[60px]'>{moment(SELECTED_RANGES[0]?.year).format("DD/MM/YYYY")}</span>
                                                    <span className='mx-1'>-</span>
                                                    <span>{moment(SELECTED_RANGES[SELECTED_RANGES.length-1]?.year).format("DD/MM/YYYY")}</span>
                                                </div>
                                                <div className="">
                                                    <span className='font-bold'>Average XLScore:</span>
                                                    <span className='ml-12'>{parseFloat(mapper__100_100_to_0_100(SUM_OF_XL_SCORES)).toFixed(2)}</span>
                                                </div>
                                                <div className="">
                                                    <span className='font-bold'>Highest freq Factor/s:</span>
                                                    <span className='ml-5'>{MOST_FREQUENT_FACTORS}</span>
                                                </div>
                                                <div className="pt-2">
                                                    <ToggleButton isChecked={isEventsChecked} setIsChecked={setIsEventsChecked}/>
                                                    <div className="flex flex-col gap-2">
                                                        {
                                                            isEventsChecked && selectedEvents.map((event,idx) => (
                                                                <div key={idx} className="border p-2 bg-white">
                                                                    <p>{event.description || "Not Set"}</p>
                                                                    {
                                                                    event.tags.length === 0 && "Not Set"  
                                                                    }
                                                                    {
                                                                        event.tags.map((tag,idx1) => (
                                                                            <UI.Button key={idx1} className="bg-blue-600 text-white px-2 ml-2">{tag}</UI.Button>
                                                                        ))
                                                                    }
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-2  max-[450px]:w-full min-[1000px]:w-[20%] flex flex-wrap items-center justify-center">
                        <div ref={XLScoreContainer} style={{transform: "scale(1)"}} className="w-full flex justify-center items-center">
                            <ScoreInBrain containerRef={XLScoreContainer} XLScore={mapper__100_100_to_0_100(CURRENT_FACTORS.XLScore)} XLChange={XLChange}/>  
                        </div>
                        {/* <div className="border px-4 flex items-center bg-gray-400 text-white"><h4 className="mb-0">OPQ: {parseFloat(currentOPQ).toFixed(2)}</h4></div> */}
                        <div className="flex max-w-[300px] flex-col w-full p-2">
                            <p className="title flex justify-center gap-2 items-center text-secondry-color" ><span>{IS_TODAY? "Current":moment(CURRENT_FACTORS.year).format("DD/MM/YYYY")} XL factors</span><UI.InfoIconWithToolTip content={XLFactorsTooltipText}/></p>
                            <Factors 
                                Fun={CURRENT_FACTORS?._per_Fun}
                                Purpose={CURRENT_FACTORS?._per_Purpose}
                                Growth={CURRENT_FACTORS?._per_Growth}
                                Emotives={Math.abs(CURRENT_FACTORS?._per_Emotives)}
                                Utility={Math.abs(CURRENT_FACTORS?._per_Utility)}
                                Apathy={Math.abs(CURRENT_FACTORS?._per_Apathy)}
                                factorsMinMax={factorsMinMax}
                                percentageOfChangeOfFactors={percentageOfChangeOfFactors}
                                border={true}
                                mixPanelEvents={""}
                            />
                        </div>
                    </div>
                    <Modals.EventModal
                        isOpen={isOpenAddEventsModal}
                        setIsOpen={setIsOpenAddEventsModal}
                        userId={loggedInUser._id}
                        isUpdate={isUpdateEvent}
                        data={eventData}
                        setEvents={setEvents}
                        eventId={eventId}
                        cancelBtnText="Cancel"
                        isManagemaneEvent={false}
                        surveyTitle={null}
                    />
                </div>
        </div>
      </TempMain>
    )
}
  

export { YourInsightsFullView }
  
  
function useFrequencyOfFactorsCalculator(selectedRanges){
    const FREQUENCY_OF_FACTORS = {}
    const [incDec] = useIsIncreaseOrDicrease();
  
    selectedRanges.forEach(item => {
        if(FREQUENCY_OF_FACTORS[item.highestDiffrence.factor]){
            FREQUENCY_OF_FACTORS[item.highestDiffrence.factor] = FREQUENCY_OF_FACTORS[item.highestDiffrence.factor]+1;
        }
        else {
            FREQUENCY_OF_FACTORS[item.highestDiffrence.factor] = 1;
        }
    })
  
    let mostFrequent = undefined;
    const MOST_FREQUENT_WITH_SAME_WEATAGE = [];
  
    for (const key in FREQUENCY_OF_FACTORS) {
        if(!mostFrequent || mostFrequent.freq < FREQUENCY_OF_FACTORS[key]){
            mostFrequent = {factor: key, freq: FREQUENCY_OF_FACTORS[key]};
        }
        if(mostFrequent.freq < FREQUENCY_OF_FACTORS[key]){
            MOST_FREQUENT_WITH_SAME_WEATAGE.push({factor: key, freq: FREQUENCY_OF_FACTORS[key]});
        }
    }
  
    if(!mostFrequent){
        return `No Factors in this range`;
    }
  
    let finalString = `${incDec(mostFrequent.factor)} ${mostFrequent.factor}`;
  
    MOST_FREQUENT_WITH_SAME_WEATAGE.forEach(item => {;
        finalString += ` & ${incDec(item.factor)} ${item.factor}`;
    })
    
    return finalString;
}
  
function useIsIncreaseOrDicrease(){
    const  incDec = (factor) => {
        return ["Fun","Purpose","Growth"].indexOf(factor) === -1? "Decrease":"Increase"
    }
    
    return  [incDec];
}

function useEventsChangeEffect({events,setstreamGraphChartData}){

    useEffect(() => {
      if(events){
        setstreamGraphChartData(prev => {
          const rem = prev.datasets.filter(dataSet => dataSet.label !== "Events");
  
          return {
            ...prev,
            datasets: [
              {
                type: "scatter",
                label: "Events",
                data: events.map(ev => {return {x: new Date(ev.date),y:-85,description: ev.description,tags: ev.tags}}),
                borderColor: "#db03fc",
                backgroundColor: "#db03fc",
                stack: 'combined',
              },
              ...rem
            ]
          }
        })
      }
    }, [events,setstreamGraphChartData]);
  
    return null;
}
  
function useHistoricalDataChangeEffect({historiclData,setstreamGraphChartData}){
  
    useEffect(() => {
      if(historiclData){
        setstreamGraphChartData(prev => {
          
          const rem = prev.datasets.filter(dataSet => [...XL_FACTORS,"XLScore"].indexOf(dataSet.label) === -1);
     
          return {
            ...prev,
            datasets: [
              ...rem,
               {
                type: 'line',
                label: "XLScore",
                data: historiclData.map(item => {return {x: new Date(item.year),y: item["XLScore"]}}),
                borderColor: FACTOR_COLORS["XLScore".toLocaleUpperCase()],
                backgroundColor: FACTOR_COLORS["XLScore".toLocaleUpperCase()],
                pointRadius: 0,
                fill: false,
                stack: 'combined',
              },
              ...XL_FACTORS.slice(0,6).map(factor => {
                  return {
                    type: 'line',
                    label: factor,
                    data: historiclData.map(item => {return {x: new Date(item.year),y: item[factor]}}),
                    borderColor: FACTOR_COLORS[factor.toLocaleUpperCase()],
                    backgroundColor: FACTOR_COLORS[factor.toLocaleUpperCase()],
                    fill: true,
                    pointRadius: 0,
                  }
              })
            ]
          }
        });
      }
    }, [historiclData,setstreamGraphChartData]);
  
    return null;
}

function MyChart({setCurrentIndex,setBrushSelection,chart,setChart,streamGraphChartData,events,setEventData,setEventId,setIsUpdateEvent,setIsOpenAddEventsModal}){
    const chartRef = useRef(null);
    

    function handeDoubleClick(){
        chart.resetZoom();
    }

    function handleOnClick(e){
 
        const points = chart.getElementsAtEventForMode(e,'nearest',{intersect: true},true);
        const data = points[0];
        if(!data){
            return;
        }
        // if user click on event circle 
        if(data.datasetIndex === 0){
            const {index} = data;
            const event = events[index];
            setEventData(event);
            setEventId(event._id);
            setIsUpdateEvent(true);
            setIsOpenAddEventsModal(true);
            return;
        }
    }

    useEffect(() => {
        let chart = undefined;
        if(streamGraphChartData){
            
            const getOrCreateTooltip = (chart) => {
                let tooltipEl = chart.canvas.parentNode.querySelector('div');
              
                if (!tooltipEl) {
                  tooltipEl = document.createElement('div');
                  tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
                  tooltipEl.style.borderRadius = '3px';
                  tooltipEl.style.color = 'white';
                  tooltipEl.style.opacity = 1;
                  tooltipEl.style.pointerEvents = 'none';
                  tooltipEl.style.position = 'absolute';
                  tooltipEl.style.transform = 'translate(-50%, 0)';
                  tooltipEl.style.transition = 'all .1s ease';
              
                  const table = document.createElement('table');
                  table.style.margin = '0px';
              
                  tooltipEl.appendChild(table);
                  chart.canvas.parentNode.appendChild(tooltipEl);
                }
              
                return tooltipEl;
            };
              
            const externalTooltipHandler = (context) => {
                // Tooltip Element
                const {chart, tooltip} = context;
                const tooltipEl = getOrCreateTooltip(chart);

                if(tooltip.dataPoints[0].dataset.label !== "Events" && tooltip.dataPoints[0].dataset.label !== "Action Challenges"){
                    return;
                }
              
                // Hide if no tooltip
                if (tooltip.opacity === 0) {
                  tooltipEl.style.opacity = 0;
                  return;
                }
              
                // Set Text
                if (tooltip.body) {
                  const titleLines = tooltip.title || [];
                  const bodyLines = tooltip.body.map(b => b.lines);
              
                  const tableHead = document.createElement('thead');
              
                  titleLines.forEach(title => {
                    const tr = document.createElement('tr');
                    tr.style.borderWidth = 0;
              
                    const th = document.createElement('th');
                    th.style.borderWidth = 0;
                    const text = document.createTextNode(title);
              
                    th.appendChild(text);
                    tr.appendChild(th);
                    tableHead.appendChild(tr);
                  });
              
                  const tableBody = document.createElement('tbody');
                  bodyLines.forEach((body, i) => {
                    const colors = tooltip.labelColors[i];
              
                    const span = document.createElement('span');
                    span.style.background = colors.backgroundColor;
                    span.style.borderColor = colors.borderColor;
                    span.style.borderWidth = '2px';
                    span.style.marginRight = '10px';
                    span.style.height = '10px';
                    span.style.width = '10px';
                    span.style.display = 'inline-block';
              
                    const tr = document.createElement('tr');
                    tr.style.backgroundColor = 'inherit';
                    tr.style.borderWidth = 0;
              
                    const td = document.createElement('td');
                    td.style.borderWidth = 0;

                    if(tooltip.dataPoints[i].dataset.label === "Events"){
                      const rawData = tooltip.dataPoints[i].raw;
                      const text = document.createTextNode(rawData.description);
                      td.appendChild(span);
                      td.appendChild(text);
                      rawData.tags.forEach(tag => {
                        const button = document.createElement("button");
                        button.innerText = tag;
                        button.style.backgroundColor = "blue";
                        button.style.marginLeft = "2px";
                        button.style.marginBottom = "2px";
                        button.style.padding = "0px 10px";
                        button.style.color = "#ffffff";
                        button.style.borderRadius = "5px"
                        td.appendChild(button)
                      });
                      tr.appendChild(td);
                      tableBody.appendChild(tr);
                    }
                    else if(tooltip.dataPoints[i].dataset.label === "Action Challenges"){
                      const rawData = tooltip.dataPoints[i].raw;
                      const text = document.createTextNode(rawData.actionChallenge);
                      td.appendChild(span);
                      td.appendChild(text);
                      rawData.steps.forEach(step => {
                        const button = document.createElement("button");
                        button.innerText = step.step;
                        button.style.backgroundColor = "blue";
                        button.style.marginLeft = "2px";
                        button.style.marginBottom = "2px";
                        button.style.padding = "0px 10px";
                        button.style.color = "#ffffff";
                        button.style.borderRadius = "5px"
                        td.appendChild(button)
                      });
                      tr.appendChild(td);
                      tableBody.appendChild(tr);
                    }
                    else {
                      const text = document.createTextNode(body);
              
                      td.appendChild(span);
                      td.appendChild(text);
                      tr.appendChild(td);
                      tableBody.appendChild(tr);
                    }
                  });
              
                  const tableRoot = tooltipEl.querySelector('table');
              
                  // Remove old children
                  while (tableRoot.firstChild) {
                    tableRoot.firstChild.remove();
                  }
              
                  // Add new children
                  tableRoot.appendChild(tableHead);
                  tableRoot.appendChild(tableBody);
                }
              
                const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
              
                // Display, position, and set styles for font
                tooltipEl.style.opacity = 1;
                tooltipEl.style.background = '#ffffff';
                tooltipEl.style.color = '#000000';
                tooltipEl.style.border = '1px solid #000000';
                tooltipEl.style.left = positionX + tooltip.caretX + 'px';
                tooltipEl.style.top = positionY + tooltip.caretY + 'px';
                tooltipEl.style.font = tooltip.options.bodyFont.string;
                tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
            };


            chart = new ChartJS(chartRef.current, {
                type: 'bar',
                data: streamGraphChartData,
                options: {
                    scales: {
                    x: {
                                        type: 'time',
                                        ticks: {
                                        autoSkip: true,
                                        autoSkipPadding: 50,
                                        maxRotation: 0,
                                        },
                    },
                    y: {
                                        stacked: true,
                                        ticks: {
                                        callback: function(ctx,i){
                                            return i*10;
                                        }
                                        },
                                        min: -100,
                                        max: 100
                    }
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        title: {
                                            display: false,
                                            text: (ctx) => 'Test Chart'
                        },
                        tooltip: {
                            enabled: false,
                            position: 'nearest',
                            external: externalTooltipHandler
                        },
                        zoom: { 
                            limits: {
                                y: {min: -100,max: 100,minRange: 0}
                            },
                            pan: {
                                enabled: true,
                                modifierKey: 'ctrl',
                                mode: 'x',
                                onPanComplete: function(e){
                                    const chart = e.chart;
                                    const selection = {
                                        range: [new Date(chart.scales.x.min),new Date(chart.scales.x.max)]
                                    };
                                    if(chart.getZoomLevel() === 1){
                                        chart.update();
                                        setBrushSelection(selection);
                                        return;
                                    }
                                    const startTick = chart.scales.x.ticks[0];
                                    const endTick = chart.scales.x.ticks[chart.scales.x.ticks.length-1];
                                    const majorTicks = chart.data.labels.map((item,index) => {return {year: item,index}}).filter(item => moment(item.year).isBetween(moment(startTick.value),moment(endTick.value)));
                                    const highlightedTick = majorTicks[Math.round((majorTicks.length - 1) / 2)];
     
                                    if(highlightedTick){
                                        chart.options.plugins.annotation.annotations.line1.xMin = new Date(highlightedTick.year);
                                        chart.options.plugins.annotation.annotations.line1.xMax = new Date(highlightedTick.year);
                                        chart.update();
                                        setCurrentIndex(highlightedTick.index);
                                    }
                                    setBrushSelection(selection);
                                }
                            },
                            zoom: {
                                drag: {
                                    enabled: true,
                                    backgroundColor: "#00000055"
                                },
                                pinch: {
                                    enabled: true
                                },
                                mode: 'x',
                                onZoomComplete: function(e,f){
                                    const chart = e.chart;
                                    const selection = {
                                        range: [new Date(chart.scales.x.min),new Date(chart.scales.x.max)]
                                    };
                                    if(chart.getZoomLevel() === 1){
                                        chart.update();
                                        setBrushSelection(selection);
                                        return;
                                    }
                                    const startTick = chart.scales.x.ticks[0];
                                    const endTick = chart.scales.x.ticks[chart.scales.x.ticks.length-1];
                                    const majorTicks = chart.data.labels.map((item,index) => {return {year: item,index}}).filter(item => moment(item.year).isBetween(moment(startTick.value),moment(endTick.value)));
                                    const highlightedTick = majorTicks[Math.round((majorTicks.length - 1) / 2)];
     
                                    if(highlightedTick){
                                        chart.options.plugins.annotation.annotations.line1.xMin = new Date(highlightedTick.year);
                                        chart.options.plugins.annotation.annotations.line1.xMax = new Date(highlightedTick.year);
                                        chart.update();
                                        setCurrentIndex(highlightedTick.index);
                                    }
                                    setBrushSelection(selection);
                                }
                            }
                        },
                        legend: {
                                            labels: {
                                            boxWidth: 10,
                                            boxHeight: 10,
                                            font: 10
                                            },
                        },
                        annotation: {
                            annotations: {
                                line1: {
                                    type: 'line',
                                    yMin: -100,
                                    yMax: 100,
                                    xMin: streamGraphChartData.labels[streamGraphChartData.labels.length-1],
                                    xMax: streamGraphChartData.labels[streamGraphChartData.labels.length-1],
                                    borderColor: '#000000',
                                    borderWidth: 2,
                                }
                            }
                        }
                    }
                }
            });
            setChart(chart);
        }
        return () => {
            if(chart){
                chart.destroy();
            }
        }
    }, [streamGraphChartData,setEventData,setEventId,setIsUpdateEvent,setIsOpenAddEventsModal,events,setChart,setBrushSelection,setCurrentIndex]);


    if(!streamGraphChartData){
        return null;
    }


    return <canvas ref={chartRef} onDoubleClick={handeDoubleClick} onClick={handleOnClick}></canvas>
}

const ToggleButton = ({isChecked,setIsChecked}) => {

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  return (
    <>
      <label className='autoSaverSwitch relative inline-flex cursor-pointer select-none items-center'>
        <input
          type='checkbox'
          name='autoSaver'
          className='sr-only'
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <span
          className={`slider mr-3 flex h-[26px] w-[50px] items-center rounded-full p-1 duration-200 ${
            isChecked ? 'bg-primary' : 'bg-[#CCCCCE]'
          }`}
        >
          <span
            className={`dot h-[18px] w-[18px] rounded-full bg-white duration-200 ${
              isChecked ? 'translate-x-6' : ''
            }`}
          ></span>
        </span>
        <span className='label flex items-center text-sm font-medium'>
        <span className='pl-1 text-[18px]'> {isChecked ? 'Hide' : 'Show'} Event Details:</span>
        </span>
      </label>
    </>
  )
}



