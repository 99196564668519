// import InfoIcon from "../InfoIcon/InfoIcon";
import { useEffect, useState } from "react";
import {FACTOR_COLORS} from "../../../constants";
import {UI} from "../../../components";

const infoTexts = [
  `This indicates how much you are driven by the activity* itself (i.e. when you do in an activity simply because you enjoy it)`,
  `This indicates how much you are driven by the value and impact of  activity* whether you enjoy it or not!`,
  `This indicates how much you are driven by the self-development value of the activity*`,
  `This indicates how much you do the activity* because of emotional pressure/s (i.e. guilt, peer pressure, fear, etc.) not because you enjoy or value the activity itself.`,
  `This indicates how much you do the activity* to gain a reward, or avoid a punishment.`,
  `A high score here suggests you do not have a strong reason why you're doing the activity*  and tends to result in feelings of suppression, detachment and apathy about the activity.`
]

const actionChallangeInfo = `These are some simple things you can action to improve the XL factor that will give you the greatest impact in terms of accessing the mental states for peak performance you want so improve & make your goals happen.`;


function ActionChallenge({currentActionChallanges,higestDiffrance,forTeam,mixPanelEvent,handleActionChallengeClick}) {
  const [factor, setFactor] = useState("");
  const [color, setColor] = useState("");
  const [isDriver, setIsDriver] = useState(true);
  const [actionChallengeInfo, setactionChallengeInfo] = useState(infoTexts[0]);

  
 
  useEffect(() => {
    setFactor(higestDiffrance.factor);
    if(higestDiffrance.factor === "Fun" || higestDiffrance.factor === "Purpose" || higestDiffrance.factor === "Growth"){
      setIsDriver(true);
    }
    else{
      setIsDriver(false);
    }

    const clr = FACTOR_COLORS[higestDiffrance.factor.toUpperCase()];
    // console.log(clr);
    setColor(clr);
    
  }, [higestDiffrance.factor]);  


  useEffect(() => {
    if(higestDiffrance.factor === "Fun"){
      setactionChallengeInfo(infoTexts[0]);
    }
    if(higestDiffrance.factor === "Purpose"){
      setactionChallengeInfo(infoTexts[1]);
    }
    if(higestDiffrance.factor === "Growth"){
      setactionChallengeInfo(infoTexts[2]);
    }
    if(higestDiffrance.factor === "Emotives"){
      setactionChallengeInfo(infoTexts[3]);
    }
    if(higestDiffrance.factor === "Utility"){
      setactionChallengeInfo(infoTexts[4]);
    }
    if(higestDiffrance.factor === "Apathy"){
      setactionChallengeInfo(infoTexts[5]);
    }
  }, [higestDiffrance.factor]);

  return (
    <>
    <div onClick={mixPanelEvent} onMouseEnter={mixPanelEvent} className="pl-0 max-h-full">
            <h4 className="text-sm font-bold">{forTeam? "Leadership Action Challenge":"Your Action Challenge"}</h4>
            <div className="action-challanges text-xs">

              <p>
                It has been calculated that 
                {isDriver? <span className='font-bold mx-1'>increasing</span>:<span className='font-bold mx-1'>decreasing</span>} 
                <span className='rounded ml-1 px-2' style={{backgroundColor: `${color}`}}>{factor}</span> 
                <UI.InfoIconWithToolTip content={actionChallengeInfo}/>
                {/* <span id='factor-to-improve' className='mr-1 hover:cursor-pointer' data-tip={actionChallengeInfo}><InfoIcon/></span>  */}
                has the potential to create the greatest uplift {forTeam? "in the teams":"in your"} overall wellbeing and performance.
              </p>
             
              
              <p className=''>
                Choose one of the actions below and see what impact it will have on {forTeam? "the team":"on your"} XL score:
                <UI.InfoIconWithToolTip content={actionChallangeInfo}/>
                {/* <span id='action-info' className='mx-1 hover:cursor-pointer' data-tip={actionChallangeInfo}><InfoIcon/></span>  */}
              </p>

              
              {/* chalanges  */}
                <div className='flex gap-2 flex-wrap max-h-[350px] overflow-auto'>
                  {
                    currentActionChallanges.map((c,i)=> {
                      return <UI.Button 
                      key={i} 
                      className='px-4 py-3 w-full text-white'
                      onClick={()=>handleActionChallengeClick(c)}
                      >{c.actionChallenge}</UI.Button>
                    })
                  }
                </div>
            </div>
    </div>
    </>
    
  )
}

export {ActionChallenge};