import React, { useEffect, useState } from 'react'
import {Main} from "../../layout";
import {
    getUserNotifications,
    deleteNotification
} from "../../Store/actions";
import {Loading} from "../../components";
import { toast } from 'react-toastify';
import {
    NotificationModal, 
    Notifications
} from "./components";

function Index() {
    const [notifications, setNotifications] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isShowModal, setIsShowModal] = useState(false);
    const [notificationToEdit, setNotificationToEdit] = useState(null);
    

    const handleDelete = async (e,item) => {
        if(!window.confirm("Are you sure?")){
            return;
        };

        const res = await deleteNotification(item._id);
        if(res.success){
            toast.success("Deleted Successfutty!");
            setNotifications(prev => {
                return prev.filter(noti => noti._id !== item._id);
            });
        }
        else {
            toast.error(res.message);
        }
        // console.log(e,item);
    }

    useEffect(() => {
        (async ()=> {
            setIsLoading(true)
            const res = await getUserNotifications();
            setIsLoading(false);
            if(res.success){
                setNotifications(res.notifications);
            }
        })()
    }, []);


    return (
        <Main containerClasses={"px-2"}>
            <div className="items-start justify-between items-center md:flex border-b pb-7">
                <div className="max-w-lg">
                    <h3 className="text-gray-800 text-xl font-bold sm:text-2xl">Manage Notifications</h3>
                </div>
                <div className="mt-3 md:mt-0 w-[150px]">
                    <button onClick={()=>setIsShowModal(true)} className={`w-full p-2.5 flex-1 rounded-md ring-offset-2  focus:ring-2  border`}>
                        Create New
                    </button>
                </div>
            </div>
            {
                isLoading? <Loading/>:<Notifications handleDelete={handleDelete} setNotificationToEdit={setNotificationToEdit} notifications={notifications}  setIsShowModal={setIsShowModal}/>
            }
            {
                isShowModal && <NotificationModal setNotificationToEdit={setNotificationToEdit} notificationToEdit={notificationToEdit} setIsShowModal={setIsShowModal} setNotifications={setNotifications}/>
            } 
        </Main>
    )
}

export default Index;

