import React, { useState } from 'react';
import {useMixpanelEvents} from "./useMixpanelEvents";

function Users({allUserNames,condition}) {
    const [showUsers,setShowUsers] = useState(false);
    const {showHideTeamMembers} = useMixpanelEvents();

    

  return (
    <>
     {
                              <div onClick={showHideTeamMembers} onMouseEnter={showHideTeamMembers} className="mb-10" style={{marginTop: condition? "120px":"52px" }}>
                                <label htmlFor="default-toggle" className="inline-flex relative items-center cursor-pointer">
                                      <input type="checkbox" onChange={()=>setShowUsers(p =>!p)} value="" id="default-toggle" className="sr-only peer"/>
                                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                      <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Show/Hide Users</span>
                                </label>
                              </div>
                              }
                              {/* users list  */}
                              {
                                showUsers && <>
                                      <div className="rounded-md w-[122px] h-[215px] overflow-auto mb-10 flex flex-col bg-blue-500 p-2">
                                        {
                                          allUserNames?.map((u,i) => (
                                            <span key={i} className='text-white text-[12px]'>{i+1}. {u}</span>
                                          )) 
                                        }
                                      </div>
                                </>
                              }
    </>
  )
}

export {Users};