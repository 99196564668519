import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {getActionChallengeOfTeam, getActionChallengeOfUser, setNumberOfActionChallenges} from "../../Store/actions";
import moment  from 'moment';
import { useNavigate } from 'react-router-dom';
import {Main} from "../../layout";
import {UI,Thead, Table, Tbody, Td, Th, Tr,} from "../../components";
import { deleteActionChallenge } from '../../Store/actions';
import { toast } from 'react-toastify';




function Index() {
    const loggedInUser = useSelector(state=> state.loggedInUser);
    const previousRoute = useSelector(state=> state.globalStates.previousRoute);
    const [actionChallenges, setActionChallenges] = useState(null);
    const [showActionChallengePopup, setShowActionChallengePopup] = useState(false);
    const [selectedActionChalenge, setSelectedActionChalenge] = useState(null);
    const navigator = useNavigate();
    

    const handleBack = () => {
        navigator(previousRoute);
    }

    useEffect(() => {
        (async ()=> {
            if(!loggedInUser){
                return;
            }
            const res = await getActionChallengeOfUser({userId: loggedInUser._id});
            const res1 = await getActionChallengeOfTeam({userId: loggedInUser._id});
            if(res.success){
                const completedOnes = res.actionChallenges.filter(ac => ac.isCompleted);
                const unCompletedOnes = res.actionChallenges.filter(ac => !ac.isCompleted);
                setActionChallenges([...unCompletedOnes,...completedOnes]);
            }
            if(res1.success){
                const completedOnes = res1.actionChallenges.filter(ac => ac.isCompleted);
                const unCompletedOnes = res1.actionChallenges.filter(ac => !ac.isCompleted);
                setActionChallenges(p => [...p,...unCompletedOnes,...completedOnes])
            }
        })()
    }, [loggedInUser,showActionChallengePopup]);

  return (
        <Main containerClasses="bg-white">    
            <div className="flex items-center justify-between md:flex">
                <div className="max-w-lg">
                    <h3 className="text-gray-800 text-xl mb-0 font-bold sm:text-2xl">
                        All Action-Challenges
                    </h3>
                </div>
                <div className="max-w-lg">
                    <UI.Button onClick={handleBack} className="text-gray-800 shadow-none font-bold bg-white border px-4 py-2">
                        Back
                    </UI.Button>
                </div>
            </div>
            {
                actionChallenges &&  <MyTable setActionChallenges={setActionChallenges} setShowActionChallengePopup={setShowActionChallengePopup} setSelectedActionChalenge={setSelectedActionChalenge} actionChallenges={actionChallenges}/>
            }    
            <UI.ActionChallengePopup userId={loggedInUser._id} factor={selectedActionChalenge?.factor} currentActionChallenge={selectedActionChalenge} show={showActionChallengePopup} setShow={setShowActionChallengePopup}/>
        </Main>
  )
}

export default Index;



function MyTable({setActionChallenges,actionChallenges,setSelectedActionChalenge,setShowActionChallengePopup}){
    const dispatch = useDispatch();

    const handleView = (actionChallenge) => {
        setShowActionChallengePopup(true);
        setSelectedActionChalenge(actionChallenge);
    }

    const handleDeleteActionChallenhe = async (item) => {
        const isSure = window.confirm("Are You Sure!");
        if(!isSure){
            return;
        }

        const actionChallengeId = item._id;
        const res = await deleteActionChallenge({actionChallengeId});
        // console.log(res);
        if(res.success){
            toast.success("Deleted Successfutty!");
            setActionChallenges(prev => {
                const rem = prev.filter(ac => ac._id !== actionChallengeId);
                dispatch(setNumberOfActionChallenges(rem.length));
                return rem;
            });
        }
        else {
            toast.error(res.message);
        }
    }

    const tableHeaders = ["Name","For","Accepted Date","Status","Action"];


    return (
            <div className="mt-12 relative h-max overflow-auto">
                <Table>
                    <Thead>
                        <Tr>
                            {
                              tableHeaders.map(h => <Th key={h} className="py-3 pr-6">{h}</Th>)  
                            }
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            actionChallenges.map((item, idx) => (
                                <Tr key={idx}>
                                    <Td>{item.actionChallenge}</Td>
                                    <Td>{item.for}</Td>
                                    <Td>{moment(item.createdAt).format("DD/MM/YYYY")}</Td>
                                    <Td>
                                        <span className={`px-3 py-2 rounded-full font-semibold text-xs ${item.isCompleted? "text-green-600 bg-green-50" : "text-blue-600 bg-blue-50"}`}>
                                            {item.isCompleted? "Completed":"In Progress"}
                                        </span>
                                    </Td>
                                    <Td>
                                        <UI.Button onClick={()=>handleView(item)} className="text-white py-1.5 px-3 text-gray-600 duration-150 border shadow-none">
                                            View
                                        </UI.Button>
                                        <UI.Button onClick={()=>handleDeleteActionChallenhe(item)} className="text-white py-1.5 px-3 bg-red-600 text-gray-600 duration-150 border shadow-none">
                                            Delete
                                        </UI.Button>
                                    </Td>
                                </Tr>
                            ))
                        }
                    </Tbody>
                </Table>
            </div>
    )
}



