import { MdArrowBackIosNew } from "react-icons/md";
import { useSelector } from "react-redux";
import { UI } from "../../../components";

function ProgressBarAndBackButton({totalSteps=6,completedSteps,handleBack}){
    const loggedInUser = useSelector(state => state.loggedInUser);
    const activitieWithNoName = loggedInUser.activities.find(act => !act.name);
    totalSteps = activitieWithNoName? 6:5;

    return (
        <>
            <div className="flex mb-2 items-center">
                {
                    handleBack && <MdArrowBackIosNew onClick={handleBack} className='border rounded-full w-10 h-10 p-2 bg-secondry-color text-primary-color cursor-pointer'/>
                }
                <div className={`flex-1 ${handleBack? "pl-4":""}`}>
                    <UI.ProgressBar totalSteps={totalSteps} bgClass={"bg-[#001f3f]"} completedSteps={completedSteps} height={10}/>
                </div>
            </div>
        </>
    )
};


export {ProgressBarAndBackButton};