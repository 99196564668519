import React, { useEffect } from 'react';
import {UserSurveySelection} from "./components";
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';


function Index() {
    const location = useLocation();
    const loggedInUser = useSelector(state => state.loggedInUser);
    const [isHowIsWorksSeen,setIsHowItWorksSeen] = useState(false);
    const [isOnboardingSettingSeen,setIsOnboardingSettingSeen] = useState(false);


  

    useEffect(() => {
        if(location.state?.payload === "updateOnlyActivity"){
            setIsHowItWorksSeen(true);
            setIsOnboardingSettingSeen(true);
        }
        else {
            if(loggedInUser.activities?.length > 0){
                setIsHowItWorksSeen(true);
                setIsOnboardingSettingSeen(true);
            }
            if(location?.state?.isCommingFromPrePostSurvey){
                setIsHowItWorksSeen(true);
                setIsOnboardingSettingSeen(true);
            }
        }
    }, [loggedInUser,location]);

  return (<>
       {
        !isHowIsWorksSeen && <HowItWorks setIsHowItWorksSeen={setIsHowItWorksSeen}/>
       }

       {
        (isHowIsWorksSeen && !isOnboardingSettingSeen) &&  <OnboardingSetting setIsOnboardingSettingSeen={setIsOnboardingSettingSeen}/>
       }

       {
        (isHowIsWorksSeen && isOnboardingSettingSeen) && <UserSurveySelection showOnlyUpdateActPage={location.state?.payload === "updateOnlyActivity"} isCommingFromPrePostSurvey={location?.state?.isCommingFromPrePostSurvey}/>
       }
    </>)
}

export default Index;



function HowItWorks({setIsHowItWorksSeen}){

    const howItWorks = [
        `Set your purpose & goals for the areas of your life that you want to track and improve (this onboarding takes approx. 5-10 mins).`,
        `Track your state to increase your self awareness (approx. 50 secs).`,
        `Take action challenges: Complete daily challenges that are designed to increase your personal growth potential, enhance your performance & help you make progress towards your goals.`,
        `Earn rewards: Every time you complete an action, you'll earn personal growth points that can be redeemed for in game rewards.`,
        `Level up: As you accumulate points, you'll level up and unlock new rewards and challenges.`,
        `Encourage & compete with other like minded people in your team to improve yourself & others.`,
    ]


    return (<>
    <div className="container max-w-[800px] mx-auto pt-10">
        <h4 className='pt-5 pb-2'>Welcome to XLrate, your personal growth platform!</h4>
        <p>We believe that personal development should be fun and rewarding, which is why we've created an exciting new game that will help you achieve your goals and become the best version of yourself.</p>
        <h5 className='py-3'>Here's how it works:</h5>
        <ol className='list-decimal'>
            {
                howItWorks.map(item => (
                    <li className='mb-3' key={item}>{item}</li>
                ))
            }
        </ol>
        <p className='pt-3'>By using XLrate, you'll not only be improving yourself, you'll also be having fun and earning rewards along the way and encouraging others to become the best version of themselves!</p>
        
        <div className="flex justify-center mt-4">
            <button onClick={()=>setIsHowItWorksSeen(true)} className='bg-blue-700 text-white px-4 py-2 rounded-md'>Continue</button>
        </div>
    </div>
   </>)
}



function OnboardingSetting({setIsOnboardingSettingSeen}){

    const onboardingSetting = [
        `Choose the activities that you want to track and improve.`,
        `Quickly generate a powerful purpose for the activities you want to track and improve.`,
        `Set an exciting "moonshot" goal for each activity effortlessly.`,
    ]


    return (<>
    <div className="container max-w-[800px] mx-auto pt-10">
        <h4 className='pt-5 pb-2 text-center'>Onboarding: Setting you up for success</h4>
        <ol className='list-decimal mt-4 flex flex-col items-center'>
            {
                onboardingSetting.map(item => (
                    <li className='mb-3' key={item}>{item}</li>
                ))
            }
        </ol>
        <p className='pt-3 text-center'>This onboarding is a one-off thing and should take between 5-10mins.</p>
        
        <div className="flex justify-center mt-4">
            <button onClick={()=>setIsOnboardingSettingSeen(true)} className='bg-blue-700 text-white px-4 py-2 rounded-md'>Continue</button>
        </div>
    </div>
   </>)
}
