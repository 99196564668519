import React,{useEffect, useState} from 'react';
import "./Index.css";
import { useDispatch } from "react-redux";
import {Tree} from "./components"; 
import {
  getAllGroups,
  getAllScheduledTimesForGroups,
  getAllSurveyTitles
} from "../../Store/actions";
import { Loading } from '../../components';
import {Main} from "../../layout";



function Index() {
  // const groups = useSelector(state => state.groups);
  const [groups, setGroups] = useState(null);
  const [scheduledTimes, setScheduledTimes] = useState(null);
  const [surveyTitles, setSurveyTitles] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    (async ()=> {
      const res = await getAllGroups();
      if(res.success){
        setGroups(res.groups);
      }

      const res1 = await getAllScheduledTimesForGroups();
      if(res1.success){
        setScheduledTimes(res1.surveyPeriodFrequencyAndTimes);
      }

      const res2 = await getAllSurveyTitles();
      if(res2.success){
        setSurveyTitles(res2.surveyTitles);
      }
    })()
  }, [dispatch]);

  
  if(!groups){
    return <><Loading/></>
  }
  
  return (
    <>
    <Main containerClasses="bg-white">
      {
        groups && <Tree data={groups} scheduledTimes={scheduledTimes} setScheduledTimes={setScheduledTimes} surveyTitles={surveyTitles}/>
      }
    </Main>
    </>
  )
}

export default Index;