import mixpanel from 'mixpanel-browser';


function useMixpanelBrowser() {

    const addEvent = (userId,text) => {
        if(!userId || !text){
            console.log("userId and text are required to add mix panel event!");
            return;
        }
        if(window.location.hostname === "localhost"){
            console.log(`${text}`);
            return;
        }
        mixpanel.identify(userId);
        mixpanel.track(text);
    }

    return {addEvent};
}

export default useMixpanelBrowser;