import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {PrimaryButton} from "../../../components";
// import {useMixpanelBrowser} from "../../../Hooks";
import {capitalized} from "../../../utils";
import {TempMain,CenterContent} from "../../../layout";


function Index({user,currentCategory,currentName,isCommingFromPrePostSurvey,setpurposeAndGoalStatementSeen}) {
  const [goal,setGoal] = useState(null);
  const [purpose,setPurpose] = useState({});
  const navigator = useNavigate();
  // const {addEvent} = useMixpanelBrowser();

  const handleNext = () => {
    setpurposeAndGoalStatementSeen(true);
    // addEvent(user?._id,"Purpose & Goal Next Button!");
  }

  useEffect(() => {
    if(user){
      const currentActivity = user.activities.find(act => act.category.trim() === currentCategory?.trim() && act.name.trim() === currentName?.trim())
  
      if(isCommingFromPrePostSurvey){
        setpurposeAndGoalStatementSeen(false);
      }
      if(currentActivity && !isCommingFromPrePostSurvey){
        if(currentActivity.goal && currentActivity.goal.length === 0){
          setpurposeAndGoalStatementSeen(false);
        }
        else {
          setGoal(currentActivity.goal);
          setPurpose(currentActivity.purpose);
        }
      }
      if(!user.activities && user.activities.length === 0){
        navigator("/user-survey-selection");
      }
    }
  }, [user,currentCategory,currentName,navigator,isCommingFromPrePostSurvey,setpurposeAndGoalStatementSeen]);

  

  return (
    <>
      <TempMain>
          <CenterContent>
              <h1 className="text-sm font-bold text-secondry-color  tracking-widest text-center mb-1">
                My purpose for my {currentName} {currentCategory}
              </h1>
              <p className="text-sm  tracking-widest mb-5 text-center text-secondry-color">
                {
                  purpose && <>
                    Using my {purpose[0]?.toLowerCase()} and my {purpose[1]?.toLowerCase()} I {purpose[2]?.toLowerCase()} {purpose[3]?.toLowerCase()} to {purpose[4]?.toLowerCase()} {purpose[5]?.toLowerCase()} in order to {purpose[6]?.toLowerCase()} {purpose[7]?.toLowerCase()} while staying true to my core values of {purpose[8]?.toLowerCase()} {purpose[9]?.toLowerCase()} & {purpose[10]?.toLowerCase()}
                  </>
                }
              </p>
              <p className="text-sm font-bold  tracking-widest text-center mb-1 text-secondry-color">
                My current 90 day goal for {currentName} {currentCategory}
              </p>
              <p className="text-sm  tracking-widest text-center mb-8 text-secondry-color">
                  {
                    goal && Object.keys(goal)?.map((key,idx) => idx===0? capitalized(`${goal[key]} `):`${goal[key]} `.toLowerCase())
                  }
              </p>
            
              <PrimaryButton onClick={handleNext}>
                Next
              </PrimaryButton>
          </CenterContent>
      </TempMain>
    </>
  )
}

export default Index;