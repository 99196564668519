import React, { useState } from 'react';
import {updateLoggedInUser} from "../../../Store/actions";
import {useDispatch, useSelector} from "react-redux";
import {Loading} from '../../../components';
import { useNavigate} from 'react-router-dom';
import {objToArr} from "./index";
import { formatGoal,formatPurpose } from '../../../utils';


function ForthPage({activityesWithPurposeAndGoal,purpose,setActivityesWithPurposeAndGoal,setPurpose,setGoal,selectedActivityes,setCurrentActivityIndex,goal,currentName,currentCategory,setVisivility}) {
  
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const loggedInUser = useSelector(state => state.loggedInUser);
  const [loading] = useState(false);

  const handleContinue = async () => {

    setActivityesWithPurposeAndGoal(prev => {
      return [...prev,{
        category: currentCategory,
        name: currentName,
        purpose: objToArr(purpose),
        goal: objToArr(goal)
      }];
    })

    if(selectedActivityes[selectedActivityes.length-1].category === currentCategory){
      const activities = [...activityesWithPurposeAndGoal];
      
      if(activities.length !== selectedActivityes.length){
        activities.push({
          category: currentCategory,
          name: currentName,
          purpose: objToArr(purpose),
          goal: objToArr(goal)
        })
      }
      sendLink(activities);
      return;
    }
    else {
      setPurpose({});
      setGoal({});
      setCurrentActivityIndex(prev => prev+1);
      setVisivility({
        firstPage: true,
        secondPage: false,
        thirdPage: false,
        forthPage: false,
      })
    }
  }

  const handleBack = () => {
    setVisivility({thirdPage: true,forthPage: false,})
  }

  const sendLink = async (activities) => {

      dispatch(updateLoggedInUser(activities));


      const today = new Date();
            let redirectTo = undefined;
            const unCompletedSurveys = loggedInUser.surveysinfo.filter(survey => !survey.iscompleted);
            unCompletedSurveys.forEach(survey => {
                const link = survey.link.split("?")[0];
                const date = new Date(Number(link.split("/")[link.split("/").length-1]));
                if(today.getDate() === date.getDate() && today.getMonth() === date.getMonth() && today.getFullYear() === date.getFullYear()){
                    const arr = survey.link.split("/");
                    redirectTo = `/${arr.slice(3).join("/")}`
                }
            });
      
      if(redirectTo){
        navigator(redirectTo);
      }
      else {
        navigator(`/score/user/${loggedInUser._id}`);
      }      
  }

  return (
    <>
      {
        loading && <Loading/>
      }
      {
        !loading && <>
         <h3 className='text-center font-bold'>My purpose for my {currentName} {currentCategory}</h3>
          <p className='bg-white rounded-lg p-4 text-center mt-3 max-w-[500px] text-[20px] font-bold mx-auto'>
            {formatPurpose(purpose)}
          </p>
          <h3 className='text-center font-bold mt-10'>My current 90 day goal for  {currentName} {currentCategory}</h3>
          <p className='bg-white rounded-lg p-4 text-center mt-3 max-w-[500px] text-[20px] font-bold mx-auto'>
            {formatGoal(objToArr(goal))}
          </p>
          <div className="flex justify-center mt-5 gap-10">
            <button onClick={handleBack} className='rounded-md px-4 py-2 bg-blue-600 text-white font-bold'>Back</button>
            <button onClick={handleContinue} className='rounded-md px-4 py-2 bg-blue-600 text-white font-bold'>Next</button>
          </div>
        </>
      }
    </>
  )
}

export default ForthPage;