import React from 'react'

function XLScoreBar({XLScore,XLChange}) {
    XLScore = parseFloat(XLScore).toFixed(1);
    XLChange = parseFloat(XLChange).toFixed(1);
    const height = 300;
    const width = (height*20)/100;
    const bg = 'bg-gray-600';
    const arrowBg = `border-l-gray-600`;
  

    return (
        <div style={{width: `${width}px`,height: `${height}px`}} className={`${bg} rounded-[30px] relative`}>
          {/* pointer  */}
          <div style={{top: `calc(${100-XLScore}% - .5px)`}} className="absolute w-[100px] h-[1px] bg-red-800/0 -left-5">
            <div className={`relative bottom-[7px] left-0 border-y-8 border-y-transparent border-l-[16px] ${arrowBg}`}/>
            <div style={{width: `${width}px`,height: `${width}px`,left: `-${width-1}px`, top: `-${width-14}px`}} className={`relative ${bg} rounded-full flex justify-center items-center`}>
              <h4 className='absolute text-[8px] font-bold -top-[15px]'>Team XL Score</h4>
              <div className="w-full h-full rounded-full flex flex-col justify-center items-center">
                  <p className='mb-0 font-bold text-white text-[20px]'>{XLScore}</p>
                  <p className='mb-0 text-[10px] font-bold' style={{color: XLChange>0? "#00ff00":"#ff0000"}}>{XLChange}% {XLChange>0? "⬆":"⬇"}</p>
              </div>
            </div>
          </div>

          {/* liquid  */}
          <div className="absolute rounded-[30px] w-full bg-gradient-to-t from-[#c92020] via-[#f3c41a] to-[#00f218] h-[100%] overflow-hidden">
            <div style={{height: `${100-XLScore}%`}} className="relative bg-gray-500 w-full"></div>
          </div>

          {/* indicator lines  */}
          <div className="absolute rounded-[30px] w-full h-[100%] overflow-hidden">
            {
              new Array(11).fill(1).map((v,i) => {
                return <div key={i} style={{top: `${(i*8.3)+3}%`}} className="h-[1%] relative bg-white w-[15px]"></div> 
              })
            }
          </div>

          {/* glassy shadow  */}
          <div style={{left: "10px"}} className="absolute h-[20%] w-[8px]  top-[15%] bg-white/10 rounded-full"></div>
          <div style={{left: "10px"}} className="absolute h-[50%] w-[8px]  top-[40%] bg-white/10 rounded-full"></div>
        
          <div className="absolute rounded-[30px] w-full h-[100%] overflow-hidden flex flex-col justify-between items-center">
            {
              ["100","50","0"].map(val => {
                return <div key={val} className="relative text-[10px] font-bold text-white mt-[2px] ml-[2px]">{val}%</div> 
              })
            }
          </div>
        </div>
    );  
}

export {XLScoreBar};