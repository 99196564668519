import {useMixpanelBrowser} from "../../../hooks";

function useMixpanelEvents(userId) {
    const {addEvent} = useMixpanelBrowser();
  
    const teamXLScoreContainer = (e) => {
      e.stopPropagation(); 
      if(e.type === "click"){
        addEvent(userId,"teamXLScoreContainer Clicked!");
        return;
      }
      addEvent(userId,"teamXLScoreContainer Hovered!");
    }
  
    const showHideTeamMembers = (e) => {
      e.stopPropagation(); 
      if(e.type === "click"){
        addEvent(userId,"showHideTeamMembers Clicked!");
        return;
      }
      addEvent(userId,"showHideTeamMembers Hovered!");
    }

    const teamXLScoreRanceSlider = (e) => {
        e.stopPropagation();
        if(e.type === "click"){
          addEvent(userId,"teamXLScoreRanceSlider Clicked!");
          return;
        }
        addEvent(userId,"teamXLScoreRanceSlider Hovered!");
    }

    const teamHeatMapContainer = (e) => {
      e.stopPropagation(); 
        if(e.type === "click"){
          addEvent(userId,"teamHeatMapContainer Clicked!");
          return;
        }
        addEvent(userId,"teamHeatMapContainer Hovered!");
    }

    const teamXLFactorsContainer = (e) => {
      e.stopPropagation(); 
        if(e.type === "click"){
          addEvent(userId,"teamXLFactorsContainer Clicked!");
          return;
        }
        addEvent(userId,"teamXLFactorsContainer Hovered!");
    }

    const teamFactorsShowHideRange = (e) => {
        e.stopPropagation();
        if(e.type === "click"){
          addEvent(userId,"teamShowHideRange Clicked!");
          return;
        }
        addEvent(userId,"teamShowHideRange Hovered!");
    }

    const teamFactorsShowHideChange = (e) => {
        e.stopPropagation();
        if(e.type === "click"){
          addEvent(userId,"teamShowHideChange Clicked!");
          return;
        }
        addEvent(userId,"teamShowHideChange Hovered!");
    }

    const teamFactorsDrivers = (e) => {
        e.stopPropagation();
        if(e.type === "click"){
          addEvent(userId,"teamDrivers Clicked!");
          return;
        }
        addEvent(userId,"teamDrivers Hovered!");
    }

    const teamFactorsConstrainers = (e) => {
        e.stopPropagation();
        if(e.type === "click"){
          addEvent(userId,"teamConstrainers Clicked!");
          return;
        }
        addEvent(userId,"teamConstrainers Hovered!");
    }
  
    const leadershipDiagonsticCenter = (e) => {
      e.stopPropagation(); 
          if(e.type === "click"){
            addEvent(userId,"leadershipDiagonsticCenter Clicked!");
            return;
          }
          addEvent(userId,"leadershipDiagonsticCenter Hovered!");
    }

    const leadershipDiagonsisFromSheet = (e) => {
      e.stopPropagation(); 
          if(e.type === "click"){
            addEvent(userId,"leadershipDiagonsisFromSheet Clicked!");
            return;
          }
          addEvent(userId,"leadershipDiagonsisFromSheet Hovered!");
    }

    const leadershipPrescriptionContainer = (e) => {
      e.stopPropagation(); 
          if(e.type === "click"){
            addEvent(userId,"leadershipPrescriptionContainer Clicked!");
            return;
          }
          addEvent(userId,"leadershipPrescriptionContainer Hovered!");
    }

    const leadershipActionChallenges = (e) => {
      e.stopPropagation(); 
          if(e.type === "click"){
            addEvent(userId,"leadershipActionChallenges Clicked!");
            return;
          }
          addEvent(userId,"leadershipActionChallenges Hovered!");
    }

    const teamHistoryContainer = (e) => {
      e.stopPropagation(); 
          if(e.type === "click"){
            addEvent(userId,"teamHistoryContainer Clicked!");
            return;
          }
          addEvent(userId,"teamHistoryContainer Hovered!");
    }

    const teamHistoryStreamGraphContainer = (e) => {
      e.stopPropagation(); 
          if(e.type === "click"){
            addEvent(userId,"teamHistoryStreamGraphContainer Clicked!");
            return;
          }
          addEvent(userId,"teamHistoryStreamGraphContainer Hovered!");
    }

    const teamHistoryStreamGraph = (e) => {
      e.stopPropagation(); 
          if(e.type === "click"){
            addEvent(userId,"teamHistoryStreamGraph Clicked!");
            return;
          }
          addEvent(userId,"teamHistoryStreamGraph Hovered!");
    }

    const teamEachXLFactorInStreamGraph = (e,buttonName) => {
            e.stopPropagation(); 
            if(e.type === "click"){
              addEvent(userId,`team ${buttonName} clicked!`);
            return;
            }
            addEvent(userId,`team ${buttonName} Hovered!`);
    }

    const teamStreamGraphPrevNextButton = (e) => {
      e.stopPropagation(); 
            if(e.type === "click"){
              addEvent(userId,"teamStreamGraphPrevNextButton Clicked!");
            return;
            }
            addEvent(userId,"teamStreamGraphPrevNextButton Hovered!");
    }

    const teamTrendingDataAnalysis = (e) => {
      e.stopPropagation(); 
        if(e.type === "click"){
          addEvent(userId,"teamTrendingDataAnalysis Clicked!");
        return;
        }
        addEvent(userId,"teamTrendingDataAnalysis Hovered!");
    }
  
    return {
        teamXLScoreContainer,
        showHideTeamMembers,
        teamXLScoreRanceSlider,
        teamHeatMapContainer,
        teamXLFactorsContainer,
        teamFactorsShowHideRange,
        teamFactorsShowHideChange,
        teamFactorsDrivers,
        teamFactorsConstrainers,
        leadershipDiagonsticCenter,
        leadershipDiagonsisFromSheet,
        leadershipPrescriptionContainer,
        leadershipActionChallenges,
        teamHistoryContainer,
        teamHistoryStreamGraphContainer,
        teamHistoryStreamGraph,
        teamEachXLFactorInStreamGraph,
        teamStreamGraphPrevNextButton,
        teamTrendingDataAnalysis,
    };
}


export {useMixpanelEvents};