import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import {useNavigate} from "react-router-dom";

function UserInfo({info,setActivities,setInfo,selectedActivities,handleSubmit,isCommingFromPrePostSurvey,setSections}){

   
    const [isReadytoNext, setIsReadytoNext] = useState(false);
    const loggedInUser = useSelector(state => state.loggedInUser);
    const placeHolder = "Enter activity name";
    // const navigator = useNavigate();
   

    const handleChange =(e) => {
        setInfo(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        });   
    }

    useEffect(()=>{
        let fillCount = 0;

        for (const iterator in info) {
            const act = selectedActivities.find(ac => ac.surveyName === iterator);
            if(act && info[iterator]){
                fillCount++;
            }
        }

        if(fillCount === selectedActivities.length){
            setIsReadytoNext(true);
        }
        else{
            setIsReadytoNext(false);
        }

    },[info,selectedActivities]);


    useEffect(() => {
        setInfo(prev => {
            const temp = {};

            for (const key in prev) {
                const t = loggedInUser.activities?.find(s => s.category === key);
                if(t){
                    temp[key] = t.name;
                }
                else {
                    temp[key] = "";
                }
            }

            return {
                ...prev,
                ...temp
            }
        })
    }, [loggedInUser.activities,setInfo]);


    useEffect(() => {
        setActivities((prev) => {
            return prev.map(act => {
                const cat = loggedInUser.activities.find(a => a.category === act.surveyName);
                return cat? {...act,selected: true}: act;
            })
        });
    }, [loggedInUser.activities,setActivities]);


    const giveInfo = (act) => {
            switch (act.surveyName) {
                case "work":
                    return <div>
                                    My <input type="text" value={info.work} name='work' onChange={handleChange} placeholder={placeHolder}/> Work
                            </div>;
                case "relationship":
                    return <div>
                                    My relationship with <input type="text" value={info.relationship} name='relationship' onChange={handleChange} placeholder={placeHolder}/>
                            </div>;
                case "hobby":
                    return <div>
                                    My <input type="text" value={info.hobby} name='hobby' onChange={handleChange} placeholder={placeHolder}/> Hobby
                            </div>;
                case "study":
                    return <div>
                                    My <input type="text" value={info.study} name='study' onChange={handleChange} placeholder={placeHolder}/> Study
                            </div>;
                case "sport":
                    return <div>
                                    My <input type="text" value={info.sport} name='sport' onChange={handleChange} placeholder={placeHolder}/> Sport
                            </div>;
                case "business":
                    return <div>
                                    My <input type="text" value={info.business} name='business' onChange={handleChange} placeholder={placeHolder}/> Business
                            </div>;
                default:
                    break;
            }
    }


    const handleNext = () => {
        if(isCommingFromPrePostSurvey){
            setSections(prev => {
                return {
                    ...prev,
                    userInfo: false,
                    finalMessage: true
                }
            })
        }
        else {
            handleSubmit();
        }
    }

    return (<>
        <h1 className='text-center pt-10'>XLrate</h1>
        <p className='text-center pb-10'>Enter the specific titles or names of the things you want track and improve.</p>
        

        {/* activities */}
        <div className='flex flex-col items-center gap-10 flex-wrap'>
            {
                selectedActivities.map((act) => {
                    return <div key={act.text}>{giveInfo(act)}</div>;
                })
            }
        </div>

        {/* next btn  */}
        <div className='flex justify-center mt-20'>
            {
                isReadytoNext? <>
                <button onClick={handleNext} className={`text-white bg-green-600 py-2 px-5 rounded-xl`}>Next</button>
                </>:<>
                <button className={`text-white bg-green-600/40 py-2 px-5 rounded-xl`}>Next</button>
                </>
            }
        </div>

    </>)
}



export default UserInfo;