import { useParams } from "react-router-dom";
// import {useMixPanelEvents} from "./useMixPanelEvents";
import { SecondryButton, UI } from "../../../components";
import { useEffect, useState } from "react";
import {motion} from "framer-motion";
import {FACTOR_COLORS} from "../../../constants"

function ActionChallenges({higestDiffrance,currentActionChallengesWithSteps,setSecVis,isACAnimation}){
    const duration = isACAnimation? .5:0;
    const initial = {opacity: 0};
    const animate = {opacity: 1};
    const {userId} = useParams();
    const toolTipText = `Discover the secret to your peak performance! 🚀 Choose from our Action Challenges, specially designed to transform this critical factor and unlock your full potential. Your journey to success starts now. What are you waiting for?`;
    const [showACModel, setShowACModel] = useState(false);
    const [currentActionChallenge, setCurrentActionChallenge] = useState(null);
    const [insDec] = useIsIncreaseOrDicrease();
    // const {
    //     userChooseActionChallengeTooltip,
    //     userChooseActionChallengeHeading,
    //     userActionChallenges
    // } = useMixPanelEvents(userId);
    
    useEffect(()=>{
        const time = setTimeout(() => {
            setSecVis(prev => {
                return {
                    ...prev,
                    streamGraph: true
                }
            })
        }, isACAnimation? 4000:0);

        return () => {
            clearTimeout(time);
        }
    },[setSecVis,isACAnimation])

                             
    return (<>
        <div className="text-[11px] md:text-[14px]">
            <motion.h1 
            initial={initial}
            animate={animate}
            transition={{duration: duration,delay: isACAnimation? duration*0:0}}
            // onClick={userChooseActionChallengeHeading} 
            // onMouseEnter={userChooseActionChallengeHeading} 
            id='dashboard-step-7' 
            className='text-[11px] md:text-[18px] rounded font-bold relative z-30 mb-4 text-secondry-color'>
                Click one Action Challenge now & improve your XL by {insDec(higestDiffrance.factor)} 
                <span id="introSelector10">
                    <span style={{backgroundColor: FACTOR_COLORS[`${higestDiffrance.factor}`.toUpperCase()]}} className="py-1 px-2 rounded-md inline-block mx-1 text-primary-color">{higestDiffrance.factor}</span> 
                    <UI.InfoIconWithToolTip content={toolTipText}/>
                </span>
            </motion.h1>

            <div id='dashboard-step-9' className="flex flex-wrap gap-2 ml-0 md:ml-6">
                {
                    currentActionChallengesWithSteps && currentActionChallengesWithSteps.map((ch,i) => {
                        return <motion.div
                            initial={initial}
                            animate={animate}
                            transition={{duration: duration,delay:isACAnimation? ((duration*1)+i):0}}
                            key={i}
                            className="w-full"
                            id={i === 0? "introSelector11":""}
                            >
                                <SecondryButton
                                    onClick={(e)=>{
                                        // userActionChallenges(e,i);
                                        setCurrentActionChallenge(ch);
                                        setShowACModel(true);
                                    }} 
                                    // onMouseEnter={(e)=> userActionChallenges(e,i)} 
                                    key={i} 
                                    className='text-primary-color w-full capitalize px-3 py-3 rounded-md text-start bg-secondry-color'
                                    >{ch.actionChallenge}
                                </SecondryButton>
                        </motion.div>
                    })
                }
            </div>
        </div>
        <UI.ActionChallengePopup userId={userId} factor={higestDiffrance.factor} currentActionChallenge={currentActionChallenge} setShow={setShowACModel} show={showACModel}/>
    </>)
}

export {ActionChallenges};



function useIsIncreaseOrDicrease(){
    const  incDec = (factor) => {
        return ["Fun","Purpose","Growth"].indexOf(factor) === -1? "decreasing":"increasing"
    }
    
    return  [incDec];
}