import Button  from "./Button";
import ListIcon from "./ListIcon";
import List from "./List";
import {InfoIconWithToolTip,ToolTipInfoIcon,RectTooltip} from "./InfoIconWithToolTip";
import PopUpWraper from "./PopUpWraper";
import ModalHeader from "./ModalHeader";
import ErrorMessage from "./ErrorMessage";
import SurveyUI from "./SurveyUI";
import CrossSVG from "./CrossSVG";
import ProgressBar from "./ProgressBar";
import ActionChallengePopup from "./ActionChallengePopup";
import {PasswordInputWithEye} from "./PasswordInputWithEye";

const components = {
    PasswordInputWithEye,
    ToolTipInfoIcon,
    RectTooltip,
    ActionChallengePopup,
    ProgressBar,
    CrossSVG,
    SurveyUI,
    ErrorMessage,
    ModalHeader,
    PopUpWraper,
    Button,
    ListIcon,
    List,
    InfoIconWithToolTip
}

export default components;