import React, { useState } from 'react';
import {FactorBar} from "./FactorBar";
// import InfoIcon from '../InfoIcon/InfoIcon';
import {FACTOR_COLORS} from "../../constants";
// import {ReactToolTip} from "../index";
import {UI} from "../index";

const driversInfo = "These factors drive the performance of the activity being measured, they range from 0 to 100 and higher scores indicate higher performance potential."
const constrainersInfo = "These factors constrain the performance of the activity being measured, even though they may not be directly connected to it. They range from 0 to -100, and lower scores indicate lower performance potential.";


const factors = {
  drivers: [
    {
      factor: "Fun",
      bg: FACTOR_COLORS["FUN"],
      toolTip: `This indicates how much you are driven by the activity* itself (i.e. when you do in an activity simply because you enjoy it)`
    },
    {
      factor: "Purpose",
      bg: FACTOR_COLORS["PURPOSE"],
      toolTip: `This indicates how much you are driven by the value and impact of  activity* whether you enjoy it or not!`
    },
    {
      factor: "Growth",
      bg: FACTOR_COLORS["GROWTH"],
      toolTip: `This indicates how much you are driven by the self-development value of the activity*`
    }
  ],
  constrainers: [
    {
      factor: "Emotives",
      bg: FACTOR_COLORS["EMOTIVES"],
      toolTip: `This indicates how much you do the activity* because of emotional pressure/s (i.e. guilt, peer pressure, fear, etc.) not because you enjoy or value the activity itself.`
    },
    {
      factor: "Utility",
      bg: FACTOR_COLORS["UTILITY"],
      toolTip: `This indicates how much you do the activity* to gain a reward, or avoid a punishment.`
    },
    {
      factor: "Apathy",
      bg: FACTOR_COLORS["APATHY"],
      toolTip: `A high score here suggests you do not have a strong reason why you're doing the activity*  and tends to result in feelings of suppression, detachment and apathy about the activity.`
    }
  ]
} 



function Factors({Fun,Purpose,Growth,Emotives,Utility,Apathy,factorsMinMax,percentageOfChangeOfFactors,mixPanelEvents}) {
  const [showRange,setShowRange] = useState(false);
  const [showChange,setShowChange] = useState(false);
  
  return (
    <>
        <div className="flex flex-col gap-2 justify-center items-streach w-[100%]">
          <div className="flex justify-between relative z-10">
            {/* show/hide range  */}
            <div onClick={mixPanelEvents.showHideRange} onMouseEnter={mixPanelEvents.showHideRange} className="border px-2 py-1 text-[14px] flex justify-center items-center relative z-10">
                <input
                className="mr-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                type="checkbox"
                role="switch" onChange={()=> setShowRange(p => !p)}/>
                <span className='text-secondry-color' >{showRange? "Hide":"Show"} Range</span>
            </div>

            {/* show/hide change  */}
            <div onClick={mixPanelEvents.showHideChange} onMouseEnter={mixPanelEvents.showHideChange} className="border px-2 py-1 text-[14px] flex justify-center items-center relative z-10">
                <input
                className="mr-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                type="checkbox"
                role="switch" onChange={()=> setShowChange(p => !p)}/>
                <span className='text-secondry-color'>{showChange? "Hide":"Show"} Change</span>
            </div>
          </div>
          <div id="introSelectorFactorsContainer" className="flex flex-col gap-2">
            <Drivers mixPanelEvent={mixPanelEvents.drivers} showChange={showChange} percentageOfChangeOfFactors={percentageOfChangeOfFactors} factorsMinMax={factorsMinMax} Fun={Fun} Purpose={Purpose} Growth={Growth} showRange={showRange}/>
            <Constrainers mixPanelEvent={mixPanelEvents.constrainers} showChange={showChange} percentageOfChangeOfFactors={percentageOfChangeOfFactors} factorsMinMax={factorsMinMax} Emotives={Emotives} Utility={Utility} Apathy={Apathy} showRange={showRange}/>
          </div>
        </div>
    </>
  )
}

export default Factors;


function Drivers({mixPanelEvent,Fun,Purpose,Growth,showRange,factorsMinMax,percentageOfChangeOfFactors,showChange}){
  const facs = [Fun,Purpose,Growth];
  const margin = (factor) => {
    return factor.factor === "Fun"? "12px":factor.factor === "Purpose"? "7px":"";
  }

  return <>
          <div id="introSelectorDrivers" onClick={mixPanelEvent} onMouseEnter={mixPanelEvent} className="flex flex-col items-center border-4 border-secondry-color rounded p-2 relative z-20">
            <p className='text-center mb-0 font-bold pb-2 flex justify-center items-center gap-2 text-accent-color'><span>Drivers</span> <UI.InfoIconWithToolTip content={driversInfo}/></p>
            <div className="flex justify-around w-full">
              {
                  factors.drivers.map((factor,idx) => {
                    const change = percentageOfChangeOfFactors?  percentageOfChangeOfFactors[factor.factor]:0;
                    return <div id={`introSelector${factor.factor}Drivers`} style={{marginLeft: margin(factor)}} key={idx} className="flex flex-col items-center">
                            <div className="flex justify-center gap-9 w-full">
                                <div key={factor.toolTip} className='text-xs flex items-center gap-1 text-secondry-color'>
                                  <span>{factor.factor}</span> 
                                  <UI.InfoIconWithToolTip content={factor.toolTip}/>
                                </div>
                              {/* {
                                factors.drivers.map(factor => (
                                <div key={factor.toolTip} className='text-xs flex gap-1'>
                                  <span>{factor.factor}</span> 
                                  <UI.InfoIconWithToolTip content={factor.toolTip}/>
                                </div>
                                ))
                              } */}
                            </div>
                            <div className='w-[100%] flex justify-center gap-[60px] p-2'>
                              <Factor showChange={showChange} showRange={showRange} toolTip={factor.toolTip} key={factor.factor} factor={factor.factor} value={facs[idx]} bg={factor.bg} isConstrainers={false} min={factorsMinMax.min[factor.factor]} max={factorsMinMax.max[factor.factor]} change={change}/>
                              {/* {
                                factors.drivers.map((fac,i) => {
                                  const change = percentageOfChangeOfFactors?  percentageOfChangeOfFactors[fac.factor]:0;
                                  return <Factor showChange={showChange} showRange={showRange} toolTip={fac.toolTip} key={fac.factor} factor={fac.factor} value={facs[i]} bg={fac.bg} isConstrainers={false} min={factorsMinMax.min[fac.factor]} max={factorsMinMax.max[fac.factor]} change={change}/>
                                })
                              } */}
                            </div>
                          </div>
                  })
              }
            </div>
          </div>
  </>
}


function Constrainers({mixPanelEvent,Emotives,Utility,Apathy,showRange,factorsMinMax,percentageOfChangeOfFactors,showChange}) {

  const facs = [Emotives,Utility,Apathy];

  return <>
          <div id="introSelectorConstrainers" onClick={mixPanelEvent} onMouseEnter={mixPanelEvent}  className="flex flex-col items-center border-4 border-secondry-color rounded p-2 relative z-20">
            
            <div className="flex justify-around w-full">
              {
                  factors.constrainers.map((factor,idx) => {
                    const change = percentageOfChangeOfFactors?  percentageOfChangeOfFactors[factor.factor]:0;
                    return <div id={`introSelector${factor.factor}Constrainers`} key={idx} className="flex flex-col items-center">
                            <div className='w-[100%] flex justify-center gap-[60px] p-2'>
                              <Factor showChange={showChange} showRange={showRange} toolTip={factor.toolTip} key={factor.factor} factor={factor.factor} value={facs[idx]} bg={factor.bg} isConstrainers={true} min={factorsMinMax.min[factor.factor]} max={factorsMinMax.max[factor.factor]} change={change}/>
                            </div>
                            <div className="flex justify-center gap-9 w-full">
                                <div key={factor.toolTip} className='text-xs flex items-center gap-1 text-secondry-color'>
                                  <span>{factor.factor}</span> 
                                  <UI.InfoIconWithToolTip content={factor.toolTip}/>
                                </div>
                            </div>
                          </div>
                  })
              }
            </div>
            
            <p className='text-center mb-0 font-bold pt-2 flex justify-center items-center gap-2 text-accent-color'><span>Constrainers</span> <UI.InfoIconWithToolTip content={constrainersInfo}/></p>
          </div>
  </>
}


function Factor({value,bg,isConstrainers,showRange,min,max,change,showChange}){


  return <>
    <div className="flex flex-col items-center justify-center gap-2">
        {/* {
          !isConstrainers && <div className="flex  items-center gap-1 text-xs justify-center">
                                  <span>{factor}</span> 
                                  <span id={factor} data-tip={toolTip}>
                                    <InfoIcon/>
                                  </span>
                              </div>
        } */}
        
        <div className="h-[134px]">
          <FactorBar
            value={value}
            isReverse={isConstrainers}
            bg={bg}
            showRange={showRange}
            min={min}
            max={max}
            percentageOfchange={change}
            showChange={showChange}
          />
        </div>

        {/* {
          isConstrainers && <div className="flex items-center gap-1 text-xs justify-center">
                                  <span>{factor}</span> 
                                  <span id={factor} data-tip={infoTexts[0]}>
                                    <InfoIcon/>
                                  </span>
                              </div>
        } */}
    </div>
  </>
}



