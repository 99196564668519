import React,{useEffect, useState} from 'react';
import { Link,useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {loginUser,getTodaysUnCompletedSurveyLinkOfUser, afterLoginSuccess} from "../../Store/actions";
import {PrimaryButton, UI} from "../../components";
import { toast } from 'react-toastify';
import {TempMain} from "../../layout";


function Index() {
    const dispatch = useDispatch();
    const previousRoute = useSelector(state => state.previousRoute);
    const navigator = useNavigate();
    const [user, setUser] = useState({email:"",password:"",termAndConditions: true});
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const loggedInUser = useSelector(state => state.loggedInUser);
    

    const handleChange = (e)=>{
        setUser(prev =>{
            if(e.target.name === "termAndConditions"){
                return {
                    ...prev,
                    [e.target.name]: e.target.checked
                }
            }
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    const navigationFunc = async (loggedInUser) => {

        // if onboarding is not completed 
        if(!loggedInUser.isOnboardingDone && loggedInUser.preSurveyId){
            navigator(`/on-boarding`);
            return;
        }

        const res = await getTodaysUnCompletedSurveyLinkOfUser(loggedInUser._id);

        if(res.success && res.surveyLinks.length > 0){
            const url = res.surveyLinks[0].url.replace("https://xlrate.10x.systems","");
            navigator(`${url}`);
            return;
        }

        // previousRoute
        if(previousRoute){
            return navigator(previousRoute);
        }

        if(loggedInUser.activities && loggedInUser.activities.length > 0 && loggedInUser.activities[0].goal?.length > 0){
            navigator(`/score/user/${loggedInUser._id}`);
            return;
        } 
    }

    const handleSubmit = async (e)=>{
        e.preventDefault();
        setIsLoggingIn(true);

        const loginRes = await loginUser(user);

        console.log(loginRes);
        
        if(loginRes.success){
            dispatch(afterLoginSuccess(loginRes));
            return await navigationFunc(loginRes.user);
        }
        else {
            toast.error(loginRes.message);
        }
        setIsLoggingIn(false);
    }

    useEffect(() => {
        // console.log("system");
        const navigationFuncLocal = async (loggedInUser) => {

            // if onboarding is not completed 
            if(!loggedInUser.isOnboardingDone && loggedInUser.isCreatedByAdmin){
                navigator(`/on-boarding`);
                return;
            }
    
            const res = await getTodaysUnCompletedSurveyLinkOfUser(loggedInUser._id);
    
            // console.log(previousRoute);

            if(res.success && res.surveyLinks.length > 0){
                const url = res.surveyLinks[0].url.replace("https://xlrate.10x.systems","");
                if(previousRoute && !previousRoute.includes("/survey")){
                    return navigator(previousRoute);
                }
                return navigator(`${url}`);
            }
    
            // console.log(previousRoute);
            // previousRoute
            // if(previousRoute && !previousRoute.includes("/survey") ){
            if(previousRoute){
                return navigator(previousRoute);
            }
    
            if(loggedInUser.activities && loggedInUser.activities.length > 0 && loggedInUser.activities[0].goal?.length > 0){
                navigator(`/score/user/${loggedInUser._id}`);
                return;
            } 
        }

        if(loggedInUser && !isLoggingIn){
           navigationFuncLocal(loggedInUser);
           return;
        }
    }, [loggedInUser,navigator,isLoggingIn,previousRoute]);

  return (
    <>
        <TempMain>
            <div className='w-full min-h-[calc(100vh-64px)] flex justify-center items-center'>
                <div className='w-full p-6 rounded-lg shadow-lg  md:mt-0 sm:max-w-md sm:p-8'>
                    <h2 className="mb-2 text-3xl font-semibold text-center text-secondry-color">Sign in to your account</h2>
                    <p className="text-sm text-secondry-color mb-6 flex justify-center items-center gap-2">Dont have account?
                                <Link to="/signup" className='focus:underline text-accent-color underline'>
                                    Sign up here
                                </Link>
                    </p>
                    <form noValidate="" action="" className="w-[500px] max-w-full text-secondry-color space-y-8 ng-untouched ng-pristine ng-valid">
                                <div className="space-y-4">
                                    <div className="space-y-2">
                                        <label htmlFor="email" className="block text-sm">Email address</label>
                                        <input value={user.email} onChange={handleChange} type="email" name="email" id="email" placeholder="leroy@jenkins.com" className="w-full px-3 py-2 border rounded-md border-brandbg2 bg-secondry-color/20 text-brandtext1 focus:border-brandbg2" />
                                    </div>
                                    <div className="space-y-2">
                                        <div className="flex justify-between">
                                            <label htmlFor="password" className="text-sm">Password</label>
                                            <Link to="/forget-password" className='text-xs underline text-secondry-color/50'>
                                            Forgot password?
                                            </Link>
                                        </div>
                                        <UI.PasswordInputWithEye value={user.password} onChange={handleChange} name={"password"} id={"password"}/>
                                        {/* <input value={user.password} onChange={handleChange} type="password" name="password" id="password" placeholder="*****" className="w-full px-3 py-2 border rounded-md border-brandbg2 bg-brandbg1 text-brandtext1 focus:border-brandbg2" /> */}
                                    </div>
                                    <div className="space-y-2">
                                        <div className="flex items-start">
                                            <div className="flex items-center h-5">
                                                <input value={user.termAndConditions} checked={true} onChange={handleChange} id="terms" name='termAndConditions' aria-describedby="terms" type="checkbox" className="accent-accent-color w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required=""/>
                                            </div>
                                            <div className="ml-3 text-sm">
                                                <label htmlFor="terms" className="font-light  text-secondry-color">I accept the <Link to="/term-and-conditions" className="font-medium  text-accent-color underline">Terms and Conditions</Link></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <PrimaryButton disabled={!user.termAndConditions}  onClick={handleSubmit}>
                                    Sign in
                                </PrimaryButton>
                    </form>
                </div>
            </div>
        </TempMain>
    </>
  )
}

export default Index;

