import React from 'react';
import {formatPurpose} from "../../../utils/index";


function SecondPage({purpose,setVisivility,currentCategory,currentName}) {
  


  
  return (
    <>
      <h3 className='text-center font-bold'>My purpose for my {currentName} {currentCategory}</h3>
      <p className='text-center mt-5 max-w-[500px] text-[20px] font-bold mx-auto'>{formatPurpose(purpose)}</p>
      <div className="flex justify-center mt-5 gap-10">
        <button onClick={()=>{setVisivility({firstPage:true,secondPage: false})}} className='rounded-md px-4 py-2 bg-blue-600 text-white font-bold'>Back</button>
        <button onClick={()=>{setVisivility({secondPage: false,thirdPage:true})}} className='rounded-md px-4 py-2 bg-blue-600 text-white font-bold'>Next</button>
      </div>
    </>
  )
}

export default SecondPage;