import React from 'react';
import {deleteSurveyTitle} from "../../../Store/actions";
import {confirmAlert} from "react-confirm-alert";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";


function Survey({surveyTitle,handleUpdate,setAllSurveyTitles}) {
  const loggedInUser = useSelector(state => state.loggedInUser);

  const handdleDeleteSurvey = (id)=>{
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            deleteSurveyTitle(id);
            setAllSurveyTitles(null);
          }
        },
        {
          label: 'No',
          onClick: () => {
            //
          }
        }
      ]
    });
    
  }


  return (
    <form>
              <div className="mb-3">
                <h6 className="title"><span>Title</span><div>
                <span className="update-btn" onClick={()=>handleUpdate(surveyTitle?._id)}>Update</span>
                <span className="delete-btn" onClick={()=> handdleDeleteSurvey(surveyTitle?._id)} >Delete</span></div></h6>
                <p name="question" id="question">{surveyTitle?.title}</p>
              </div>
              <div className="mb-3">
                <h6>consentform</h6>
                <p name="question" id="question">{surveyTitle?.consentform}</p>
              </div>
              <div className="mb-3">
                <h6>briefingform</h6>
                <p name="question" id="question">{surveyTitle?.briefingform}</p>
              </div>
              <div className="mb-3">
                <h6>debriefingform</h6>
                <p name="question" id="question">{surveyTitle?.debriefingform}</p>
              </div>
              <div className="mb-3">
                <h6>Email Subject</h6>
                <p name="question" id="question">{surveyTitle.email? surveyTitle.email.subject:''}</p>
              </div>
              <div className="mb-3">
                <h6>Email Body</h6>
                <p name="question" id="question">{surveyTitle.email? surveyTitle.email.body:''}</p>
              </div>
              <div className="mb-3">
                <h6>Submitted Survey Screen</h6>
                <p name="question" id="question">{surveyTitle.submittedsurveyscreen||''}</p>
              </div>
              <div className="mb-3">
                <h6>Survey Question Instruction</h6>
                <br/>Visibility : {surveyTitle.surveyquestioninstruction? surveyTitle.surveyquestioninstruction.isvisible:'false'}
                <p name="question" id="question">{surveyTitle.surveyquestioninstruction? surveyTitle.surveyquestioninstruction.instruction:''}</p>
              </div>
              <div className="mb-3" style={{overflowWrap: "anywhere"}}>
                <h6>Link Preview</h6>
                <Link  to={`/survey/`+ loggedInUser._id + `/`+surveyTitle._id+`/`+new Date().getTime()} >{`${window.location.origin}/survey/`+ loggedInUser._id + `/`+surveyTitle._id+`/`+new Date().getTime()}</Link>
              </div>
              <select className="form-select" id="questio-type" aria-label="Default select example">
                {
                  surveyTitle?.questions?.map((ques,i)=>{
                    return <option key={i} value={ques.XLFactor}>{ques.question}</option>
                  })
                }
              </select>
            </form>
  )
}

export default Survey