import React,{useEffect,useState} from 'react';
import {CompletedSurvey} from "./components";
import {
  getAllCompletedSurveys,
  getAllEventsOfUser,
  getAllGroups,
  getAllSurveyTitles,
  getAllUsersSurnameForename
} from "../../Store/actions";
import { toast } from 'react-toastify';
import {Loading, Td} from "../../components"; 
import {Thead, Table, Tbody, Th, Tr} from "../../components"; 
import {XL_FACTORS} from "../../constants";
import {Main} from "../../layout";
import moment from 'moment';
import { CSVLink } from "react-csv";
import { allSurveysWithXLScoresToHistoricalSPQ, mapper__100_100_to_0_100 } from '../../utils';

const tableHeaders = [
    "#",
    "User Name",
    "User Email",
    "Org.",
    "Dept.",
    "Group",
    "S. Title",
    "User Activity Name",
    "User Activity Title",
    "Date",
    "Time",
    "Survey Dur",
    ...XL_FACTORS,
    "XL Score",
    "XL Score (0-100)",
    "Lowest XL",
    "Highest XL",
    "Event",
    "XlChange",
    "Exclude",
    "Mood"
];

const sdTableHeader = [
    "#",
    "User Name",
    "User Email",
    "Org.",
    "Dept.",
    "Group",
    "S. Title",
    "User Activity Name",
    "User Activity Title",
    "Date",
    "Time",
    "XL SD (last 7 days)",
]


function Index() {

  const initObj = {
    userSearch: "",
    userId: null,
    surveySearch: "",
    surveyId: null,
    groupSearch: "",
    page: 1,
    groupId: null,
    showAll: false,
    showExcluded: false
  }
  const [searchTerms,setSearchTerms] = useState(initObj);
  const [completedSurveys,setCompletedSurveys] = useState(null);
  const [historicalOPQ,setHistoricalOPQ] = useState(null);
  const [users,setUsers] = useState([]);
  const [loading,setLoading] = useState(true);
  const [surveyFound,setSurveyFound] = useState(false);
  const [excludedFields,setExcludedFields] = useState([]);
  const [groups,setGroups] = useState([]);
  const [surverTitles,setSurverTitles] = useState([]);
  const [isShowData,setIsShowData] = useState(false);
  const [paginationData,setPaginationData] = useState(null);
  const [isChangingPage,setIsChangingPage] = useState(false);
  const headerStr = JSON.stringify(tableHeaders.filter((item,i) => excludedFields.indexOf(i+1) === -1));
  const bodyStr = completedSurveys? JSON.stringify(
    completedSurveys.map((survey,i) => {
      const group = groups.find(gp => {
        const user = gp?.users?.find(user => user._id === survey?.user?._id);
        if(user){
          return gp
        }
        return undefined 
      })

      const arr = [
        i+1,
      `${survey?.user?.forename}${survey?.user?.surname}`,
      survey?.user?.email,
      "org",
      "dept",
      group?.groupname || "NA",
      survey?.surveytitle?.title,
      survey.surveyName,
      survey?.user?.activities.find(ac => ac.category === survey.surveyName)?.name || "",
      new Date(survey?.createdAt).toLocaleString(),
      ...XL_FACTORS.map(XLFactor => {
          const ans = survey[XLFactor]
          if(!ans){
            return "NA"
          }
          return mapRange(ans.rating.$numberDecimal,1,7,0,100)
      })
      ]

      return arr.filter((item,i) => excludedFields.indexOf(i+1) === -1);
    })
  ):"";
  const csvData = prepareCSVData(completedSurveys,groups);
  const csvDataOfSD = prepareCSVData1(historicalOPQ,groups);
  const [eventCSVData, seteventCSVData] = useState(null);


  // console.log(Math.ceil(paginationData.totalDocuments/paginationData.limit));
 
  function mapRange(value, inMin, inMax, outMin, outMax) {
    const ans = ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
    return ans.toFixed(2)
  }

  const handleInclueExcludeNumber = (number) => {
    setExcludedFields(prev => {
      const isExist = prev.find(n => n === number)
      if(isExist){
        return prev.filter(n => n !== number);
      }
      return [...prev,number]
    })
  }

  const  downloadTxtFile = ()=> {
    const text = `${headerStr}${bodyStr}`;
    const filename = "sample_file.txt";
  
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);
  
    element.style.display = "none";
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
  }

  const loadMore = async (e) => {
    let page = Number(searchTerms.page)+1;

    let query = ``;

    if(searchTerms.userId){
      query += `user=${searchTerms.userId}`;
    }
    if(searchTerms.surveyId){
      query += `&&surveytitle=${searchTerms.surveyId}`;
    }
    if(searchTerms.groupId){
      query += `&&groupId=${searchTerms.groupId}`;
    }

    if(searchTerms.groupId || searchTerms.surveyId || searchTerms.userId){
      query += `&&showAll=${true}`;
    }
    else {
      query += `&&showAll=${searchTerms.showAll}`;
    }

    query += `&&isexcluded=${searchTerms.showExcluded}&&page=${page}`;
    setIsChangingPage(true);
    const res = await getAllCompletedSurveys(query);
    setIsChangingPage(false);
    
    if(res.success){
        const temp = res.surveys.sort((a,b)=> new Date(b.createdAt).getTime()-new Date(a.createdAt).getTime());
        const sortedSurveys = temp.map(item => {
          const createdAt = moment(item.createdAt).startOf("day");
          const event = res.events.find(event => event.user === item.user?._id && createdAt.isSame(moment(event.date).startOf("day")));
          if(event){
            item.event = event;
          }
          return item;
        });
        setCompletedSurveys((prev)=> {
          return [...prev,...sortedSurveys]
        });
        const temp1 = sortedSurveys.reduce((a,b) => {
          const t = {createdAt: b.createdAt,XLScore: b.lxscore,data: b};
          if(a[b.user._id]){
            a[b.user._id].push(t);
          }
          else {
            a[b.user._id] = [t]; 
          }
          return a; 
        },{});
        let sds = [];
        for (const key in temp1) {
          const sd = allSurveysWithXLScoresToHistoricalSPQ(temp1[key]);
          const t = sd.map(item => {return {...item,data:temp1[key][0].data}})
          sds = [...sds,...t];
        }
        setHistoricalOPQ(prev => {
          return [...prev,...sds]
        });
        setSearchTerms(prev => {
          return {
            ...prev,
            page: res.page
          }
        });
        setPaginationData({
          page: res.page,
          totalDocuments: res.totalDocuments,
          limit: res.limit,
        });
    }
    else {
      toast.error("Someting Went Wrong!")
    }
  }

  const handleDownloadEvents = async () => {
    const res = await getAllEventsOfUser(searchTerms.userId);
    // console.log(res);
    if(res.success){
      const csvData = prepareCSVData2(res.events);
      seteventCSVData(csvData);
    }
    else {
      toast.error(res.message);
    }
  }

  useEffect(() => {
      (async ()=>{
        setLoading(true);
        const res = await getAllCompletedSurveys("showAll=false&&isexcluded=false");
      
        setLoading(false);
        if(res.success){
          const temp = res.surveys.sort((a,b)=> new Date(b.createdAt).getTime()-new Date(a.createdAt).getTime());
          const sortedSurveys = temp.map(item => {
            const createdAt = moment(item.createdAt).startOf("day");
            const event = res.events.find(event => event.user === item.user?._id && createdAt.isSame(moment(event.date).startOf("day")));
            if(event){
              item.event = event;
            }
            return item;
          });
          setCompletedSurveys(sortedSurveys);
          setSurveyFound(sortedSurveys.length > 0? true:false);
          const temp1 = sortedSurveys.reduce((a,b) => {
            const t = {createdAt: b.createdAt,XLScore: b.lxscore,data: b};
            if(b.user){
              if(a[b.user._id]){
                a[b.user._id].push(t);
              }
              else {
                a[b.user._id] = [t]; 
              }
            }
            return a; 
          },{});
          let sds = [];
          for (const key in temp1) {
            const sd = allSurveysWithXLScoresToHistoricalSPQ(temp1[key]);
            const t = sd.map(item => {return {...item,data:temp1[key][0].data}})
            sds = [...sds,...t];
          }
          setHistoricalOPQ(sds);
          setPaginationData({
            page: res.page,
            totalDocuments: res.totalDocuments,
            limit: res.limit,
          });
        }
        else{
          toast.error("Someting Went Wrong!")
        }
        
      })()
  }, []);

  useEffect(() => {
    (async ()=> {
      const res = await getAllUsersSurnameForename();
      if(res.success){
        setUsers(res.users);
      }
      const res1 = await getAllGroups();
      if(res1.success){
        setGroups(res1.groups);
      }
      const res2 = await getAllSurveyTitles();
      if(res2.success){
        setSurverTitles(res2.surveyTitles);
      }
    })()
  }, []);

  if(loading){
     return <Loading/>
  }
  
  return (
    <>
    <Main containerClasses={"bg-white"}>
        <FilterOptions searchTerms={searchTerms} setPaginationData={setPaginationData} setSearchTerms={setSearchTerms} setLoading={setLoading} setHistoricalOPQ={setHistoricalOPQ} setCompletedSurveys={setCompletedSurveys} setSurveyFound={setSurveyFound} users={users} groups={groups} surverTitles={surverTitles}/>
        
        <div className="flex gap-3 flex-wrap">
          {
            bodyStr !== "[]" && <button onClick={downloadTxtFile} className='bg-blue-600 text-white rounded-md px-4 py-1'>Download .txt File</button>
          }
          {
            (bodyStr !== "[]" && csvData) && <button className='bg-blue-600 text-white rounded-md px-4 py-1'><CSVLink data={csvData} className='text-white no-underline'>Download .csv File</CSVLink></button>
          }
          {
            bodyStr !== "[]" && <button onClick={()=> setIsShowData(p => !p)} className='bg-blue-600 text-white rounded-md px-4 py-1'>{isShowData? "Hide":"Show"} Data</button>
          }
          {
            (csvDataOfSD?.length > 9 && csvDataOfSD) && <button className='bg-blue-600 text-white rounded-md px-4 py-1'><CSVLink data={csvDataOfSD} className='text-white no-underline'>Download SD Data</CSVLink></button>
          }
          {
            searchTerms.userId && <button onClick={handleDownloadEvents} className='bg-blue-600 text-white rounded-md px-4 py-1'>Fetch Events Data</button>
          }
          {
            eventCSVData && <button className='bg-blue-600 text-white rounded-md px-4 py-1'><CSVLink data={eventCSVData} className='text-white no-underline'>Download Event Data</CSVLink></button>
          }
        </div>
        
        <div className="">
          {
            (bodyStr !== "[]" && isShowData) && <>
              <div className="mt-5 flex flex-wrap gap-2">
                      {
                        tableHeaders.map((val,i) => (
                          <span  onClick={()=>handleInclueExcludeNumber(i+1)} style={{backgroundColor: excludedFields.indexOf(i+1) === -1? "":"red"}}  className='cursor-pointer bg-blue-900 text-white rounded-md p-1 text-[10px]'>{val}</span>
                        ))
                      }  
              </div>
              { headerStr }
              { bodyStr }
            </>
          }
        </div>
        
        {
          !surveyFound && <SomethingNotFound someting={"Surveys"}/>
        }
        
        {
          (!loading && surveyFound)  &&  <> 
            <div className="w-full mt-5">
              <Table >
                  <Thead>
                    <Tr>
                      {
                        tableHeaders.map((th,idx) => <Th key={idx}>{th}</Th>)
                      }
                    </Tr>
                  </Thead>
                  <Tbody>
                      {
                        completedSurveys && completedSurveys.map((item,index)=> {
                            const group = groups?.find(gp => {
                                                                const user = gp.users?.find(user => user._id === item?.user?._id);
                                                                if(user){
                                                                  return gp
                                                                }
                                                                return undefined 
                            })

                            return <CompletedSurvey 
                            survey={item} 
                            key={index} 
                            index={index}
                            setCompletedSurveys={setCompletedSurveys}
                            group={group}
                            />
                        })
                      }
                  </Tbody>
              </Table>

              <div className="mt-3 w-full flex flex-col justify-center items-center gap-2">
                <p>{((searchTerms.page*paginationData.limit) > paginationData.totalDocuments? paginationData.totalDocuments:(searchTerms.page*paginationData.limit))}/{paginationData.totalDocuments}</p>
                <div className="flex items-center gap-2 mt-1">
                    <button disabled={searchTerms.page < Math.ceil(paginationData.totalDocuments/paginationData.limit)?false:true} onClick={loadMore} id='plusBtn' className='bg-blue-600 text-white rounded-md px-4 py-1 disabled:bg-blue-600/50 disabled:cursor-not-allowed'>{isChangingPage? "Loading...":"Load More"}</button>
                </div>
              </div>
            </div>
          </>
        }

        {
          (!loading && surveyFound)  &&  <> 
          <div className="w-full mt-5">
            <Table >
                <Thead>
                  <Tr>
                    {
                      sdTableHeader.map((th,idx) => <Th key={idx}>{th}</Th>)
                    }
                  </Tr>
                </Thead>
                <Tbody>
                    {
                      historicalOPQ.map((item,idx) => {
                        const group = groups?.find(gp => {
                            const user = gp.users?.find(user => user._id === item.data?.user?._id);
                            if(user){
                              return gp
                            }
                            return undefined 
                        })
                        const survey = item.data;

                        return <Tr key={idx}>
                                  <Td className="pl-4">{idx + 1}</Td>

                                  <Td>{survey?.user?.forename}{` `}{survey?.user?.surname}</Td>

                                  <Td className="text-muted">{survey?.user?.email}</Td>

                                  <Td className="text-muted">org</Td>

                                  <Td className="text-muted">dept</Td>

                                  <Td>
                                    <select
                                      className="form-control category-select"
                                      id="exampleFormControlSelect1"
                                    >
                                      <option>{group?.groupname}</option>
                                    </select>
                                  </Td>

                                  <Td className="text-muted">{survey.surveyName ? `${survey?.surveytitle?.title} ${survey.surveyName}`:survey?.surveytitle?.title}</Td>

                                  <Td className="text-muted">{new Date(item.date).toLocaleString().split(",")[0]}</Td>

                                  <Td className="text-muted">{new Date(item.date).toLocaleString().split(",")[1]}</Td>

                                  <Td className="text-muted">{item.OPQ}</Td>
                         </Tr>
                      })
                    }
                </Tbody>
            </Table>
          </div>
        </>
        }
    </Main>
    </>
  )
}

export default Index;



function FilterOptions({setLoading,setPaginationData,setCompletedSurveys,searchTerms,setSearchTerms,setHistoricalOPQ,setSurveyFound,users,groups,surverTitles}){
  
  
  const extrectAllUserIds = (group) => {
    let userIds = "";
    visit(group);
    function visit(node){
      const temp = node.users?.map(u => u._id);
      userIds = [...userIds,...temp];
      
      if(node.children){
        node.children.forEach(child => {
          const foundGroup = groups.find(g => g._id === child._id);
          if(foundGroup){
            visit(foundGroup);
          }
        })
      }
    }
    return userIds.join(",");
  }

  const handleChaange = (e) => {

    setSearchTerms(prev => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })

  }

  const handleSelect = (type,payload) => {
    if(type === "user"){
      setSearchTerms(prev => {
        return {
          ...prev,
          userSearch: `${payload.forename} ${payload.surname}`,
          userId: payload._id
        }
      })
    }
    if(type === "survey"){
      setSearchTerms(prev => {
        return {
          ...prev,
          surveySearch: payload.title,
          surveyId: payload._id
        }
      })
    }
    if(type === "group"){
      setSearchTerms(prev => {
        return {
          ...prev,
          groupSearch: payload.groupname,
          groupId: extrectAllUserIds(payload)
        }
      })
    }
    if(type === "excluded"){
      setSearchTerms(prev => {
        return {
          ...prev,
          showExcluded: payload.target.checked
        }
      });
    }
    if(type === "all"){
      setSearchTerms(prev => {
        return {
          ...prev,
          showAll: payload.target.checked
        }
      });
    }
  }

  const handleSearch = async () => {
    let query = ``;

    if(searchTerms.userId){
      query += `user=${searchTerms.userId}`;
    }
    if(searchTerms.surveyId){
      query += `&&surveytitle=${searchTerms.surveyId}`;
    }
    if(searchTerms.groupId){
      query += `&&groupId=${searchTerms.groupId}`;
    }

    if(searchTerms.groupId || searchTerms.surveyId || searchTerms.userId){
      query += `&&showAll=${true}`;
    }
    else {
      query += `&&showAll=${searchTerms.showAll}`;
    }

    query += `&&isexcluded=${searchTerms.showExcluded}`;

    setLoading(true);

    const res = await getAllCompletedSurveys(query);
    
    setLoading(false);

    if(res.success){
        const temp = res.surveys.sort((a,b)=> new Date(b.createdAt).getTime()-new Date(a.createdAt).getTime());
        const sortedSurveys = temp.map(item => {
          const createdAt = moment(item.createdAt).startOf("day");
          const event = res.events.find(event => event.user === item.user?._id && createdAt.isSame(moment(event.date).startOf("day")));
          if(event){
            item.event = event;
          }
          return item;
        });
        setCompletedSurveys(sortedSurveys);
        setSurveyFound(sortedSurveys.length > 0? true:false);
        const temp1 = sortedSurveys.reduce((a,b) => {
          const t = {createdAt: b.createdAt,XLScore: b.lxscore,data: b};
          if(a[b.user._id]){
            a[b.user._id].push(t);
          }
          else {
            a[b.user._id] = [t]; 
          }
          return a; 
        },{});
        let sds = [];
        for (const key in temp1) {
          const sd = allSurveysWithXLScoresToHistoricalSPQ(temp1[key]);
          const t = sd.map(item => {return {...item,data:temp1[key][0].data}})
          sds = [...sds,...t];
        }
        setHistoricalOPQ(sds);
        setSearchTerms(prev => {
          return {
            ...prev,
            page: 1
          }
        });
        setPaginationData({
          page: res.page,
          totalDocuments: res.totalDocuments,
          limit: res.limit,
        });
    }
    else {
      toast.error("Someting Went Wrong!")
    }
  }


  
  

  return (
    <>
     <div className='py-10'>
        <div className="flex gap-10 items-center flex-wrap">
            {/* <div className="block min-h-6 pl-7">
              <label className='flex items-center gap-3'>
                <input checked={searchTerms.showAll} onChange={(e)=>handleSelect("all",e)} id="checkbox-1" className="w-4 h-4 ease-soft text-base -ml-7 rounded-1.4" type="checkbox" />
                <label htmlFor="checkbox-1" className="cursor-pointer select-none text-slate-700">Show All</label>
              </label>
            </div> */}
            <div className="block min-h-6 pl-7 relative">
              <label className='flex items-center gap-3'>
                <input checked={searchTerms.showExcluded} onChange={(e)=>handleSelect("excluded",e)} id="checkbox-1" className="w-4 h-4 ease-soft text-base -ml-7 rounded-1.4" type="checkbox" />
                <label htmlFor="checkbox-1" className="cursor-pointer select-none text-slate-700">Show Excluded</label>
              </label>
            </div>
            <div className="block min-h-6 pl-7">
              <input value={searchTerms.userSearch} onChange={handleChaange} type="search" name='userSearch' id="userSearch" className="block w-full p-4 py-1 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 " placeholder="Search User.." />
              {
                (!searchTerms.userId && searchTerms.userSearch) && <div className="absolute bg-white w-[198px] rounded-md p-2">
                                    {
                                      users.filter(user => `${user.forename} ${user.surname}`.toLowerCase().includes(searchTerms.userSearch.toLowerCase())).map((user,idx) => (
                                        <p key={idx} onClick={()=> handleSelect('user',user)} className='cursor-pointer'>{user.forename} {user.surname}</p>
                                      ))
                                    }
                                  </div>
              }
            </div>
            <div className="block min-h-6 pl-7 relative">
                <input value={searchTerms.surveySearch} onChange={handleChaange} name='surveySearch' type="search" id="surveySearch" className="block w-full p-4 py-1 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 " placeholder="Search Survey.." />
                  {
                    (!searchTerms.surveyId && searchTerms.surveySearch) && <div className="absolute bg-white w-[198px] rounded-md p-2">
                                        {
                                          surverTitles.filter(survey => `${survey.title}`.toLowerCase().includes(searchTerms.surveySearch.toLowerCase())).map(survey => (
                                            <p onClick={()=> handleSelect("survey",survey)} className='cursor-pointer'>{survey.title}</p>
                                          ))
                                        }
                                      </div>
                  }
            </div>
            <div className="block min-h-6 pl-7">
              <input value={searchTerms.groupSearch} onChange={handleChaange} name='groupSearch'  type="search" id="groupSearch" className="block w-full p-4 py-1 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 " placeholder="Search Group.." />
              {
                (!searchTerms.groupId && searchTerms.groupSearch) && <div className="absolute bg-white w-[198px] rounded-md p-2">
                                    {
                                      groups.filter(group => `${group.groupname}`.toLowerCase().includes(searchTerms.groupSearch.toLowerCase())).map(group => (
                                        <p onClick={()=> handleSelect("group",group)} className='cursor-pointer'>{group.groupname}</p>
                                      ))
                                    }
                                  </div>
              }
            </div>
            <div className="block min-h-6 pl-7">
              <button onClick={handleSearch} type='search' className='bg-indigo-800 text-white px-3 py-1 rounded-md'>Search</button>
            </div>
        </div>
     </div>
    </>
  )
}

function SomethingNotFound({someting}){
  return (
    <>
    <div className="flex justify-center items-center">
    <h5>{someting} Not Found!</h5>
    </div>
    </>
  )
}

function prepareCSVData(completedSurveys,groups){

  const calcullateTotalDuration = (survey)=> {
    let totalTime = 0;

    for (const key in survey) {
      if(XL_FACTORS.indexOf(key) !== -1){
          const ans = survey[key];
          totalTime += (ans.endtime - ans.starttime); 
      }
    }
 
    return parseFloat((totalTime/1000)/60).toFixed(2) + " Mins";
  }

  const mapRange =  (value, inMin, inMax, outMin, outMax)=> {
    const ans = ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
    return ans.toFixed(2)
  }

  if(!completedSurveys){
    return null;
  }

  const array = [[...tableHeaders,"Survey Name"]];

  completedSurveys.forEach((survey,i) => {
    const group = groups?.find(gp => {
      const user = gp.users?.find(user => user._id === survey?.user?._id);
      if(user){
        return gp
      }
      return undefined 
    })
    const duration = calcullateTotalDuration(survey);
    const factors = XL_FACTORS.map(XLFactor => {
      return survey[XLFactor] ? mapRange(survey[XLFactor].rating.$numberDecimal,1,7,0,100) : "NA";
    });
    const eventTags = survey?.event?.tags?.map(tag => tag);
    const eventFeelings = survey?.event?.feelings?.map(tag => tag);
  
    const arr = [
      i+1,
      `${survey.user?.forename} ${survey.user.surname}`,
      survey.user?.email,
      survey.user?.organizationName,  
      survey.user?.departent,  
      group?.groupname,  
      survey?.surveytitle?.title,
      survey.surveyName,
      survey?.user?.activities.find(ac => ac.category === survey.surveyName)?.name || "",  
      new Date(survey?.createdAt).toLocaleString().split(",")[0],  
      new Date(survey?.createdAt).toLocaleString().split(",")[1].trim(),  
      duration,
      ...factors,
      survey.lxscore,
      mapper__100_100_to_0_100(survey?.lxscore).toFixed(2),
      survey.minLXScore,  
      survey.maxLXScore,
      `${survey?.event?.description} ${eventTags?.join(",")}`,
      survey?.xlChangeFromLastScore,
      survey?.isexcluded,
      eventFeelings?.join(","),
      survey.surveyName 
    ]

    array.push(arr);
  })

  return array;
}

function prepareCSVData1(completedSurveys,groups){


  if(!completedSurveys){
    return null;
  }

  const array = [sdTableHeader];

  completedSurveys.forEach((item,i) => {
    const survey = item.data;
    const group = groups?.find(gp => {
      const user = gp.users?.find(user => user._id === survey?.user?._id);
      if(user){
        return gp
      }
      return undefined 
    })

    const arr = [
      i+1,
      `${survey.user?.forename} ${survey.user.surname}`,
      survey.user?.email,
      survey.user?.organizationName,  
      survey.user?.departent,  
      group?.groupname,  
      survey?.surveytitle?.title,  
      new Date(item.date).toLocaleString().split(",")[0],  
      new Date(item.date).toLocaleString().split(",")[1].trim(),  
      Number(item.OPQ)
    ]

    array.push(arr);
  })

  return array;
}

function prepareCSVData2(events){


  const array = [[
    "#",
    "email",
    "Date",
    "Time",
    "Description",
    "SurveyName",
    "Tags",
    "Event Date",
    "Event Time",
    "Feelings"
  ]];

  events.forEach((item,i) => {

    const eventTags = item?.tags?.map(tag => tag);
    const eventFeelings = item?.feelings?.map(tag => tag);

    const arr = [
      i+1,
      item.user?.email,
      new Date(item.createdAt).toLocaleString().split(",")[0],
      new Date(item.createdAt).toLocaleString().split(",")[1].trim(),
      item.description,
      item.surveyName,
      eventTags.join(","),
      new Date(item.date).toLocaleString().split(",")[0],
      new Date(item.date).toLocaleString().split(",")[1].trim(),
      eventFeelings.join(",")
    ]

    array.push(arr);
  });

  return array;
}


