import React,{useEffect,useState} from 'react';
import { UI} from "../../components";
import {useDispatch} from "react-redux";
import {
  getAllUsers,
  updateUser,
  getUserById,
  deleteUser,
  getAllSurveyTitles
} from "../../Store/actions";
import { toast } from 'react-toastify';
import {Main} from "../../layout"
import { confirmAlert } from 'react-confirm-alert';
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { ACTIVITIES, GENDER_IDENTITIES } from '../../constants';




function Index() {
  
  const dispatch = useDispatch();
  const [toggleupdateForm, setToggleupdateForm] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    forename: "",
    surname: "",
    role: "",
    dob: "",
    profession: ""
  });
  const [allUsers, setAllUsers] = useState([]);

  // _id for update resources 
  const [_id, set_id] = useState('');


  // update user 
  const handdleUpdateUser = async(id)=> {
    set_id(id);
    const res = await getUserById(id);
    // console.log(res.user);
    setFormData(prev => {
        return {
        dob: res.user.dob ,
        profession: res.user.profession || "",
        title: res.user.title || "",
        middleNames: res.user.middleNames || "",
        surname: res.user.surname || "",
        forename: res.user.forename || "",
        email: res.user.email,
        userName: res.user.userName || "",
        gender: res.user.gender || "",
        organizationName: res.user.organizationName || "",
        department: res.user.department || "",
        team: res.user.team || "",
        role: res.user.role,
        rank: res.user.rank || "",
        className: res.user.className || "",
        level: res.user.level || "",
        studentNumber: res.user.studentNumber || "",
        mobilePhoneNumber: res.user.mobilePhoneNumber || "",
        landlineNumber: res.user.landlineNumber || "",
        address: res.user.address || "",
        unRegularSurveys: res.user.unRegularSurveys || "",
        activities: res.user.activities || "",
      }
    })
    setToggleupdateForm(true);
  }

  const handdleUpdateSubmit = async ()=> {
    const res = await updateUser(_id,formData);

    if(res.success){
      toast.success(res.message);
      const users = allUsers.map(user => user._id === _id? {...user,...res.user}:user);
      setAllUsers(users);
      setToggleupdateForm(false);
    }
    else {
      toast.error(res.message);
    }
  }

 
  useEffect(() => {
      async function fetchData() {
        const usersRes = await getAllUsers();
        setAllUsers(usersRes.users);

        // const groupsRes = await getAllGroups();
        // if(groupsRes.success){
        //   setAllGroups(groupsRes.groups);
        // }
      }
      fetchData();
  }, [dispatch]);


  return (
    <>
    <Main containerClasses={"bg-white"}>
        <h2 className="text-gray-600 text-md text-center">Manage Users</h2>

        {
          allUsers && <UsersTable 
                        users={allUsers} 
                        setAllUsers={setAllUsers} 
                        handdleUpdateUser={handdleUpdateUser}
                      />
        }
    </Main>

    {
      toggleupdateForm && <UpdateUserModel handdleUpdateSubmit={handdleUpdateSubmit}  formData={formData} setFormData={setFormData} setIsOpen={setToggleupdateForm}/>
    }
    </>
  )
}

export default Index;



function UsersTable({users,setAllUsers,handdleUpdateUser}){


  return (
    <div className="flex flex-col gap-3">
      {
          users.map((user,idx) => (
            <div key={idx} className="pb-4 flex flex-wrap border-b">
                {
                    ["Dob","Profession","Title","MiddleNames","Surname","Forename","Email","User Name","Gender","Organisation","Department","Team","Role","Rank","Class Name","Level","Student Number","Mobile Phone Number","Landline Number","Address","No. Survey User","Personal Surveys","Manager Groups/Teams","Received Onboarding Email","On Boarding Status","Dashboard Tour Status","Actions"].map((item,idx1) => (
                      <div key={idx1} className="p-2 px-3 bg-gray-50 mb-1">
                          <p className="mb-1 font-bold text-sm text-gray-400 whitespace-nowrap">{item}</p>
                          {
                            item === "Profession" && <OneLineField user={user} fieldName="profession"/>
                          }
                          {
                            item === "Dob" && <OneLineField user={user} fieldName="dob"/>
                          }
                          {
                            item === "Title" && <OneLineField user={user} fieldName="title"/>
                          }
                          {
                            item === "MiddleNames" && <OneLineField user={user} fieldName="middleNames"/>
                          }
                          {
                            item === "Surname" && <OneLineField user={user} fieldName="surname"/>
                          }
                          {
                            item === "Forename" && <OneLineField user={user} fieldName="forename"/>
                          }
                          {
                            item === "Email" && <OneLineField user={user} fieldName="email"/>
                          }
                          {
                            item === "User Name" && <OneLineField user={user} fieldName="userName"/>
                          }
                          {
                            item === "Gender" && <OneLineField user={user} fieldName="gender"/>
                          }
                          {
                            item === "Organisation" && <OneLineField user={user} fieldName="organizationName"/>
                          }
                          {
                            item === "Department" && <OneLineField user={user} fieldName="department"/>
                          }
                          {
                            item === "Team" && <OneLineField user={user} fieldName="team"/>
                          }
                          {
                            item === "Role" && <OneLineField user={user} fieldName="role"/>
                          }
                          {
                            item === "Rank" && <OneLineField user={user} fieldName="rank"/>
                          }
                          {
                            item === "Class Name" && <OneLineField user={user} fieldName="className"/>
                          }
                          {
                            item === "Level" && <OneLineField user={user} fieldName="level"/>
                          }
                          {
                            item === "Student Number" && <OneLineField user={user} fieldName="studentNumber"/>
                          }
                          {
                            item === "Mobile Phone Number" && <OneLineField user={user} fieldName="mobilePhoneNumber"/>
                          }
                          {
                            item === "Landline Number" && <OneLineField user={user} fieldName="landlineNumber"/>
                          }
                          {
                            item === "Address" && <OneLineField user={user} fieldName="address"/>
                          }
                          {
                            item === "No. Survey User" && (user?.activities?.length || <EmptyField/>)
                          }
                          {
                            item === "Personal Surveys" && <PersonalSurveys user={user}/>
                          }
                          {
                            item === "Manager Groups/Teams" && <ManagerGroupsOrTeams user={user}/>
                          }
                          {
                            item === "On Boarding Status" && <OnBoardingStatus user={user}/>
                          }
                          {
                            item === "Received Onboarding Email" && <OnboardingEmailReceived user={user}/>
                          }
                          {
                            item === "Dashboard Tour Status" && <DashboardStatus user={user}/>
                          }
                          {
                            item === "Actions" && <Actions user={user} setAllUsers={setAllUsers} handdleUpdateUser={handdleUpdateUser}/>
                          }
                      </div>
                    ))
                }
            </div>
          )) 
      }
    </div>
  )
}


function Actions({user,setAllUsers,handdleUpdateUser}){

  const handdleDeleteUser =(id)=> {
    confirmAlert({
      title: 'Confirm to Delete',
      message: `Are you sure to delete ${user.email}`,
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            const res = await deleteUser(id);
            if(res.success){
              toast.success(res.message);
              setAllUsers(prev =>{
                const users = prev.filter(user => user._id !== id);
                return users;
              })
            }
          }
        },
        {
          label: 'No',
          onClick: () => {
            //
          }
        }
      ],
      overlayClassName: "bg-red-200"
    });
  }

  return (
    <>
      {/* <UI.Button onClick={()=>handdleAssignRemoveUser(user?._id)} className="text-white bg-blue-400  px-2 py-1">Assign/Remove Personal Surveys</UI.Button>
      <UI.Button onClick={()=>handdleAssignRemoveManagementGroups(user?._id)} className="text-white ml-2 bg-blue-400  px-2 py-1">Assign/Remove Manager Groups/Teams</UI.Button> */}
      <UI.Button className="text-white ml-2 bg-blue-400  px-2 py-1">Start Access</UI.Button>
      <UI.Button className="text-white ml-2 bg-blue-400  px-2 py-1">Stop Access</UI.Button>
      <UI.Button onClick={() => handdleUpdateUser(user._id)} className="text-white ml-2 bg-yellow-400 px-2 py-1">Edit User</UI.Button>
      <UI.Button onClick={()=>  handdleDeleteUser(user._id)} className="mt-1 md:mt-0 text-white ml-2 bg-red-400  px-2 py-1">Delete User</UI.Button>
    </>
  )
}


function DashboardStatus({user}){


  return (
    <>
              {
                user.isDashboardTutorialFinished && <p className='px-3 py-2 rounded-full font-semibold text-xs bg-green-50 text-green-600 text-center rounded-md mb-0'>Finished</p>
              }
              {
                !user.isDashboardTutorialFinished && <p className='px-3 py-2 rounded-full font-semibold text-xs bg-red-50 text-red-600 text-center rounded-md mb-0'>Not Finished</p>
              }
    </>
  )
}

function OnBoardingStatus({user}){

  const [isShowMore, setIsShowMore] = useState(false);
  const steps = [
    {
      placeholder: "Create Password",
      name: "hasCreatedNewPassword",
    },
    {
      placeholder: "1st Login",
      name: "hasCompletedFirstLogin",
    },
    {
      placeholder: "Welcome Screen",
      name: "isIntroSeen",
    },
    {
      placeholder: "Pre XLRate Survey",
      name: "isPreSurveyDone",
    },
    {
      placeholder: "Intro to XLRate videos",
      name: "isWelcomeSeen",
    },
    {
      placeholder: "Set goals",
      name: "isPersonalImprovementGoalsSeen",
    },
    {
      placeholder: "Obstacles",
      name: "isPersonalImprovementObstaclesSeen",
    },
    {
      placeholder: "Final projection",
      name: "isFinalProjectionSeen",
    },
    {
      placeholder: "Enter title activity",
      name: "isEnterActivityTitleSeen",
    },
    {
      placeholder: "1st Survey",
      name: "hasCompletedFirstSurvey",
    },
    {
      placeholder: "1st Dashboard",
      name: "hasSeenDashboardFirstTime",
    },
  ];


  const isDone = steps.every(step => user[step.name]);


  return (
    <>
        {
          !user.isCreatedByAdmin && <p className='mb-0 text-sm'>Not Created By Admin</p>
        }
        
        {
          (user.isCreatedByAdmin && isDone) && <p title='More Info..' onClick={()=> setIsShowMore(!isShowMore)} className='cursor-pointer px-3 py-2 rounded-full font-semibold text-xs bg-green-50 text-green-600 text-center rounded-md mb-0 flex justify-between items-center'>Finished {isShowMore? <IoIosArrowDown />:<IoIosArrowForward />}</p>
        }

        {
          (user.isCreatedByAdmin && !isDone) && <p title='More Info..' onClick={()=> setIsShowMore(!isShowMore)} className='cursor-pointer px-3 py-2 rounded-full font-semibold text-xs bg-yellow-50 text-yellow-600 text-center rounded-md mb-0 flex justify-between items-center'>In Progress.. {isShowMore? <IoIosArrowDown />:<IoIosArrowForward />}</p>
        }

        {
            (user.isCreatedByAdmin && isShowMore)  && (
                    <>
                    <div className="mt-2">
                        {
                          steps.map((step,idx) => (
                            <div key={idx} className="flex items-center gap-2">
                              <span>{step.placeholder}</span>
                              <span className={`w-3 h-3 rounded-full ${user[step.name]? "bg-green-600":"bg-yellow-400"}`}/>
                            </div>
                            ))
                          }
                    </div>
                    </>
            )
        }
    </>
  )
}

function ManagerGroupsOrTeams({user}){

  if(!user?.managementGroups?.length){
    return <EmptyField/>
  }

  return (
    <>
        {
            user.managementGroups?.map((group,idx) => {
              return <span key={idx}><b>{idx+1}</b> : {group.groupname} <br/></span>
            })
        } 
    </>
  )
}

function PersonalSurveys({user}) {

  if(!user?.groups?.length){
    return <EmptyField/>
  }

  return (
    <>
       {
          user.groups.map((group,idx) => {
            return <span key={idx}><b>{idx+1}</b> : {group.groupname} <br/></span>
          })
       } 
    </>
  )
}

function EmptyField(){


  return (
    <>
      <span className='rounded-full text-xs text-gray-300 text-center rounded-md'>Empty</span>
    </>
  )
}

function OneLineField({user,fieldName}){
  if(!user[fieldName]){
    return <EmptyField/>
  }

  if(fieldName === "dob" && user[fieldName]){
    return new Date(user[fieldName]).toLocaleDateString()
  }

  return user[fieldName];
}


function UpdateUserModel({setIsOpen,formData,setFormData,handdleUpdateSubmit}){
  const keys = Object.keys(formData);
  const [nonUniversalSurveyTitles, setNonUniversalSurveyTitles] = useState(null);
  const [activityData, setActivityData] = useState({
    name: "",
    category: ""
  });


  const handleChange = (e) => {
    if(e.target.name === "unRegularSurveys"){
      setFormData((prev => {
        return {
          ...prev,
          [e.target.name]: Array.from(e.target.selectedOptions).map(option => option.value)
        }
      }));
      return;
    }
    setFormData((prev => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    }));
  }

  const handleAddActivity = () => {
    setFormData((prev => {
      return {
        ...prev,
        activities: [...prev.activities,activityData]
      }
    }));
  }

  function convertCamelCaseToWords(camelCaseWord) {
      let words = [camelCaseWord[0].toUpperCase()];

      for (let i = 1; i < camelCaseWord.length; i++) {
          if (camelCaseWord[i] === camelCaseWord[i].toUpperCase()) {
              words.push(' ');
          }
          words[words.length - 1] += camelCaseWord[i];
      }

      return words.join(' ');
  }

  useEffect(() => {
    (async () => {
      const res = await getAllSurveyTitles();
      const NUST = res.surveyTitles.filter(st => (!st.isUniversal && st.title !== "Pre & Post XLrate Survey"));
      setNonUniversalSurveyTitles(NUST);
    })()
  }, []);

  return (
    <div className="flex justify-center items-center fixed top-[62px] left-0 w-full h-full max-h-full bg-black/20">
      <div className="bg-white w-full md:w-[90%] max-h-full  py-4 px-2 rounded-md overflow-auto">
          <div className="w-full flex flex-wrap justify-center gap-2 p-2">
            {
              keys.map((key,idx) => {
                if(key === "gender"){
                  return <div key={idx} className="w-full md:w-auto flex flex-col bg-black/10 p-2 rounded-md">
                            <label htmlFor={key}>{convertCamelCaseToWords(key)}</label>
                            <select name={key} id={key} value={formData[key]} onChange={handleChange}>
                              {
                                GENDER_IDENTITIES.map((gen,idx) => <option key={idx} value={gen.toLocaleLowerCase()}>{gen || "Not Selected"}</option>)
                              }
                            </select>
                          </div>
                }
                if(key === "role"){
                  return <div key={idx} className="w-full md:w-auto flex flex-col bg-black/10 p-2 rounded-md">
                            <label htmlFor={key}>{convertCamelCaseToWords(key)}</label>
                            <select name={key} id={key} value={formData[key]} onChange={handleChange}>
                              {
                                ["user","manager","admin"].map((gen,idx) => <option key={idx} value={gen.toLocaleLowerCase()}>{gen}</option>)
                              }
                            </select>
                          </div>
                }
                if(key === "profession"){
                  return <div key={idx} className="w-full md:w-auto flex flex-col bg-black/10 p-2 rounded-md">
                            <label htmlFor={key}>{convertCamelCaseToWords(key)}</label>
                            <select name={key} id={key} value={formData[key]} onChange={handleChange}>
                              {
                                ["","Student","Working Professional"].map((pro,idx) => <option key={idx} value={pro.toLocaleLowerCase()}>{pro || "Not Selected"}</option>)
                              }
                            </select>
                          </div>
                }
                if(key === "email"){
                  return <div key={idx} className="w-full md:w-auto flex flex-col bg-black/10 p-2 rounded-md">
                            <label htmlFor={key}>{convertCamelCaseToWords(key)}</label>
                            <input placeholder={`Enter ${convertCamelCaseToWords(key)}..`} onChange={()=>{}} className='px-1' type="text" name={key} id={key} value={formData[key]} />
                          </div>
                }
                if(key === "dob"){
                  return <div key={idx} className="w-full md:w-auto flex flex-col bg-black/10 p-2 rounded-md">
                            <label htmlFor={key}>{convertCamelCaseToWords(key)}</label>
                            <input placeholder={`Enter ${convertCamelCaseToWords(key)}..`} onChange={handleChange} className='px-1' type="date" name={key} id={key} value={formData[key]} />
                          </div>
                }
                if(key === "unRegularSurveys"){
                  return <div key={idx} className="w-full md:w-auto flex flex-col bg-black/10 p-2 rounded-md">
                              {
                                !nonUniversalSurveyTitles && <p>Loading...</p>
                              }
                              {
                                nonUniversalSurveyTitles && <>
                                    <select name={key} id={key} value={formData[key]} multiple onChange={handleChange}>
                                      {
                                        nonUniversalSurveyTitles.map((st,idx) => <option key={idx} value={st._id}>{st.title}</option>)
                                      }
                                    </select> 
                                </>
                              }
                          </div>
                }
                if(key === "activities"){
                  return <div key={idx} className="w-full md:w-auto flex flex-col bg-black/10 p-2 rounded-md">
                            <h6 htmlFor={key}>{convertCamelCaseToWords(key)}</h6>
                            <div className='mb-2'>
                              {
                                formData[key].map((activity,idx) => <p className='bg-white px-2 rounded-md mb-1' key={idx}>{activity.name} {activity.category}</p>)
                              }
                            </div>
                            <div className='flex items-streach'>
                              <input name='activityName' value={activityData.name} onChange={(e) => setActivityData({...activityData,name: e.target.value})} className=' px-2 py-1 border rounded-md' placeholder='Eg. coding..' />
                              <select value={activityData.category} className='p-1 border rounded-md' onChange={(e) => setActivityData({...activityData,category: e.target.value})}>
                                {
                                  ACTIVITIES.filter(act => formData[key].find(ac => ac.category === act) === undefined).map((activity,idx) => <option key={idx}>{activity}</option>)
                                }
                              </select>
                              <button disabled={(activityData.name && activityData.category)? false:true} onClick={handleAddActivity} className='bg-blue-600 ml-1 px-2 text-white rounded-md disabled:bg-blue-600/50 disabled:cursor-not-allowed'>Add</button>
                            </div>
                          </div>
                }
                return <div key={idx} className="w-full md:w-auto flex flex-col bg-black/10 p-2 rounded-md">
                          <label htmlFor={key}>{convertCamelCaseToWords(key)}</label>
                          <input placeholder={`Enter ${convertCamelCaseToWords(key)}..`} onChange={handleChange} className='px-1' type="text" name={key} id={key} value={formData[key]} />
                       </div>
              })
            }
            <div className="w-full flex justify-center mt-4 mb-14 md:mb-0 gap-4">
                <UI.Button onClick={()=>setIsOpen(false)} className="py-2 px-4 text-white font-semibold bg-yellow-600">Cancel</UI.Button>
                <UI.Button onClick={handdleUpdateSubmit} className="py-2 px-4 text-white font-semibold bg-green-600">Update</UI.Button>
            </div>
          </div>
      </div>
    </div>
  )
};


function OnboardingEmailReceived({user}){

  return <>
              {
                user.hasReceivedOnboardingEmail && <p className='px-3 py-2 rounded-full font-semibold text-xs bg-green-50 text-green-600 text-center rounded-md mb-0'>
                  {
                    user.onbordingEmailResponse? user.onbordingEmailResponse:"Yes"
                  }
                </p>
              }
              {
                !user.hasReceivedOnboardingEmail && <p className='px-3 py-2 rounded-full font-semibold text-xs bg-red-50 text-red-600 text-center rounded-md mb-0'>No</p>
              }
  </>
}



