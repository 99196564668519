export function InputWithLabel({label="Label",placeholder="placeholder",value,onChange,name}){

            return (
                <div className='mb-5'>
                    <label className="text-gray-600">
                        {label}
                    </label>
                    <div className="relative mt-2 w-full text-gray-500">
                        <input
                            type="date"
                            placeholder={placeholder}
                            value={value}
                            onChange={onChange}
                            name={name}
                            className="w-full pl-[.5rem] pr-3 py-2 appearance-none bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                        />
                    </div>
                </div>
            )
        }