import React,{useEffect,useState} from "react";
import {Tree } from "react-arborist";
// prev versions 
import {FaPlus, FaUser} from "react-icons/fa";
import {BiEdit} from "react-icons/bi";
import {
  AiFillDelete,
} from "react-icons/ai";
import {useConfermAlert} from "../../../hooks";
import {Modal} from "../../../components";
import {
  // addOrRemoveSurveyTitleFromGroup,
  updateGroupName,
  deleteGroup,
  addGroup,
  addOrRemoveGroupFromGroup, 
  addUserToGroup,
  removeUserFromGroup, 
  addScheduledTime,
  updateScheduledTime,
  // getAllScheduledTimes,
  allowStopAccessToDashboardOfUsers,
  getScheduledTimeById,
  getGroupById,
  updateBatchUsers
} from "../../../Store/actions";
import { useDispatch ,useSelector} from "react-redux";
import { useNavigate } from "react-router-dom";
import {AddSurveyTiming as AddSurveyTimingForm} from "./index";
import {dateFormet} from "../../../utils";
import { toast } from "react-toastify";
import {UI,Loading,} from "../../../components";
import moment from "moment";
import { MakeTreeFromArray, addIdAndName , getAllUsers, getUsersFromGroupWithoutTiming } from "./utils";
import Loader from "../../../components/Loading/Loading";

  
function TreeComp({data,setScheduledTimes,scheduledTimes,surveyTitles}) {
      const [treeData, setTreeData] = useState(null);
      const [selectedUsers, setselectedUsers] = useState([]);
      const navigator = useNavigate();
      const dispatch = useDispatch();
      const [timingData, setTimingData] = useState({
        days: [],
        duration: {start: "",end: ""},
        frequency: "",
        time: {hours: "",minutes: ""},
        surveytitle: "",
        unRegularSurvey: "",
        _id: "",
      });
      const [_id, set_Id] = useState(null);
      const loggedInUser = useSelector(state => state.loggedInUser);
      const [isTimeUpdate, setIsTimeUpdate] = useState(false);
      const [showAddGroupModal, setShowAddGroupModal] = useState(false);
      const [groupName, setGroupName] = useState("");
      const [isUniversal, setIsUniversal] = useState(false);
      const [groupId, setGroupId] = useState(null);
      const [isUpdateGroup, setIsUpdateGroup] = useState(false);
      const [surveytitle] = useState("");
      const [addOrUpdateSurveyTitle, setAddOrUpdateSurveyTitle] = useState(false);
      const [surveyTitlesToUpdate,setSurveyTitlesToUpdate] = useState([]);
      const [selectedSurveyNames,setSelectedSurveyNames] = useState([]);
      const [displaySurveyTimingModal, setDisplaySurveyTimingModal] = useState(false);
      const [selectedGroup, setSelectedGroup] = useState(null);
      const [showUpdateBatchUsersPopup, setShowUpdateBatchUsersPopup] = useState(false);
      const alert = useConfermAlert();
      const [showUserNames, setShowUserNames] = useState(true);
 

      useEffect(() => {
            // dispatch(getAllScheduledTimes())
            const newData = JSON.parse(JSON.stringify(data));
            const temp = MakeTreeFromArray(newData,loggedInUser);
            setTreeData(temp);
      }, [data,dispatch,loggedInUser]);

      const handdleSelect = (nodes)=> {
        // console.log("aa");
        let temp = nodes[0]?.tree.selectedNodes;
  
        if(temp){
          setGroupId(temp[0].data._id);
          setSelectedGroup(temp[0]);
        }

        // set survey title for group 
        const surveyTitlesForGroup = temp?temp[0].data.surveys:null;
  
        if(surveyTitlesForGroup){
          setSurveyTitlesToUpdate(surveyTitlesForGroup);
        }
       
    
        if(temp){
            let usersArr = [];

            temp.forEach(g => {
                if(!g.data.email){
                    const {userids,users,userSurveys} = getAllUsers(g);
                    setSelectedSurveyNames([...userSurveys][0]);
                    usersArr.push({
                        users: [...userids],
                        groupname: g.data.name,
                        createdAt: g.data.createdAt,
                        usersDetails: users,
                        userSurveys: [...userSurveys]
                    })
                }
            })
            setselectedUsers(usersArr);
        }


        if(nodes[0]?.data?._id){
          set_Id(nodes[0].data._id)
        }
      }

      const handdleViewDashboard = ()=> {
        let temp = undefined;
        if(surveytitle){
          temp = selectedUsers.map(su => {return{...su,surveyTitle: surveytitle,selectedSurveyNames}});
        }
        else{
          temp = selectedUsers;
        }

        if(temp[0]?.users?.length <= 1){
          toast.error("You can't see dashboard");
          return;
        }

        navigator("/dashboard/management",{ state: temp[0] })
      }

      
      const moveNode = (sourceId,sourceParentId,destnationId) => {
          let source = undefined;
          
          // remove source from its previous position  
          treeData.forEach(root => {
            function myVisit(d){
              if(d.id === sourceParentId){
                source = d.children.find(c => c.id === sourceId);
                d.children = d.children.filter(c => c.id !== sourceId);
              }
              if(d.children){
                d.children.forEach(child => myVisit(child));
              }
            }
            myVisit(root);
          })

          // add source to its new position   
          treeData.forEach(root => {
            function myVisit(d){
              if(d.id === destnationId){
                d.children.push(source);
              }
              if(d.children){
                d.children.forEach(child => myVisit(child));
              }
            }
            myVisit(root);
          })

          setTreeData([...treeData]);
      }

      const handleMove = async (e)=> {
        const isUser = e.dragNodes[0].data.email
        const sourceId = e.dragIds[0];
        const sourceParentId = e.dragNodes[0].parent?.id;
        const destinationId = e.parentId;
        const mongoSourceId = sourceId.split("---")[0];
        const mongoSourceParentId = sourceParentId.split("---")[0];
        const mongoDestinationId = destinationId.split("---")[0];

        if(destinationId === sourceParentId){
          return;
        }

        if(isUser && destinationId && mongoSourceId && mongoSourceParentId){
          const res = await removeUserFromGroup(mongoSourceId,mongoSourceParentId);
          const res1 = await addUserToGroup(mongoSourceId,mongoDestinationId);

          if(res.success && res1.success){
            moveNode(sourceId,sourceParentId,destinationId);
            toast.success("Moved Successfully!");
          }
          return;
        }

        if(destinationId && mongoDestinationId && mongoSourceId){
          const res = await addOrRemoveGroupFromGroup(mongoDestinationId,{groups: [mongoSourceId]});
         
          if(res.success){
            moveNode(sourceId,sourceParentId,destinationId);
            toast.success("Moved Successfully!");
          }
        }
      }

      const showAddOrUpdateGroupModal = (id,text) =>{
        if(text){
          setGroupName(text);
          setIsUpdateGroup(true);
        }
        else {
          setGroupName("");
          setIsUpdateGroup(false);
        }
        setGroupId(id);
        setShowAddGroupModal(true);
      }

      const handleAddGroupClick = (groupId) => {
        showAddOrUpdateGroupModal(groupId);
      }

      const handleEditGroupClick = (groupId,groupName) => {
        showAddOrUpdateGroupModal(groupId,groupName);
      }

      const handleAddSurveyTimingClick = (groupId) => {
          set_Id(groupId);
          setIsTimeUpdate(false);
          setTimingData(()=>{
            return {
              days: [],
              duration: {start: "",end: ""},
              frequency: "",
              time: {hours: ``,minutes: ``},
              surveytitle: "",
              _id: ""
            }
          });
          setDisplaySurveyTimingModal(true);
      }

      const handleUpdateSurveyTimingClick = async (groupId) => {
        set_Id(groupId);
        setIsTimeUpdate(true);
        const temp = await scheduledTimes.find(t => t.group === groupId);
        const res = await getScheduledTimeById(temp._id);
        const time = res.surveyPeriodFrequencyAndTime;
        if(time){
          setTimingData(()=>{
            return {
              days: time.days,
              duration: {start: dateFormet(time.duration.start),end: dateFormet(time.duration.end)},
              frequency: time.frequency,
              time: {hours: `${time.time.hours>9? time.time.hours:`0${time.time.hours}`}`,minutes: `${time.time.minutes>9? time.time.minutes:`0${time.time.minutes}`}`},
              surveytitle: time.surveytitle,
              unRegularSurvey: time.unRegularSurvey,
              _id: time._id
            }
          });
        }
        setDisplaySurveyTimingModal(true);
      }

      const handleEditBatchUsersClick = (groupId)=> {
        set_Id(groupId);
        setShowUpdateBatchUsersPopup(true);
      }

      const handleDashboardToggleClick = (e) => {
          const users = selectedUsers[0].users;
          let canAccessDashboard = false;
         
          if(e.target.checked){
            canAccessDashboard = true;
          }
          // console.log(canAccessDashboard);
          dispatch(allowStopAccessToDashboardOfUsers(users,canAccessDashboard));
      }

      const handleChangeOfSurveyTitle = (e)=>{
        if(e.target.checked){
          setSurveyTitlesToUpdate(prev =>{
            return [...prev,e.target.value]
          })
        }
        else {
          setSurveyTitlesToUpdate(prev =>{
            return prev.filter(item => item !== e.target.value)
          })
        }
      }

      const handleAddSurveyTitleSubmit =()=>{
        // dispatch(addOrRemoveSurveyTitleFromGroup(_id,{surveys: surveyTitlesToUpdate}));
      }

      const handleAddGroupSubmit = async ()=>{
        if(!isUpdateGroup){
          const res = await addGroup({parent: groupId,groupname: groupName,isUniversal: isUniversal});
          if(res.success){
            addGroupLocally(res);
            toast.success("Group Added Successfully!");
          }
          else {
            toast.success("Error While Adding Group!");
          }
        }
        else {
          const res = await updateGroupName(groupId,{groupname: groupName});
        
          if(res.success){
            updateGroupNameLocally(res);
            toast.success("Group Updated Successfully!");
          }
          else {
            toast.success("Error While Updating Group!");
          }
        }

        function addGroupLocally(res){
          const group = res.group;
          addIdAndName(group);
          treeData.forEach(root => {
            function visitTree(d){
              if(d._id === groupId){
                d.children.push(group);
              }
              if(d.children){
                d.children.forEach(child => visitTree(child));
              }
            }
            visitTree(root);
          });
          setTreeData([...treeData]);
        }

        function updateGroupNameLocally(res){
          const group = res.group;
    
          treeData.forEach(root => {
            function visitTree(d){
              if(d._id === groupId){
                d.groupname = group.groupname;
                d.name = group.groupname;
              }
              if(d.children){
                d.children.forEach(child => visitTree(child));
              }
            }
            visitTree(root);
          });
          setTreeData([...treeData]);
        }
      }

      const handdleAddSurveyTimingSubmit = async ()=> {
        const users = getUsersFromGroupWithoutTiming(groupId,selectedGroup.data,scheduledTimes)
    
        if(isTimeUpdate){
          let temp = {
            ...timingData,
            days: timingData.days.sort((a,b) => a-b),
            users
          }
          const res = await updateScheduledTime(timingData._id,temp);
          
          if(res.success){
            toast.success("Timing Updated Successfully!");
          }
          else {
            toast.success("Error While Updating Timing");
          }
        }
        else {
          let temp = {
            ...timingData,
            days: timingData.days.sort((a,b) => a-b),
            users
          }
          delete temp._id;

          const res = await addScheduledTime({...temp,group: _id});
          
          if(res.success){
            setScheduledTimes(prev => {
              return [...prev,res.surveyPeriodFrequencyAndTime]
            })
            toast.success("Timing Added Successfully!");
          }
          else {
            toast.success("Error While Adding Timing");
          }
        }
      }

      if(!treeData){
        return <Loader/>
      }
    
      return (
      <>
        <Header setShowUserNames={setShowUserNames} showUserNames={showUserNames} selectedUsers={selectedUsers} handdleViewDashboard={handdleViewDashboard}/>
  
        <Tree
            data={treeData}
            openByDefault={false}
            width={500}
            indent={24}
            height={750}
            rowHeight={36}
            paddingTop={30}
            paddingBottom={10}
            padding={25 /* sets both */}
            onSelect={(nodes)=>{handdleSelect(nodes)}}
            onMove={(e)=>handleMove(e)}
            handleAddGroupClick={handleAddGroupClick}
            handleEditGroupClick={handleEditGroupClick}
            handleAddSurveyTimingClick={handleAddSurveyTimingClick}
            handleUpdateSurveyTimingClick={handleUpdateSurveyTimingClick}
            handleEditBatchUsersClick={handleEditBatchUsersClick}
            handleDashboardToggleClick={handleDashboardToggleClick}
            treeData={treeData}
            setTreeData={setTreeData}
            scheduledTimes={scheduledTimes}
            alert={alert}
            showUserNames={showUserNames}
          >
            {Node}
        </Tree>

        {/* add timing modal  */}
        <Modal
          title={isTimeUpdate? "Update Timing":"Add Timing" }
          saveBtnText={isTimeUpdate? "Update":"Add" } 
          onSubmit={()=>handdleAddSurveyTimingSubmit()}
          isOpen={displaySurveyTimingModal}
          setIsOpen={setDisplaySurveyTimingModal}
        >
          <AddSurveyTimingForm 
            setTimingData={setTimingData}
            timingData={timingData}
          />
        </Modal> 
        
        {/* group modal  */}
        <Modal 
            title={isUpdateGroup?"Update Group":"Add Group"}
            isOpen={showAddGroupModal}
            setIsOpen={setShowAddGroupModal}
            onSubmit={()=>handleAddGroupSubmit()}
          >
            <div>
              <input 
                type="text"
                value={groupName} 
                onChange={(e)=>{setGroupName(e.target.value)}}
                className="w-full border rounded-md p-2"
              />
              <label htmlFor="universal" className="mt-2 flex items-center">
                Is Universal: 
                <input type="checkbox" id="universal" className="ml-2" onChange={e=> setIsUniversal(prev => !prev)} checked={isUniversal}/>
              </label>
            </div>
        </Modal> 

        {/* survey title modal  */}
        <Modal 
            title={"Add Survey Titles"}
            isOpen={addOrUpdateSurveyTitle}
            setIsOpen={setAddOrUpdateSurveyTitle}
            onSubmit={()=>handleAddSurveyTitleSubmit()}
          >
            {
              surveyTitles && surveyTitles.map((st,i) =>{
                
                return <div key={i}>
                        <input 
                              type="checkbox"
                              value={st._id} 
                              className="mr-5"
                              name={st._id}
                              checked={surveyTitlesToUpdate.indexOf(st._id) !== -1? true:false}
                              onChange={handleChangeOfSurveyTitle}
                              />
                              <span>{st.title}</span>
                        </div>
                
              })
            }
            
        </Modal> 

        {/* update batch users  */}
        {
          showUpdateBatchUsersPopup && <UI.PopUpWraper absolute={true}>
                                          <UpdateBatchUsers setVisilivity={setShowUpdateBatchUsersPopup} groupId={_id} surveyTitles={surveyTitles}/>
                                        </UI.PopUpWraper>
        }
      </>
      )
}

export default TreeComp;


function Node({ node,style, dragHandle,tree }) {

    const {
      handleAddGroupClick,
      handleEditGroupClick,
      handleAddSurveyTimingClick,
      handleUpdateSurveyTimingClick,
      handleEditBatchUsersClick,
      handleDashboardToggleClick,
      treeData,
      setTreeData,
      scheduledTimes,
      alert,
      showUserNames
    } = tree.props;
    const isEdit = scheduledTimes? scheduledTimes?.find(t => t.group === node.data._id):null;
    const {users} = getAllUsers(node);
    const trmp = [...users].every(el => el.canAccessDashboard); 
    const canAccessDashboard = trmp
    // const [canAccessDashboard, setCanAccessDashboard] = useState(trmp);

    const onDeleteSubmit = async (node)=> {
        const groupId = node.data._id;
        const res = await deleteGroup(groupId);
        
        if(res.success){
          treeData.forEach(root => {
            function visitTree(d){
              const isChildOf = d.children?.find(child => child._id === groupId);
              if(isChildOf){
                const remaningChild = d.children.filter(child => child._id !== groupId)
                d.children = remaningChild;
              }
              if(d.children){
                d.children.forEach(child => visitTree(child));
              }
            }
            visitTree(root);
          });
          setTreeData([...treeData]);
          toast.success("Group Deleted Successfully!");
        }
        else {
          toast.success("Error While Deleting Group!");
        }
        
    }

    const handleDashboardViewChange = (e) => {
      // setCanAccessDashboard(p=>!p)
      handleDashboardToggleClick(e);
    }

    

    return (
        <>
        <div  ref={dragHandle} className="tree flex items-center" style={{...style,backgroundColor: (node.isSelected || node.willReceiveDrop)?"#ccc":"transparent"}}>
          <div onClick={() => node.toggle()} className="left flex items-center gap-[5px] flex-1">
            <span>{node?.data?.email ? <FaUser size={12}/> : "🗀"}</span>
            <span className="name overflow-x-hidden">{!node?.data?.email ? node.data.name:showUserNames? node.data.name:"user"}</span>
          </div>
          
          <div className={node.isSelected && !node.data.email && tree?.selectedNodes?.length === 1? "right flex justify-end items-center gap-[5px] pr-[5px]":"hide"}>

              <FaPlus
              title="Add Child Group"
              className="cursor-pointer"
              onClick={()=> handleAddGroupClick(node.data._id)}
              />

              {
                isEdit? (
                  <BiEdit 
                    title="Edit Survey Timing"
                    className="cursor-pointer"
                    onClick={()=>handleUpdateSurveyTimingClick(node.data._id)}
                  />
                ):(
                  <FaPlus
                    title="Add Survey Timing"
                    className="cursor-pointer"
                    onClick={()=>handleAddSurveyTimingClick(node.data._id)}
                  />
                )
              }
              
              <AiFillDelete 
              title="Delete" 
              onClick={()=>{alert(node,onDeleteSubmit)}}/>

              <BiEdit 
                title="Edit Group"
                className="cursor-pointer"
                onClick={()=>handleEditGroupClick(node.data._id,node.data.groupname)}
              />

              <BiEdit 
              title="Edit Batch Users"
              className="cursor-pointer"
              onClick={()=>handleEditBatchUsersClick(node.data._id)}
              />

              <div className="">
                <input title={canAccessDashboard? "can access dashboard":"can't access dashboard"} value="" onChange={handleDashboardViewChange} checked={canAccessDashboard} type="checkbox" data-id={node.data._id} className="dashboardAccess"/>
              </div>
          </div>
        </div>

       
      </>
    );
}


function UpdateBatchUsers({setVisilivity,groupId,surveyTitles}){
    const [userIds, setUserIds] = useState(null);
    const [error, setError] = useState(null);
    const [data, setData] = useState({postSurveyId: "",preSurveyId: "",postSurveyDate: "",onbordingEmailTime: ""});
    const [groupName, setGroupName] = useState(null);
    

    const handleOnChange = (e) => {
      setData(prev => {
        return {
          ...prev,
          [e.target.name]: e.target.value
        }
      })
    }

    const handleSubmit = async () => {
      let isAllExists = true

      Object.keys(data).forEach(key => {
        if(!data[key]){
          isAllExists = false;
        }
      })

      if(!isAllExists){
        toast.error("All fields are required");
        return;
      }

      const d = {
        data,
        userIds
      }

      const res = await updateBatchUsers(d);

      if(res.success){
        toast.success("Updated Successfully");
        setVisilivity(false);
      }
      else{
        toast.error(res.message);
      }
    }

    useEffect(() => {
      (async ()=> {
        const res = await getGroupById(groupId);
        if(res.success){
          const user = res.group.users.find(u => u !== null);
          
          if(!user){
            setError("No User Found");
            return;
          }
          if(user.requiredActivities?.length === 0){
            setError("Users in this group are not batch users");
            return;
          }
          setGroupName(res.group.groupname);
          setUserIds(res.group.users.map(u => u?._id)); 
          setData({
            postSurveyId: user.postSurveyId,
            preSurveyId: user.preSurveyId,
            postSurveyDate: moment(user.postSurveyDate).format("YYYY-MM-DDTHH:mm:SS"),
            onbordingEmailTime: moment(user.onbordingEmailTime).format("YYYY-MM-DDTHH:mm:SS")
          })
        }
        else {
          toast.error("Something went wrong!");
        }
      })()

      return () => {
        setError(null);
      }
    }, [groupId]);


    if(error || !surveyTitles){
      return <UI.PopUpWraper absolute={true}>
                <UI.ModalHeader handleCrosClick={()=>{setVisilivity(false)}}/>
                <UI.ErrorMessage className="mt-4">{error}</UI.ErrorMessage>
              </UI.PopUpWraper>
    }

    if(!userIds){
      return <UI.PopUpWraper absolute={true}><Loading/></UI.PopUpWraper>
    }



    return (
      <>
        <UI.PopUpWraper absolute={true}>
           <UI.ModalHeader leftText={groupName} handleCrosClick={()=>{setVisilivity(false)}}/>
           <div className="pt-4 w-full">
              <div className="w-full">
                  <label htmlFor="postSurveyId">Post Survey</label>
                  <select value={data.postSurveyId} onChange={handleOnChange} className="block w-full p-2 rounded-md mt-1" name="postSurveyId" id="postSurveyId">
                      {
                        surveyTitles.map(st => <option key={st._id} value={st._id}>{st.title}</option>)
                      }
                  </select>
              </div>
              <div className="w-full mt-4">
                  <label htmlFor="preSurveyId">Pre Survey</label>
                  <select value={data.preSurveyId} onChange={handleOnChange} className="block w-full p-2 rounded-md mt-2" name="preSurveyId" id="preSurveyId">
                      {
                        surveyTitles.map(st => <option key={st._id} value={st._id}>{st.title}</option>)
                      }
                  </select>
              </div>
              <div className="w-full mt-4">
                  <label htmlFor="postSurveyDate">Post Survey Date</label>
                  <input value={data.postSurveyDate} onChange={handleOnChange} type="datetime-local" id="postSurveyDate" name="postSurveyDate" className="block w-full p-2 rounded-md mt-2"/>
              </div>
              <div className="w-full mt-4">
                  <label htmlFor="onbordingEmailTime">Onboarding Email Time</label>
                  <input value={data.onbordingEmailTime} onChange={handleOnChange} type="datetime-local" id="onbordingEmailTime" name="onbordingEmailTime" className="block w-full p-2 rounded-md mt-2"/>
              </div>
              
              <UI.Button className="text-white mt-4 py-3 px-4 w-full uppercase font-bold" onClick={handleSubmit}>Done</UI.Button>
           </div>
        </UI.PopUpWraper>
      </>
    )
}

function Header({setShowUserNames,showUserNames,selectedUsers,handdleViewDashboard}) {

  return <>
    <div className="flex items-center justify-between gap-4 border-b pb-2">
        <div className="flex items-center gap-4">
              <FaPlus 
              onClick={()=>setShowUserNames(true)} 
              title="Add Root Group" />

              <label className="relative inline-flex items-center cursor-pointer">
                    <input type="checkbox" value="" className="sr-only peer" onChange={()=>setShowUserNames(p => !p)} checked={showUserNames}/>
                    <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{showUserNames? "Hide User Names":"Show User Names"}</span>
              </label>
        </div>
        <div className="">
              <ViewDashboardUI selectedUsers={selectedUsers} handdleViewDashboard={handdleViewDashboard}/>
        </div>
    </div>
  </>
}

function ViewDashboardUI({handdleViewDashboard,selectedUsers}){

  if(selectedUsers.length === 0){
    return null;
  }

  return <>
      <div className={"flex gap-4 items-center"}>
        <p className="mb-0">{selectedUsers[0].groupname}</p>
        <p className="mb-0">{selectedUsers[0]?.userSurveys[0]}</p>
        <button className="btn cursor-pointer px-3 rounded border" onClick={handdleViewDashboard}>View dashboard</button>
      </div>
  </>
}
  
  


 





