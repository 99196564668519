import React from 'react'

function ErrorMessage({title="",children,className}) {
  return (
    <div className={`p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 ${className}`} role="alert">
        <span className="font-medium">{title}</span> {children}
    </div>
  )
}

export default ErrorMessage;