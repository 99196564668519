import React,{useEffect, useState} from 'react';
import "./App.css";
import {Navbar} from './layout';
import { BrowserRouter,Routes ,Route} from "react-router-dom";
import {
  getLoggedInUser,
  setIsMenuOpen
} from "./Store/actions";
import { useDispatch, useSelector } from 'react-redux';
import {
  CustomRoutes
} from "./components";
import {
  ActionChallenges,
  CompletedSurveys,
  CreateSurvey,
  Groups,
  Login,
  Signup,
  Surveys,
  UserDashboard,
  Users,
  AddUsers,
  Home,
  PageNotFound,
  ManagementDashboard,
  UserSurveySelection,
  UniversalSurveyTitle,
  ForgetPassword,
  ResetPassword,
  PurposeAndGoal,
  RemaningSurveys,
  UnRegularCompletedSurveys,
  CreatePrePostSurvey,
  OnBoarding,
  ManageNotifications,
  TermsAndConditions,
  PrivacyPolicy,
  CookiePolicy
} from "./pages";
import ReactGA from 'react-ga';
import { generateFireBaseToken } from './firebase';
import { toast } from 'react-toastify';
// import mixpanel from "mixpanel-browser";


// mix panel setup 
// if(window.location.hostname === "localhost"){
//   mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN_LOCAL,{debug: true});
// }
// else {
//   mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
// }


function App() {
  const dispatch = useDispatch();
  const loggedInUser = useSelector(state => state.loggedInUser);



  const handleClick = (e) => {
    if(e.target.id !== "hem" && e.target.id !== "plus"){
      dispatch(setIsMenuOpen(false))
    }
  }

  useEffect(() => {
    ReactGA.initialize('G-4QZNC9XEEH'); // Replace with your Google Analytics tracking ID
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  
  useEffect(() => {
    if(!loggedInUser){
      dispatch(getLoggedInUser());
    }
  }, [dispatch,loggedInUser]);

  useEffect(() => {
    if(!loggedInUser){
      return;
    }

    (async ()=>{
      await generateFireBaseToken();
    })()
  }, [loggedInUser]);
  
 
  return (
    <div onClick={handleClick} className="min-h-full">
    <BrowserRouter>
        <div className='app bg-primary-color' style={{marginTop: window.location.pathname === "/waiting"? "0px":"64px"}}>
          <Routes>
               {/* admin routes  */}
               <Route element={<CustomRoutes.AdminRoutes/>}>
                    <Route excet path='/admin/manage/users' element={<><Navbar/><Users/></>}/>
                    <Route excet path='/admin/manage/surveytitle'  element={<><Navbar/><Surveys/></>}/>
                    <Route excet path='/admin/surveys/completed-surveys'  element={<><Navbar/><CompletedSurveys/></>}/>
                    <Route excet path='/admin/add/user'  element={<><Navbar/><AddUsers/></>}/>
                    <Route excet path='/admin/manage/universal/surveytitle'  element={<><Navbar/><UniversalSurveyTitle/></>}/>
                    <Route excet path='/admin/un-regular-completed-surveys' element={<><Navbar/><UnRegularCompletedSurveys/></>}/>
               </Route>

               {/* manager routes  */}
               <Route element={<CustomRoutes.ManagerRoutes/>}>
                    <Route excet path='/admin/manage/groups'  element={<><Navbar/><Groups/></>}/>
                    <Route excet path='/dashboard/management'  element={<><Navbar/><ManagementDashboard/></>}/>
               </Route>

               {/* private routes  */}
              <Route element={<CustomRoutes.PrivateRoutes/>}>
                  <Route excet path='/user-survey-selection'  element={<><Navbar/><UserSurveySelection/></>} />
                  <Route excet path='/on-boarding'  element={<><Navbar/><OnBoarding/></>}/>
                  <Route excet path='/purpose-and-goal' element={<><Navbar/><PurposeAndGoal/></>}/>
                  <Route excet path='/uncompleted-surveys' element={<><Navbar/><RemaningSurveys/></>}/>
                  <Route excet path='/survey/:userId/:surveyId/:time'  element={<><Navbar/><CreateSurvey/></>}/>
                  <Route excet path='/score/user/:userId'  element={<><Navbar/><UserDashboard/></>}/>
                  <Route excet path='/pre-post-survey/:userId/:surveyId'  element={<><Navbar/><CreatePrePostSurvey/></>}/>
                  <Route excet path='/action-challenges'  element={<><Navbar/><ActionChallenges/></>}/>
                  <Route excet path='/' element={<><Navbar/><Home/></>}/>
                  <Route excet path='/manage/notifications' element={<><Navbar/><ManageNotifications/></>}/>
              </Route> 

              {/* public routes  */}
              <Route excet path='/login' element={<><Navbar/><Login/></>}/>
              <Route excet path='/signup' element={<><Navbar/><Signup/></>}/>
              <Route excet path='/forget-password'  element={<><Navbar/><ForgetPassword/></>}/>
              <Route excet path='/reset-password'  element={<><Navbar/><ResetPassword/></>}/>
              <Route excet path='/terms-and-conditions' element={<><Navbar/><TermsAndConditions/></>}/>
              <Route excet path='/privacy-policy' element={<><Navbar/><PrivacyPolicy/></>}/>
              <Route excet path='/cookie-policy' element={<><Navbar/><CookiePolicy/></>}/>
              <Route excet path='/waiting' element={<><LandingPage/></>}/>
              <Route excet path='*'  element={<PageNotFound/>}/>
          </Routes>
        </div>
    </BrowserRouter>
    </div>
  )
}

export default App;



function LandingPage(){
  const [data, setData] = useState({
    cognitivePerformance: "",
    topThreeChallenges1: "",
    topThreeChallenges2: "",
    topThreeChallenges3: "",
    satisfiedWithPerformange: "",
    whatWouldMakeXLRate: "",
    recommend: "",
    cost90Days5000: "", 
    cost30Days100: "", 
    betaTestingProgram: "",
    quitReason: "",
    outComes: [],
  });
  const [user,setUser] = useState({name: "",email: ""});
  const keys = Object.keys(data);


  const handleChange = (e) => {

    if(e.target.name === keys[11]){
      setData(prev => {

        let arr = prev[e.target.name];

        if(!e.target.checked){
          arr = arr.filter(item => item !== e.target.value);
        }
        else {
          arr = [...prev[e.target.name],e.target.value];
        }

        return {
          ...prev,
          [e.target.name]: arr
        }
      });
      return;
    }

    setData(prev => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    });
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    const waitingListData = {
      ...data,
      ...user
    }


    if(!waitingListData.email){
      toast.error("Please enter email first");
      document.querySelector("#user").style.borderColor = "red";
      window.location.href = `${window.location.origin+window.location.pathname}#hero-section`;
      return;
    }
    if(!waitingListData.name){
      toast.error("Please enter name first");
      return;
    }
    
  }

  const whyXLRate = [
    {
      icon: <>
      <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-color-swatch"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M19 3h-4a2 2 0 0 0 -2 2v12a4 4 0 0 0 8 0v-12a2 2 0 0 0 -2 -2" />
            <path d="M13 7.35l-2 -2a2 2 0 0 0 -2.828 0l-2.828 2.828a2 2 0 0 0 0 2.828l9 9" />
            <path d="M7.3 13h-2.3a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h12" />
            <line x1={17} y1={17} x2={17} y2="17.01" />
          </svg>
      </>,
      title: "Maximised Productivity through Flow",
      description: "Enhance your focus and efficiency dramatically. Research shows that being in a flow state can increase productivity by up to 500%"
    },
    {
      icon: <>
      <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-bolt"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <polyline points="13 3 13 10 19 10 11 21 11 14 5 14 13 3" />
          </svg>
      </>,
      title: "Enhanced Creativity",
      description: "Our platform supports your journey to reach and maintain your creative peak, ensuring you stay ahead in innovation."
    },
    {
      icon: <>
      <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-tools"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3 21h4l13 -13a1.5 1.5 0 0 0 -4 -4l-13 13v4" />
            <line x1="14.5" y1="5.5" x2="18.5" y2="9.5" />
            <polyline points="12 8 7 3 3 7 8 12" />
            <line x1={7} y1={8} x2="5.5" y2="9.5" />
            <polyline points="16 12 21 17 17 21 12 16" />
            <line x1={16} y1={17} x2="14.5" y2="18.5" />
          </svg>
      </>,
      title: "Accelerated Learning",
      description: "Speed up your skill development with advanced insights that promote rapid learning, keeping you agile in a dynamic market."
    },
    {
      icon: <>
       <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-tools"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3 21h4l13 -13a1.5 1.5 0 0 0 -4 -4l-13 13v4" />
            <line x1="14.5" y1="5.5" x2="18.5" y2="9.5" />
            <polyline points="12 8 7 3 3 7 8 12" />
            <line x1={7} y1={8} x2="5.5" y2="9.5" />
            <polyline points="16 12 21 17 17 21 12 16" />
            <line x1={16} y1={17} x2="14.5" y2="18.5" />
          </svg>
      </>,
      title: "Advanced Problem-Solving",
      description: "Transform challenges into opportunities with enhanced problem-solving capabilities facilitated by flow states"
    }
  ];

  const nextLavelGrowth = [
    {
      imgURL: "https://cdn-icons-png.flaticon.com/128/7069/7069350.png",
      title: "Cognitive Blueprint Mapping",
      description: "Tailor your mental performance optimally for critical tasks using our sophisticated machine-learning techniques."
    },
    {
      imgURL: "https://cdn-icons-png.flaticon.com/512/854/854894.png",
      title: "Real-Time Feedback",
      description: "Continuously monitor your cognitive performance, similar to how a fitness tracker monitors physical health"
    },
    {
      imgURL: "https://cdn-icons-png.flaticon.com/128/2651/2651001.png",
      title: "Customised Strategic Coaching",
      description: "Receive coaching that aligns seamlessly with your schedule and professional growth, ensuring constant development"
    },
    {
      imgURL: "https://cdn-icons-png.flaticon.com/128/2651/2651001.png",
      title: "Flow State Optimisation",
      description: "Learn to achieve and maintain peak mental states to boost your operational effectiveness and strategic thinking"
    },
  ]
  

  return (<>
    <section id='hero-section' className="relative bg-gray-900 text-white">
      <div className="mx-auto max-w-screen-xl px-4 py-32 lg:flex lg:h-screen lg:items-center">
        <div className="mx-auto max-w-3xl text-center">
          <h1
            className="bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 bg-clip-text text-3xl font-extrabold text-transparent sm:text-5xl"
          >
            Welcome to XLRate!
          </h1>

          <p className="mx-auto mt-4 max-w-2xl sm:text-xl/relaxed">
            Designed for visionaries and high-performance professionals,
            <br/> XLRate is more than just a tool— 
            it's your gateway to mastering the science of flow. 
            <br/>This state, where creativity, productivity, and peak performance converge, can be harnessed to propel your personal, professional and organisational success to unprecedented levels
          </p>

          <div className="relative z-[10] mt-8  flex-col flex-wrap justify-center gap-4">
            <div className='flex flex-col md:flex-row gap-5 justify-center'>
              <input
                value={user.name}
                onChange={(e)=> {
                  setUser({...user,name:e.target.value});
                  document.querySelector("#user").style.border = "none";
                }}
                name='user'
                id='user'
                type='text'
                autoFocus
                className="block w-full rounded border border-gray-800 px-3 py-3 text-sm font-medium text-white bg-gray-800 hover:bg-gray-700 focus:bg-gray-700 focus:ring sm:w-auto"
                placeholder='Enter your name'
              />
              <input
                className="block w-full rounded border border-gray-800 px-3 py-3 text-sm font-medium text-white bg-gray-800 hover:bg-gray-700 focus:bg-gray-700 focus:ring sm:w-auto"
                placeholder='Enter your email'
                type='email'
                value={user.email}
                onChange={(e)=> setUser({...user,email:e.target.value})}
                name='email'
              />
            </div>
            <div className='mt-6 flex justify-center'>
              <a
                className="px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 text-white font-bold rounded-full transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg"
                href="#questions"
                >
                Join the wait list
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
      className="absolute z-[1] bottom-0 left-0 z-0 h-1/3 w-full border-b"
      style={{
        backgroundImage:
          "linear-gradient(to right top, rgba(134, 239, 172, 0.1) 0%, transparent 50%, transparent 100%)",
        borderColor: "rgba(92, 79, 240, 0.2)"
      }}
      ></div>
      <div
        className="absolute z-[1] bottom-0 right-0 z-0 h-1/3 w-full"
        style={{
          backgroundImage:
            "linear-gradient(to left top, rgba(147, 51, 234, 0.1) 0%, transparent 50%, transparent 100%)",
          borderColor: "rgba(92, 79, 240, 0.2)"
        }}
      ></div>
    </section>
    <section
      id="features"
      className="bg-gray-900 relative block px-6 py-10 md:py-20 md:px-10 border-t border-gray-800"
    >
    <div className="relative mx-auto max-w-5xl text-center">
      <span className="bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 bg-clip-text text-3xl font-extrabold text-transparent sm:text-5xl">
        Why XLRate?
      </span>
    </div>
    <div className="relative mx-auto max-w-7xl z-10 grid grid-cols-1 gap-10 pt-14 sm:grid-cols-2 lg:grid-cols-auto">
      {
        whyXLRate.map((item,idx) => (
          <div key={idx} className="rounded-md border border-gray-800 bg-gray-900 p-8 text-center shadow">
            <div
              className="button-text mx-auto flex h-12 w-12 items-center justify-center rounded-md border "
              style={{
                backgroundImage:
                  "linear-gradient(rgb(80, 70, 229) 0%, rgb(43, 49, 203) 100%)",
                borderColor: "rgb(93, 79, 240)"
              }}
            >
              {item.icon}
            </div>
            <h3 className="mt-6 text-gray-200 font-bold">{item.title}</h3>
            <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-300">
              {item.description}
            </p>
          </div>
        ))
      }
    </div>
    <div
      className="absolute bottom-0 left-0 z-0 h-1/3 w-full border-b"
      style={{
        backgroundImage:
          "linear-gradient(to right top, rgba(134, 239, 172, 0.1) 0%, transparent 50%, transparent 100%)",
        borderColor: "rgba(92, 79, 240, 0.2)"
      }}
    ></div>
    <div
      className="absolute bottom-0 right-0 z-0 h-1/3 w-full"
      style={{
        backgroundImage:
          "linear-gradient(to left top, rgba(147, 51, 234, 0.1) 0%, transparent 50%, transparent 100%)",
        borderColor: "rgba(92, 79, 240, 0.2)"
      }}
    ></div>
    </section>
    <section
      id="features"
      className="bg-gray-900 relative block px-6 py-10 md:py-20 md:px-10 border-b border-t border-gray-800"
    >
    <div className="relative mx-auto max-w-5xl text-center">
      <span className="bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 bg-clip-text text-2xl font-extrabold text-transparent sm:text-3xl">
        Experience the next level of performance and personal growth
      </span>
    </div>
    <div className="flex flex-col space-y-4 justify-center py-12 max-w-7xl mx-auto">
      {
        nextLavelGrowth.map((item,idx) => (
          <card key={idx} className={`border-indigo-400 ${idx%2 === 0? "self-start":"self-end"} border border-dashed hover:border-double rounded md:w-[70%] max-w-full py-7 px-5`}>
              <div className="grid grid-cols-6 gap-3">
                  {
                    idx%2 !== 0 && <div className="col-span-2 flex justify-center">
                                        <img src={item.imgURL} alt='' className='w-[100px] max-w-[100%] h-auto'/>
                                    </div>
                  }
                  
                  <div className="col-span-4">
                      <p className="text-gray-200 font-bold">{item.title}</p>
                      <p className="text-gray-300 mt-4">{item.description}</p>
                  </div>

                  {
                    idx%2 === 0 && <div className="col-span-2  flex justify-center">
                                        <img src={item.imgURL} alt='' className='w-[100px] max-w-[100%] h-auto'/>
                                    </div>
                  }
              </div>
          </card>
        ))
      }
    </div>
    <div
      className="absolute bottom-0 left-0 z-0 h-1/3 w-full border-b"
      style={{
        backgroundImage:
          "linear-gradient(to right top, rgba(134, 239, 172, 0.1) 0%, transparent 50%, transparent 100%)",
        borderColor: "rgba(92, 79, 240, 0.2)"
      }}
    ></div>
    <div
      className="absolute bottom-0 right-0 z-0 h-1/3 w-full"
      style={{
        backgroundImage:
          "linear-gradient(to left top, rgba(147, 51, 234, 0.1) 0%, transparent 50%, transparent 100%)",
        borderColor: "rgba(92, 79, 240, 0.2)"
      }}
    ></div>
    </section>
    <section
      id="questions"
      className="bg-gray-900 relative block px-6 py-10 md:py-20 md:px-10 border-b border-t border-gray-800"
    >
    <div className="relative mx-auto max-w-5xl text-center">
      <span className="bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 bg-clip-text text-xl font-extrabold text-transparent sm:text-xl">
        To better tailor XLRate to your needs, please answer the following questions during sign-up
      </span>
      {/* <p className="text-gray-400 sm:text-xl dark:text-gray-400">To better tailor XLRate to your needs, please answer the following questions during sign-up</p> */}
    </div>
    <div className="relative z-[10] flex flex-col space-y-4 justify-center py-12 max-w-7xl mx-auto">
        {/* first question  */}
        <div>
              <QuestionLabel>Enhancing my cognitive performance is critical to my professional success.</QuestionLabel>
              <BigWraper>
                {
                  ["Strongly Disagree","Disagree","Neutral","Agree","Strongly Agree"].map((item,idx) => (
                    <Wraper key={idx}>
                        <RadioButton onChange={handleChange} name={keys[0]} data={data} idx={idx} value={item}/>
                    </Wraper>
                  ))
                }
              </BigWraper>
              <QuestionLabel>What are your top three challenges in achieving optimal performance at work?</QuestionLabel>
              <BigWraper>
                {
                  ["Challenge 1","Challenge 2","Challenge 3"].map((item,idx) => (
                    <div className='flex justify-center'>
                        <Input type={"text"} value={data[keys[idx+1]]} name={keys[idx+1]} placeholder={item} onChange={handleChange}/>
                    </div>
                  ))
                }
              </BigWraper>
              <QuestionLabel>I am satisfied with my current managing and enhancing cognitive performance methods</QuestionLabel>
              <BigWraper>
                {
                  ["Strongly Disagree","Disagree","Neutral","Agree","Strongly Agree"].map((item,idx) => (
                    <Wraper key={idx}>
                      <RadioButton onChange={handleChange} name={keys[4]} data={data} idx={idx} value={item}/>
                    </Wraper>
                  ))
                }
              </BigWraper>
              <QuestionLabel>What would make XLRate indispensable to you?</QuestionLabel>
              <BigWraper>
                  <div className="flex items-center">
                      <Input type={"text"} value={data[keys[5]]} name={keys[5]} placeholder={"Enter here"} onChange={handleChange}/>
                  </div>
              </BigWraper>
              <QuestionLabel>I will likely recommend a product like XLRate to my peers or colleagues.</QuestionLabel>
              <BigWraper>
                {
                  ["Not Likely at All","Slightly Likely","Moderately Likely","Very Likely","Extremely Likely"].map((item,idx) => (
                    <Wraper key={idx}>
                      <RadioButton onChange={handleChange} name={keys[6]} data={data} idx={idx} value={item}/>
                    </Wraper>
                  ))
                }
              </BigWraper>
              <QuestionLabel>I would pay £5000 to do the 90 day XLRate Executive Development programme</QuestionLabel>
              <BigWraper>
                {
                  ["Strongly Disagree", "Disagree", "Neutral", "Agree", "Strongly Agree"].map((item,idx) => (
                    <Wraper key={idx}>
                      <RadioButton onChange={handleChange} name={keys[7]} data={data} idx={idx} value={item}/>
                    </Wraper>
                  ))
                }
              </BigWraper>
              <QuestionLabel>I would pay £50-£100 monthly for access to XLRate and 6 bespoke specialist performance coaching sessions</QuestionLabel>
              <BigWraper>
                {
                  ["Strongly Disagree","Disagree","Neutral","Agree","Strongly Agree"].map((item,idx) => (
                    <Wraper key={idx}>
                      <RadioButton onChange={handleChange} name={keys[8]} data={data} idx={idx} value={item}/>
                    </Wraper>
                  ))
                }
              </BigWraper>
              <QuestionLabel>I would participate in a beta testing program for XLRate if it meant providing feedback and testimonials for a reduced monthly price</QuestionLabel>
              <BigWraper>
                {
                  ["Definitely Not","Probably Not","Might or Might Not","Probably Would","Definitely Would"].map((item,idx) => (
                    <Wraper key={idx}>
                      <RadioButton onChange={handleChange} name={keys[9]} data={data} idx={idx} value={item}/>
                    </Wraper>
                  ))
                }
              </BigWraper>
              <QuestionLabel>If I decided not to use XLRate, it would most likely be due to the cost</QuestionLabel>
              <BigWraper>
                {
                  ["Strongly Disagree", "Disagree", "Neutral", "Agree", "Strongly Agree"].map((item,idx) => (
                    <Wraper key={idx}>
                        <RadioButton onChange={handleChange} name={keys[10]} data={data} idx={idx} value={item}/>
                    </Wraper>
                  ))
                }
              </BigWraper>
              <QuestionLabel>Which of the following outcomes would make you feel the investment in XLRate is worthwhile?</QuestionLabel>
              <BigWraper>
                {
                  ["Increased productivity", "better problem-solving", "accelerated learning", "increased fulfilment", "flow state optimisation","personal development"].map((item,idx) => (
                    <Wraper key={idx}>
                        <Checkbox idx={idx} value={item} onChange={handleChange} data={data} item={item} name={keys[11]}/>
                    </Wraper>
                  ))
                }
              </BigWraper>

              <div className='mt-6'>
                <button onClick={handleSubmit} class="w-full px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 text-white font-bold rounded-full transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg">
                    Join the wait list
                </button>
              </div>
        </div>
    </div>
    <div
      className="absolute z-[1] bottom-0 left-0 z-0 h-1/3 w-full border-b"
      style={{
        backgroundImage:
          "linear-gradient(to right top, rgba(134, 239, 172, 0.1) 0%, transparent 50%, transparent 100%)",
        borderColor: "rgba(92, 79, 240, 0.2)"
      }}
    ></div>
    <div
      className="absolute z-[1] bottom-0 right-0 z-0 h-1/3 w-full"
      style={{
        backgroundImage:
          "linear-gradient(to left top, rgba(147, 51, 234, 0.1) 0%, transparent 50%, transparent 100%)",
        borderColor: "rgba(92, 79, 240, 0.2)"
      }}
    ></div>
    </section>
  </>)
}


function QuestionLabel({children}){

  return  <p className="text-purple-400 sm:text-xl dark:text-gray-400 mb-4 mt-6">{children}</p>
};


function Checkbox({item,name,value,onChange,data}){

  return <>
    <input onChange={onChange} checked={data[name].indexOf(value) !== -1? true:false} id={item.toLocaleLowerCase()} type="checkbox" value={value} name={name} className="accent-purple-600 w-4 h-4 text-gray-600 bg-gray-400 border-gray-300 focus:ring-purple-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
    <label for={item.toLocaleLowerCase()} className="w-full py-4 mx-2 text-sm font-medium text-gray-400 dark:text-gray-300">{item}</label>
  </>
}


function RadioButton({value,name,idx,onChange,data}){


  return <>
      <input onChange={onChange} id={`${name}-${idx}`} checked={data[name] === value? true:false} type="radio" value={value} name={name} className="accent-purple-600 w-4 h-4 text-gray-600 bg-gray-400 border-gray-300 focus:ring-purple-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
      <label for={`${name}-${idx}`} className="w-full py-4 mx-2 text-sm font-medium text-gray-400 dark:text-gray-300">{value}</label>
  </>
}

function Input({type,name,placeholder,value,onChange}){


  return (<>
    <input type={type} onChange={onChange} id={name} name={name} value={value}  placeholder={`${placeholder}..`}  className="bg-purple-600/10 border border-gray-800 text-gray-300 text-sm rounded-lg focus:ring-purple-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
  </>)
}

function Wraper({children}){


  return <>
  <div className='flex items-center px-4 border border-gray-800 bg-purple-600/10 rounded'>
      {children}
  </div>
  </>
}

function BigWraper({children}){

  return (<>
  <div className='flex gap-2 flex-wrap justify-start mb-11'>
    {children}
  </div>
  </>)
}
