import React from 'react'

function Button(props) {
    const myProps = {...props};
    let clsName = "inline-block rounded-lg bg-indigo-600 text-sm shadow-xl disabled:opacity-50 ";
  
    if(myProps.className){
        clsName += `${props.className}`;
        delete myProps.className;
    }
  
  
    return (
        <button className={clsName} {...myProps}>
            {props.children}
        </button>
    )
}

export default Button;


