import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { orderOptions } from "../utils";
import { Options, ProgressBarAndBackButton, Wrapper } from "./index";
import { PrimaryButton} from "../../../components";
import { updateLoggedInUser } from "../../../Store/actions";
import {TempMain} from "../../../layout";


function PersonalImprovementObstacles({setScreen}){
    const loggedInUser = useSelector(state => state.loggedInUser);
    const [options, setOptions] = useState(()=> {
        return [
            {
                order: null, 
                option: "Feeling Overwhelmed",
                isSelected: false,
                tag: "Emotional Barriers"
            },
            {
                order: null, 
                option: "Fear of Failure",
                isSelected: false,
                tag: "Emotional Barriers"
            },
            {
                order: null, 
                option: "Confidence Issues",
                isSelected: false,
                tag: "Emotional Barriers"
            },
            {
                order: null, 
                option: "Stress and Burnout",
                isSelected: false,
                tag: "Emotional Barriers"
            },
            {
                order: null, 
                option: "Imposter Syndrome",
                isSelected: false,
                tag: "Emotional Barriers"
            },
            {
                order: null, 
                option: "Limited Skillset",
                isSelected: false,
                tag: "Skillset Limitations"
            },
            {
                order: null, 
                option: "Lack of Clear Goals",
                isSelected: false,
                tag: "Skillset Limitations"
            },
            {
                order: null, 
                option: "Decision-Making Pressures",
                isSelected: false,
                tag: "Skillset Limitations"
            },
            {
                order: null, 
                option: "Procrastination",
                isSelected: false,
                tag: "Motivational Challenges"
            },
            {
                order: null, 
                option: "Lack of Direction",
                isSelected: false,
                tag: "Motivational Challenges"
            },
            {
                order: null, 
                option: "Seeking Motivation",
                isSelected: false,
                tag: "Motivational Challenges"
            },
            {
                order: null, 
                option: "Finding Focus",
                isSelected: false,
                tag: "Motivational Challenges"
            },
            {
                order: null, 
                option: "Balancing High-Stakes Responsibilities",
                isSelected: false,
                tag: "Leadership Challenges"
            },
            {
                order: null, 
                option: "Managing Large Teams",
                isSelected: false,
                tag: "Leadership Challenges"
            },
            {
                order: null, 
                option: "Lack of Support",
                isSelected: false,
                tag: "Leadership Challenges"
            },
            {
                order: null, 
                option: "Fostering Better Workplace Relationships",
                isSelected: false,
                tag: "Leadership Challenges"
            },
            {
                order: null, 
                option: "Enhancing Work-Life Balance",
                isSelected: false,
                tag: "Work-Life Balance"
            },
        ];
    });
    const dispatch = useDispatch();

    const handleBack = () => {
        setScreen(prev => {
            return {
                ...prev,
                personalImprovementGoals: true,
                personalImprovementObstacles: false
            }
        })
    }

    const handleClick = (item) => {
        setOptions(prev => orderOptions(prev,item))
    }

    const handleNext = async () => {
        setScreen(prev => {
            return {
                ...prev,
                personalImprovementObstacles: false,
                finalProjection: true
            }
        });
        dispatch(updateLoggedInUser({isPersonalImprovementObstaclesSeen: true,personalImprovementObstacles: options.filter(it => it.isSelected).sort((a,b) => a.order-b.order).map(it => it.option)}));
    }

    useEffect(() => {
        if(loggedInUser.personalImprovementObstacles){
            setOptions((prev) => {
                return prev.map(op => {
                    return loggedInUser.personalImprovementObstacles.indexOf(op.option) === -1? op:{...op,isSelected: true,order: loggedInUser.personalImprovementObstacles.indexOf(op.option)+1};
                })
            })
        }
    }, [loggedInUser.personalImprovementObstacles]);

    return (<>
        <TempMain>
            <Wrapper>
                <ProgressBarAndBackButton handleBack={handleBack} completedSteps={3}/>
                <h1 className='text-2xl font-semibold text-secondry-color mb-2'>Identify Barriers to Your Peak Performance and Growth</h1>
                <p className="text-secondry-color">Select the challenges that resonate most with you so we can tailor our support to help you overcome them</p>

                <Options options={options} handleClick={handleClick}/>

                <div className="mt-10">
                    <PrimaryButton onClick={handleNext}>
                        Continue to Overcome My Barriers
                    </PrimaryButton>
                </div>
            </Wrapper> 
        </TempMain> 
    </>)
}


export {PersonalImprovementObstacles};