import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    // AllowNotifications, 
    EnterActivityTitle, 
    FinalProjection, 
    Intro, 
    PersonalImprovementGoals, 
    PersonalImprovementObstacles, 
    Welcome
} from "./components";
import {CreatePrePostSurvey} from "../index";


function Index() {
    const loggedInUser = useSelector(state => state.loggedInUser);
    const [screensVisibility,setScreensVisibility] = useState({
        welcome: !loggedInUser.isWelcomeSeen,
        personalImprovementGoals: !loggedInUser.isPersonalImprovementGoalsSeen,
        personalImprovementObstacles: !loggedInUser.isPersonalImprovementObstaclesSeen,
        finalProjection: !loggedInUser.isFinalProjectionSeen,
    });
    const [isShowIntro, setIsShowIntro] = useState(!loggedInUser.isIntroSeen);
    const [isShowCreatePrePostSurvey, setIsShowCreatePrePostSurvey] = useState(!loggedInUser.isPreSurveyDone);
    // const [isShowAllowNotificationsPage, setIsShowAllowNotificationsPage] = useState(!loggedInUser.isNotificationPageDone);

    if(isShowIntro){
        return <Intro setIsShow={setIsShowIntro}/>;
    }

    // if(isShowAllowNotificationsPage){
    //     return <AllowNotifications setIsShow={setIsShowAllowNotificationsPage}/>;
    // }

    if(isShowCreatePrePostSurvey){
        return <CreatePrePostSurvey setIsShow={setIsShowCreatePrePostSurvey}/>;
    }

    if(screensVisibility.welcome){
        return <Welcome setScreen={setScreensVisibility}/>
    }

    if(screensVisibility.personalImprovementGoals){
        return <PersonalImprovementGoals setScreen={setScreensVisibility}/>
    }

    if(screensVisibility.personalImprovementObstacles){
        return <PersonalImprovementObstacles setScreen={setScreensVisibility}/>
    }

    if(screensVisibility.finalProjection){
        return <FinalProjection setScreen={setScreensVisibility}/>
    }

    return <EnterActivityTitle setScreen={setScreensVisibility}/>
};


export default Index;


 








