import { useState } from "react";
import { useSelector } from "react-redux";
import {defaultRadioValues} from "../constants";
import moment from "moment";
import { toast } from "react-toastify";
import { 
    createNotification, 
    updateNotification } from "../../../Store/actions";
import { InputSelect } from "./InputSelect";
import { InputWithLabel } from "./InputWithLabel";
import { RadioButton } from "./RadioButton";


export function NotificationModal({setIsShowModal,setNotifications,notificationToEdit,setNotificationToEdit}){
    const loggedInUser = useSelector(state => state.loggedInUser);
    const [activities, setActivities] = useState(()=> loggedInUser.activities.map(ac => {
        if(notificationToEdit){
            if(notificationToEdit.activityCategory === ac.category){
                return {...ac,isSelected: true};
            }
        }
        return {...ac,isSelected: false}
    }));
    const [radios, setRadios] = useState(() => {
        return defaultRadioValues.map(day => {
            if(notificationToEdit){
                const d = notificationToEdit.days.find(d => d.day === day.day);
                if(!d){
                    return day;
                }
                return {...day,times: d.times.map(time => `${time.hour}:${time.minute}`),isChecked: true}
            }
            return day;
        });
    });
    const [dates, setDates] = useState({startDate: setInputDates(notificationToEdit?.startDate),endDate: setInputDates(notificationToEdit?.endDate)});
    // const [selectedSurveys, setSelectedSurveys] = useState(null);

    const handleChange = (e) => {
        setDates(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    function setInputDates(date){
        if(date){
            return moment(date).format("YYYY-MM-DD");
        }
        return "";
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(!dates.startDate || !dates.endDate){
            toast.error("Mising Start or End Date");
            return;
        }
        const selectedActivity = activities.find(act => act.isSelected);
        if(!selectedActivity){
            toast.error("Mising Activity");
            return;
        }
        const selectedDay = radios.find(item => item.isChecked);
        if(!selectedDay){
            toast.error("Select at least one day");
            return;
        }

        const arrargeDays = (day) => {
            const times = day.times.map(time => {
                let t = time.split(":");
                let hour = Number(t[0]);
                let minute = Number(t[1]);
                const dateTime = new Date();
                dateTime.setHours(hour);
                dateTime.setMinutes(minute);
                return {hour,minute,time: dateTime};
            });
            return {day: day.day,times};
        }

        const data = {
            startDate: dates.startDate,
            endDate: dates.endDate,
            days: radios.filter(day => day.isChecked).sort((a,b) => a.day - b.day).map(arrargeDays),
            activityName: selectedActivity.name,
            activityCategory: selectedActivity.category,
        };

        if(selectedActivity.isNonUniversalSurvey){
            data.surveytitle = selectedActivity._id;
            delete data.activityCategory;
            delete data.activityName;
        }

        if(notificationToEdit){
            const res = await updateNotification(notificationToEdit._id,data);
            if(res.success){
                toast.success("Notification Updated Successfuly!");

                setNotifications(prev => {
                    return prev.map(noti => noti._id === res.notification._id? {...res.notification}:noti);
                });

                setIsShowModal(false);
                setNotificationToEdit(null);
            }
            else {
                toast.error(res.message);
            }
            return;
        }

        const res = await createNotification(data);
        
        
        if(res.success){
            toast.success("Notification Created Successfuly!");
            setNotifications(prev => {
                return [...prev,res.notification]
            });
            setIsShowModal(false);
            setNotificationToEdit(null);
        }
        else {
            toast.error(res.message);
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        setIsShowModal(false);
        setNotificationToEdit(null);
    }


    // useEffect(() => {
    //     (async ()=> {
    //             const resUser = await getUserById(loggedInUser._id);
    //             const res = await getAllSurveyTitles();
    //             if(resUser.success && res.success){
    //               const selectedSurveys = res.surveyTitles.filter(st => resUser.user?.unRegularSurveys.indexOf(st._id) !== -1);
    //               setActivities(prev => {
    //                 const temp = selectedSurveys.map(st => {
    //                     if(notificationToEdit && !notificationToEdit.activityCategory){
    //                         if(notificationToEdit.surveytitle.title === st.title){
    //                             return {category: st.title,name: "",_id: st._id,isSelected: true,isNonUniversalSurvey: true}
    //                         }
    //                     }
    //                     return {category: st.title,name: "",_id: st._id,isSelected: false,isNonUniversalSurvey: true}
    //                 })
    //                 return [...prev,...temp];
    //               })
    //             }
    //             else {
    //             }
    //     })()
    // }, [loggedInUser._id,notificationToEdit]);


    return <>
        <div className='w-full h-[calc(100vh-64px)] overflow-auto bg-black/40 absolute top-[64px] left-0 flex justify-center items-center pt-[20px]'>
            <div className='max-w-[500px] w-full min-h-[400px] bg-white rounded-md'>
                <form className='w-full  p-6 py-10 my-5'>
                    <InputSelect notificationToEdit={notificationToEdit} activities={activities} setActivities={setActivities}/>
                    <InputWithLabel label='Start Date' name="startDate" value={dates.startDate} onChange={handleChange}/>
                    <InputWithLabel label='End Date' name="endDate" value={dates.endDate} onChange={handleChange}/>
                    <RadioButton radios={radios} setRadios={setRadios}/>
                    <div className='flex gap-4'>
                        <button onClick={handleCancel} className={`w-full p-2.5 flex-1 rounded-md ring-offset-2  focus:ring-2  bg-white border text-gray-400 ring-gray-400`}>
                            Cancel
                        </button>
                        <button onClick={handleSubmit} className={`w-full p-2.5 flex-1 rounded-md ring-offset-2  focus:ring-2 bg-blue-400 ring-blue-400`}>
                            {notificationToEdit? 'Update':'Create'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </>
}