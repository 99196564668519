import React,{useEffect,useState} from 'react';
import {CompletedSurvey} from "./components";
import {
  getAllPrePostSurveys,
  getAllUsersSurnameForename,
  getAllGroups,
  getAllSurveyTitles
} from "../../Store/actions";
import { toast } from 'react-toastify';
import {Loading, Tbody, Th, Thead, Tr} from "../../components"; 
import {Main} from "../../layout"; 
import {Table} from "../../components";
import { CSVLink } from 'react-csv';
import { mapper__100_100_to_0_100 } from '../../utils';
import { XL_FACTORS } from '../../constants';



export const questions = new Array(20).fill(1).map((v,i) => i+1);
const tableHeaders = [
  "#",
"User Name",
"User Email",
"Org.",
"Dept.",
"Group",
"S. Title",
"Creation",
"Survey Dur",
...questions,
"Exclude"
]


function Index() {

  const [completedSurveys,setCompletedSurveys] = useState(null);
  const [users,setUsers] = useState([]);
  const [groups,setGroups] = useState([]);
  const [surverTitles,setSurverTitles] = useState([]);
  const [excludedFields,setExcludedFields] = useState([]);
  const [loading,setLoading] = useState(true);
  const [surveyFound,setSurveyFound] = useState(false);
  const [isShowData,setIsShowData] = useState(false);
  const [query,setQuery] = useState({
    all: false,
    showExcluded: false,
  });
  const headerStr = JSON.stringify(tableHeaders.filter((item,i) => excludedFields.indexOf(i+1) === -1));
  const bodyStr = completedSurveys? JSON.stringify(
    completedSurveys.map((survey,i) => {
      const group = groups.find(gp => {
        const user = gp.users?.find(user => user._id === survey?.user?._id);
        if(user){
          return gp
        }
        return undefined 
      })

      const arr = [
        i+1,
      `${survey?.user?.forename}${survey?.user?.surname}`,
      survey?.user?.email,
      "org",
      "dept",
      group?.groupname || "NA",
      survey?.surveytitle?.title,
      new Date(survey?.createdAt).toLocaleString(),
      ...questions.map(num => {
          const ans = survey.answers.find(an => Number(an.XLFactor) === num);
          if(!ans){
            return "NA"
          }
          return mapRange(ans.rating,1,7,0,100)
      })
      ]

      return arr.filter((item,i) => excludedFields.indexOf(i+1) === -1);
    })
  ):"";
  const csvData = prepareCSVData(completedSurveys,groups);


  function mapRange(value, inMin, inMax, outMin, outMax) {
    const ans = ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
    return ans.toFixed(2)
  }

  const handleInclueExcludeNumber = (number) => {
    setExcludedFields(prev => {
      const isExist = prev.find(n => n === number)
      if(isExist){
        return prev.filter(n => n !== number);
      }
      return [...prev,number]
    })
  }

  const  downloadTxtFile = ()=> {
    const text = `${headerStr}${bodyStr}`;
    const filename = "sample_file.txt";
  
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);
  
    element.style.display = "none";
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
  }


  useEffect(() => {
      (async ()=>{
        setLoading(true);

        let queryStr = "";
        const arr = Object.keys(query);
        for (let index = 0; index < Object.keys(query).length; index++) {
          const key = arr[index];  
          queryStr += `${key}=${query[key]}${arr.length-1 > index? "&&":""}`;
        }

        const res = await getAllPrePostSurveys(queryStr);
      
        setLoading(false);
        if(res.success){
          const temp = res.prePostSurveys.sort((a,b)=> new Date(b.createdAt).getTime()-new Date(a.createdAt).getTime());
        
          setCompletedSurveys(temp);
          setSurveyFound(temp.length > 0? true:false);
        }
        else {
          toast.error("Someting Went Wrong!")
        }
        
      })()
  }, [query]);

  useEffect(() => {
    (async ()=> {
      const res = await getAllUsersSurnameForename();
      if(res.success){
        setUsers(res.users);
      }
      const res1 = await getAllGroups();
      if(res1.success){
        setGroups(res1.groups);
      }
      const res2 = await getAllSurveyTitles();
      if(res2.success){
        const unRegularSurveys = res2.surveyTitles.filter(s => !s.isUniversal);
        setSurverTitles(unRegularSurveys);
      }
    })()
  }, []);

  if(loading){
    return <Loading/>
  }

  
  return (
    <>
    <Main containerClasses={"bg-white"}>
        <FilterOptions setQuery={setQuery} users={users} surverTitles={surverTitles} groups={groups}/>
        <div className="flex gap-3">
          {
            bodyStr !== "[]" && <button onClick={downloadTxtFile} className='bg-blue-600 text-white rounded-md px-4 py-1'>Download .txt File</button>
          }
          {
            (bodyStr !== "[]" && csvData) && <button className='bg-blue-600 text-white rounded-md px-4 py-1'><CSVLink data={csvData} className='text-white no-underline'>Download .csv File</CSVLink></button>
          }
          {
            bodyStr !== "[]" && <button onClick={()=> setIsShowData(p => !p)} className='bg-blue-600 text-white rounded-md px-4 py-1'>{isShowData? "Hide":"Show"} Data</button>
          }
        </div>
        <div className="">
          {
            (bodyStr !== "[]" && isShowData) &&
              <>
               <div className="mt-5 flex flex-wrap gap-2">
                {
                  tableHeaders.map((val,i) => (
                    <span  onClick={()=>handleInclueExcludeNumber(i+1)} style={{backgroundColor: excludedFields.indexOf(i+1) === -1? "":"red"}}  className='cursor-pointer bg-blue-900 text-white rounded-md p-1 text-[10px]'>{val}</span>
                    ))
                  }  
                </div>
                { headerStr }
                { bodyStr }
              </>
          }
        </div>
        
        {
          !surveyFound && <SomethingNotFound someting={"Surveys"}/>
        }
        {
          (!loading && surveyFound)  &&  <> 
          <div className="w-full mt-5">
            <Table>
                <Thead>
                  <Tr>
                    {
                      tableHeaders.map((th,idx) => <Th key={idx}>{th}</Th>)
                    }
                  </Tr>
                </Thead>
                <Tbody>
                    {completedSurveys && completedSurveys.map((item,index)=> {
                                                      const group = groups.find(gp => {
                                                        const user = gp.users?.find(user => user._id === item?.user?._id);
                                                        if(user){
                                                          return gp
                                                        }
                                                        return undefined 
                                                      })
                                                      return <CompletedSurvey 
                                                      survey={item} 
                                                      key={index} 
                                                      index={index}
                                                      group={group}
                                                      />
                    })}
                </Tbody>
            </Table>
          </div>
          </>
        }
    </Main>
    </>
  )
}

export default Index;



function FilterOptions({setQuery,users,surverTitles,groups}){
  const [showAll,setShowAll] = useState(false);
  const [showExcluded,setShowExcluded] = useState(false);
  const [userSearchTerm,setUserSearchTerm] = useState("");
  const [surveySearchTerm,setSurveySearchTerm] = useState("");
  const [groupSearchTerm,setGroupSearchTerm] = useState("");

  const handleShowAllChange = () => {
    setShowAll(p=>!p);
    setQuery(prev => {
        return {
          all: !showAll,
          userId: "",
          showExcluded: true
        }
    })
  }

  const handleShowExcludedChange = () => {
    setShowExcluded(p=>!p);
    setQuery(prev => {
      return {
        all: true,
        userId: "",
        showExcluded: !showExcluded
      }
    })
  }

  const handleUserSellect = (_id) => {
    setUserSearchTerm("");
    setQuery(prev => {
      return {
        showExcluded: true,
        all: true,
        userId: _id
      }
    })
  }

  const handleSurveyTitleSelect = (_id) => {
    setSurveySearchTerm("");
    setQuery(prev => {
      return {
        showExcluded: true,
        all: true,
        surveyTitle: _id
      }
    })
  }

  const extrectAllUserIds = (group) => {
    let userIds = "";
    visit(group);
    function visit(node){
      const temp = node.users?.map(u => u._id);
      userIds = [...userIds,...temp];
      
      if(node.children){
        node.children.forEach(child => {
          const foundGroup = groups.find(g => g._id === child._id);
          if(foundGroup){
            visit(foundGroup);
          }
        })
      }
    }
    return userIds.join(",");
  }

  const handleGroupSellect = (group) => {
    const userIds = extrectAllUserIds(group);    

    setGroupSearchTerm("");
    setQuery(prev => {
      return {
        showExcluded: true,
        all: true,
        users: userIds || null
      }
    })
  }

 

  return (
    <>
     <div className='py-10'>
        <div className="flex gap-10 items-center flex-wrap">
            <div className="block min-h-6 pl-7">
              <label className='flex items-center gap-3'>
                <input checked={showAll} onChange={handleShowAllChange} id="checkbox-1" className="w-4 h-4 ease-soft text-base -ml-7 rounded-1.4" type="checkbox" />
                <label htmlFor="checkbox-1" className="cursor-pointer select-none text-slate-700">Show All</label>
              </label>
            </div>
            <div className="block min-h-6 pl-7 relative">
              <label className='flex items-center gap-3'>
                <input checked={showExcluded} onChange={handleShowExcludedChange} id="checkbox-1" className="w-4 h-4 ease-soft text-base -ml-7 rounded-1.4" type="checkbox" />
                <label htmlFor="checkbox-1" className="cursor-pointer select-none text-slate-700">Show Excluded</label>
              </label>
            </div>
            <div className="block min-h-6 pl-7">
              <input value={userSearchTerm} onChange={(e)=> setUserSearchTerm(e.target.value)} type="search" id="default-search" className="block w-full p-4 py-1 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 " placeholder="Search User.." />
              {
                userSearchTerm && <div className="absolute bg-white w-[198px] rounded-md p-2">
                                    {
                                      users.filter(user => `${user.forename} ${user.surname}`.toLowerCase().includes(userSearchTerm.toLowerCase())).map(user => (
                                        <p onClick={()=> handleUserSellect(user._id)} className='cursor-pointer'>{user.forename} {user.surname}</p>
                                      ))
                                    }
                                  </div>
              }
            </div>
            <div className="block min-h-6 pl-7 relative">
                <input value={surveySearchTerm} onChange={(e)=> setSurveySearchTerm(e.target.value)} type="search" id="default-search" className="block w-full p-4 py-1 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 " placeholder="Search Survey.." />
                  {
                    surveySearchTerm && <div className="absolute bg-white w-[198px] rounded-md p-2">
                                        {
                                          surverTitles.filter(survey => `${survey.title}`.toLowerCase().includes(surveySearchTerm.toLowerCase())).map(survey => (
                                            <p onClick={()=> handleSurveyTitleSelect(survey._id)} className='cursor-pointer'>{survey.title}</p>
                                          ))
                                        }
                                      </div>
                  }
            </div>
            <div className="block min-h-6 pl-7">
              <input value={groupSearchTerm} onChange={(e)=> setGroupSearchTerm(e.target.value)} type="search" id="default-search" className="block w-full p-4 py-1 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 " placeholder="Search Group.." />
              {
                groupSearchTerm && <div className="absolute bg-white w-[198px] rounded-md p-2">
                                    {
                                      groups.filter(group => `${group.groupname}`.toLowerCase().includes(groupSearchTerm.toLowerCase())).map(group => (
                                        <p onClick={()=> handleGroupSellect(group)} className='cursor-pointer'>{group.groupname}</p>
                                      ))
                                    }
                                  </div>
              }
            </div>
        </div>
     </div>
   
    </>
  )
}

function SomethingNotFound({someting}){
  return (
    <>
    <div className="flex justify-center items-center">
    <h5>{someting} Not Found!</h5>
    </div>
    </>
  )
}


function prepareCSVData(completedSurveys,groups){

  const calcullateTotalDuration = (survey)=> {
    let totalTime = 0;

    for (const key in survey) {
      if(XL_FACTORS.indexOf(key) !== -1){
          const ans = survey[key];
          totalTime += (ans.endtime - ans.starttime); 
      }
    }
 
    return parseFloat((totalTime/1000)/60).toFixed(2) + " Mins";
  }

  const mapRange =  (value, inMin, inMax, outMin, outMax)=> {
    const ans = ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
    return ans.toFixed(2)
  }

  if(!completedSurveys){
    return null;
  }

  const array = [[...tableHeaders,"Survey Name"]];

  completedSurveys.forEach((survey,i) => {
    const group = groups?.find(gp => {
      const user = gp.users?.find(user => user._id === survey?.user?._id);
      if(user){
        return gp
      }
      return undefined 
    })
    const duration = calcullateTotalDuration(survey);
    const factors = XL_FACTORS.map(XLFactor => {
      return survey[XLFactor] ? mapRange(survey[XLFactor].rating.$numberDecimal,1,7,0,100) : "NA";
    });
    const eventTags = survey?.event?.tags?.map(tag => tag);
    const eventFeelings = survey?.event?.feelings?.map(tag => tag);
  
    const arr = [
      i+1,
      `${survey.user?.forename} ${survey.user.surname}`,
      survey.user?.email,
      survey.user?.organizationName,  
      survey.user?.departent,  
      group?.groupname,  
      survey?.surveytitle?.title,
      survey.surveyName,
      survey?.user?.activities.find(ac => ac.category === survey.surveyName)?.name || "",  
      new Date(survey?.createdAt).toLocaleString().split(",")[0],  
      new Date(survey?.createdAt).toLocaleString().split(",")[1].trim(),  
      duration,
      ...factors,
      survey.lxscore,
      mapper__100_100_to_0_100(survey?.lxscore).toFixed(2),
      survey.minLXScore,  
      survey.maxLXScore,
      `${survey?.event?.description} ${eventTags?.join(",")}`,
      survey?.xlChangeFromLastScore,
      survey?.isexcluded,
      eventFeelings?.join(","),
      survey.surveyName 
    ]

    array.push(arr);
  })

  return array;
}

