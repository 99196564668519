import React,{useEffect, useState} from 'react';
import {
  createUsers,
  getAllSurveyTitles,
  getAllGroups
} from "../../Store/actions";
import {Main} from "../../layout";
import * as xlsx from "xlsx";
import {ACTIVITIES} from "../../constants";
import moment from 'moment/moment';
import { toast } from 'react-toastify';

function Index() {
  const [data, setData] = useState(null);
  const [groups, setGroups] = useState(null);
  const [prePostSurvey, setPrePostSurvey] = useState(null);


  const getKeysFromSheet =  (worksheet)=> {
    const keys = [];
    for (const key in worksheet) {
      if(Number(key.split("")[key.split("").length-1]) === 1 && isNaN(Number(key.split("")[key.split("").length-2]))){
        keys.push(worksheet[key].v);
      }
    }
    return keys;
  }

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = xlsx.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const keys = getKeysFromSheet(worksheet);
            const values = xlsx.utils.sheet_to_json(worksheet);
            setData({keys,values});
        };
        reader.readAsArrayBuffer(e.target.files[0]);
    }
  }

  useEffect(() => {
    (async ()=> {
      const stRes = await getAllSurveyTitles();
      const grpRes = await getAllGroups();
      if(stRes.success){
        setPrePostSurvey(stRes.surveyTitles);
      }
      if(grpRes.success){
        setGroups(grpRes.groups);
      }
    })()
  }, []);
  
  return (
    <Main>
          <h2 className="pt-10 text-center mb-10 pb-3 border-b">Add Client Users</h2>

          <div className='flex gap-10 justify-center items-center'>
            <input type="file" accept=".xlsx,.xls,.csv" onChange={readUploadFile} name="exelFile" id="exelFile"/>
          </div>

          {
            data && <Users prePostSurvey={prePostSurvey || []} data={data} groups={groups || []} setData={setData}/>
          }
    </Main>
  )
}

export default Index;



function Users({data,groups,setData,prePostSurvey}) {
  const [activities, setActivities] = useState(()=>ACTIVITIES.map(ac => {return{checked: false,category: ac,nameChecked: false,name: ""}}));

  if(!data.values[0]){
    return <div className="">Invalid Data</div>
  }

  const handleGroupChange = (e) => {
    if(!e.target.value){
      setData(prev => {
        return {
          ...prev,
          values: prev.values.map(val => {
            delete val.group;
            delete val.groupId;
            return val;
          }),
          keys: prev.keys.filter(key => key !== "group")
        }
      })
      return;
    }

    const group = JSON.parse(e.target.value);
    setData(prev => {
      return {
        ...prev,
        values: prev.values.map(val => {return {...val,group: group.groupname,groupId: group._id}}),
        keys: [...new Set([...prev.keys,"group"])]
      }
    })
  }

  const handleActivityCountChange = (e) => {
    const number = Number(e.target.value);
    
    if(!e.target.value){
      setData(prev => {
        return {
          ...prev,
          values: prev.values.map(val => {
            delete val.activitiesCount;
            return val;
          }),
          keys: prev.keys.filter(key => key !== "activitiesCount")
        }
      })
      return;
    }

    setData(prev => {
      return {
        ...prev,
        values: prev.values.map(val => {return {...val,activitiesCount: number}}),
        keys: [...new Set([...prev.keys,"activitiesCount"])]
      }
    })
  }

  const handlePreSurveyChange = (e) => {
    if(!e.target.value){
      setData(prev => {
        return {
          ...prev,
          values: prev.values.map(val => {
            delete val.preSurveyId;
            delete val.preSurvey;
            return val;
          }),
          keys: prev.keys.filter(key => key !== "preSurvey")
        }
      })
      return;
    }

    const survey = JSON.parse(e.target.value);
    setData(prev => {
      return {
        ...prev,
        values: prev.values.map(val => {return {...val,preSurvey: survey.title,preSurveyId: survey._id}}),
        keys: [...new Set([...prev.keys,"preSurvey"])]
      }
    })
  }

  const handlePostSurveyChange = (e) => {
    if(!e.target.value){
      setData(prev => {
        return {
          ...prev,
          values: prev.values.map(val => {
            delete val.postSurveyId;
            delete val.postSurvey;
            return val;
          }),
          keys: prev.keys.filter(key => key !== "postSurvey")
        }
      })
      return;
    }

    const survey = JSON.parse(e.target.value);
    setData(prev => {
      return {
        ...prev,
        values: prev.values.map(val => {return {...val,postSurvey: survey.title,postSurveyId: survey._id}}),
        keys: [...new Set([...prev.keys,"postSurvey"])]
      }
    })
  }

  const handleOnboardingEmailTimingChange = (e) => {
    if(!e.target.value){
      setData(prev => {
        return {
          ...prev,
          values: prev.values.map(val => {
            delete val.onbordingEmailTime;
            return val;
          }),
          keys: prev.keys.filter(key => key !== "onbordingEmailTime")
        }
      })
      return;
    }

    setData(prev => {
      return {
        ...prev,
        values: prev.values.map(val => {return {...val,onbordingEmailTime: e.target.value}}),
        keys: [...new Set([...prev.keys,"onbordingEmailTime"])]
      }
    })
  }

  const handlePostSurveyTimingChange = (e) => {
    if(!e.target.value){
      setData(prev => {
        return {
          ...prev,
          values: prev.values.map(val => {
            delete val.postSurveyDate;
            return val;
          }),
          keys: prev.keys.filter(key => key !== "postSurveyDate")
        }
      })
      return;
    }

    setData(prev => {
      return {
        ...prev,
        values: prev.values.map(val => {return {...val,postSurveyDate: e.target.value}}),
        keys: [...new Set([...prev.keys,"postSurveyDate"])]
      }
    })
  }

  const handleSubmit = async () => {
    const requiredActivities = activities.filter(act => act.checked).map(act => act.nameChecked? {category: act.category,name: act.name}:{category: act.category});
    const users = data.values.map(user => {return {...user,requiredActivities}});
    const dif = moment(users[0].onboardingEmailTime).diff(moment(new Date()),"minutes");
    console.log(dif);
    const res = await createUsers({users});
    if(res.success){
      toast.success("Users created successfully!");
    }
    else {
      toast.error(res.message)
    }
  }

  const isDisabled = !data.values[0]?.group || !data.values[0]?.activitiesCount || !data.values[0]?.requiredActivities || !data.values[0]?.onbordingEmailTime || !data.values[0]?.preSurvey || !data.values[0]?.postSurvey || !data.values[0]?.postSurveyDate;


  return (
    <div className="h-[600px] w-full bg-white p-5 mt-5 overflow-auto">
      <div className="pb-3 border-b">
        <table className='w-full table-auto border-collapse border border-slate-400 '>
        <thead>
            <tr>
              {
                data.keys.map(key => <th className='px-2 py-1 border border-slate-300' key={key}>{key}</th>)
              }
            </tr>
        </thead>
        <tbody>
          {
            data.values.map((item,index) => (
              <tr key={index}>
                {
                  data.keys.map((key,i) => <td className='px-2 py-1 border border-slate-300' key={i}>{key === "emailBody"? <span>{`${item[key]}`.substring(0,5)}...</span>:item[key]}</td>)
                }
              </tr>
            ))
          }
        </tbody>
        </table>
      </div>
      <div className="mt-3">
        <div className="w-full flex gap-3">
            <div className="">
              <label htmlFor="group">Group</label>
              <select onChange={handleGroupChange} name="group" id="group" className='mt-1 w-full border p-2 focus:ring-4 focus:outline-none focus:ring-blue-300'>
                <option value="">Select Group</option>
                {
                  groups.map(group => <option key={group._id} value={JSON.stringify(group)}>{group.groupname}</option>)
                }
              </select>
            </div>
            <div className="">
              <label htmlFor="activitiesCount">Activities Count</label>
              <select onChange={handleActivityCountChange} name="activitiesCount" id="activitiesCount" className='mt-1 w-full border p-2 focus:ring-4 focus:outline-none focus:ring-blue-300'>
                <option value="">Select Activities Count</option>
                {
                  new Array(5).fill(1).map((one,i) => <option key={i} value={i+1}>{i+1}</option>)
                }
              </select>
            </div>
            <div className="">
              <label htmlFor="preSurvey">Pre Survey</label>
              <select onChange={handlePreSurveyChange} name="preSurvey" id="preSurvey" className='w-full mt-1 border p-2 focus:ring-4 focus:outline-none focus:ring-blue-300'>
                <option value="">Select Pre Survey</option>
                {
                  prePostSurvey && prePostSurvey.map((survey,i) => <option key={survey._id} value={JSON.stringify(survey)}>{survey.title}</option>)
                }
              </select>
            </div>
            <div className="">
              <label htmlFor="preSurvey">Post Survey</label>
              <select onChange={handlePostSurveyChange} name="postSurvey" id="postSurvey" className='w-full mt-1 border p-2 focus:ring-4 focus:outline-none focus:ring-blue-300'>
                <option value="">Select Post Survey</option>
                {
                  prePostSurvey && prePostSurvey.map((survey,i) => <option key={survey._id} value={JSON.stringify(survey)}>{survey.title}</option>)
                }
              </select>
            </div>
            <div className="">
              <label htmlFor="postSurveyDate">Post Survey Date</label>
              <input onChange={handlePostSurveyTimingChange} type="datetime-local" name="postSurveyDate" id="postSurveyDate" className='w-full mt-1 border p-2'/>
            </div>
            <div className="">
              <label htmlFor="onbordingEmailTime">Onboarding Email Time</label>
              <input onChange={handleOnboardingEmailTimingChange} type="datetime-local" name="onbordingEmailTime" id="onbordingEmailTime" className='w-full mt-1 border p-2'/>
            </div>
        </div>
        <div className="mt-3">
          <MultipleSelect setData={setData} activities={activities} setActivities={setActivities}/>
        </div>
        {/* email
        <div className="border flex flex-col mt-3 mr-0 p-2">
          <div className="flex justify-between">
            <label htmlFor="">Email</label>
            <button disabled={isEmailBodyUpdated? false:true} style={{opacity: isEmailBodyUpdated? "1":".5"}} onClick={handleEmailBodySave} className='bg-blue-600 text-white text-[10px] font-bold p-1 rounded-md' >Done</button>
          </div>
          <textarea value={emailBody} onChange={e => setEmailBody(e.target.value)} className='border p-2 mt-2  focus:ring-4 focus:outline-none focus:ring-blue-300' placeholder='Email Body..' name="emailContent" id="emailContent"></textarea>
        </div> */}
        <button onClick={handleSubmit} disabled={isDisabled} className={`rounded-md p-2 ${isDisabled? "bg-blue-600/50":"bg-blue-600"}  text-white font-bold mt-2`}>Add Users</button>
      </div>
    </div>
  )
}


function MultipleSelect({setData,activities,setActivities}) {
  const [isDropdownShow, setIsDropdownShow] = useState(false);
  
  const onActivityNameChange = (e,category) => {
    setActivities((prev) => {
      return prev.map(act => act.category === category? {...act,name: e.target.value}:act);
    })
  }

  const Activity = ({item,setActivities}) => {

    const handleChangeActivity = (e) => {
      const checked = e.target.checked;
      setActivities(prev => {
        if(checked){
          return prev.map(item => item.category === e.target.value? {...item,checked:true}:item);
        }
        return prev.map(item => item.category === e.target.value? {...item,checked:false}:item);
      })
    }

    const handleChangeName = (e) => {
      const checked = e.target.checked;
      setActivities(prev => {
        if(checked){
          return prev.map(item => item.category === e.target.value? {...item,nameChecked: true}:item);
        }
        return prev.map(item => item.category === e.target.value? {...item,nameChecked: false}:item);
      })
    }

    return (
            <li className='border-b'>
              <div className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                <input checked={item.checked} value={item.category} onChange={handleChangeActivity} id="checkbox-item-11" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
                <label htmlFor="checkbox-item-11" className="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">{item.category}</label>
              </div>
              <div className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                <input checked={item.nameChecked} value={item.category} onChange={handleChangeName} id="checkbox-item-11" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
                <label htmlFor="checkbox-item-11" className="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">Name of Activity by Admin</label>
              </div>
            </li>
    )
  }

  useEffect(() => {
    const remaningActivities = activities.filter(item => item.checked);
    setData(prev => {
      if(remaningActivities.length === 0){
        return {
          values: prev.values.map(val => {
            delete val.requiredActivities;
            return {...val}
          }),
          keys: prev.keys.filter(key => key !== "requiredActivities")
        }
      }
      const remaningActivitiesStr = remaningActivities.map(item => item.category).join(",");
      return {
        values: prev.values.map(val => {return {...val,requiredActivities: remaningActivitiesStr}}),
        keys: [...new Set([...prev.keys,"requiredActivities"])]
      }
    });
  }, [setData,activities]);

  

  return (
    <>
    <div className="relative flex items-center gap-3">
      <button onClick={()=>setIsDropdownShow(p=>!p)} id="dropdownSearchButton" data-dropdown-toggle="dropdownSearch" className="inline-flex items-center p-2 text-center border focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
        Select Required activities 
        <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
        </svg>
      </button>
      {/* selected Activities  */}
      <div className="flex items-center gap-3">
        {
          activities.filter(ac => ac.checked).map(activity => {
            return <div key={activity.category} className="p-2 border text-white flex gap-2">
              <span className='bg-green-600 px-2 py-1 rounded-md'>{activity.category}</span>
              {
                activity.nameChecked && <input value={activity.name} onChange={(e)=>onActivityNameChange(e,activity.category)} className='border px-2 rounded-md text-black' type="text" placeholder='Activity Name'/>
              }
            </div>
          })
        }
        {
          activities.filter(ac => ac.checked).length === 0 && <div className="">No Selected Activity</div>
        }
      </div>
      {/* <!-- Dropdown menu --> */}
      {
        isDropdownShow && <>
        <div id="dropdownSearch" className="absolute top-[calc(100%+10px)] z-10 bg-white rounded-lg shadow w-60 dark:bg-gray-700">
          {/* <div className="p-3">
            <label htmlFor="input-group-search" className="sr-only">Search</label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
              </svg>
              </div>
              <input type="text" id="input-group-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search user"/>
            </div>
          </div> */}
          <ul className="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownSearchButton">
            {
              activities.map(item => <Activity key={item.category} item={item} setActivities={setActivities}/>)
            }
          </ul>
        </div>
        </>
      }
    </div>
    </>
  )
}



