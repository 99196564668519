function Options({options,handleClick}){

    // console.log(options);

    return (
        <div className="flex border border-accent-color p-2 rounded-lg flex-col gap-3 mt-14 max-h-[50vh] overflow-auto">
                {
                    options.map((item,idx) => (
                        <div key={idx}>
                            {
                                (options[idx-1] === undefined || options[idx-1].tag !== item.tag) && <h2 className="mb-3 font-bold text-secondry-color">{item.tag}</h2>
                            }
                            <div onClick={()=>handleClick(item)} className={`ml-10 ${item.isSelected? "bg-accent-color/60 text-primary-color":"bg-secondry-color/20"} p-3 rounded-lg flex justify-between items-center bg-primary-color cursor-pointer text-secondry-color`}>
                                <span className='pr-2'>{item.option}</span>
                                <span className={`w-4 h-4 text-[10px] flex justify-center items-center rounded ${item.isSelected? "text-[24px] text-primary-color":"border border-accent-color"}`}>{item.isSelected? item.order:""}</span>
                            </div>
                        </div>
                    ))
                }
        </div>
    )
}

export {Options}